import { Mode, SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { CargoListFilterWidgetType, FilterWidgetType } from '@beacon-types/cargo/cargoFilters';
import { ContainerStatusMappings } from './containerStatusMappings';

const conditionForIDs = [
  {
    condition: SearchConditionType.Contains,
    filterWidgetType: FilterWidgetType.TEXT,
  },
  {
    condition: SearchConditionType.Equals,
    filterWidgetType: FilterWidgetType.TEXT,
  },
  {
    condition: SearchConditionType.HasAnyOf,
    filterWidgetType: FilterWidgetType.MULTI_SELECT,
    tokenSeparators: [' ', ','],
  },
];

const textConditions = [
  {
    condition: SearchConditionType.Contains,
    filterWidgetType: FilterWidgetType.TEXT,
  },
  {
    condition: SearchConditionType.Equals,
    filterWidgetType: FilterWidgetType.TEXT,
  },
  {
    condition: SearchConditionType.DoesNotContain,
    filterWidgetType: FilterWidgetType.TEXT,
  },
];

const dateRangeConditions = [
  {
    condition: SearchConditionType.AfterOrIncluding,
    filterWidgetType: FilterWidgetType.DATE,
  },
  {
    condition: SearchConditionType.BeforeOrIncluding,
    filterWidgetType: FilterWidgetType.DATE,
  },
  {
    condition: SearchConditionType.Contains,
    filterWidgetType: FilterWidgetType.DATE,
  },
  {
    condition: SearchConditionType.DoesNotContain,
    filterWidgetType: FilterWidgetType.DATE,
  },
];

const allSupportedModes: Mode[] = [Mode.Air, Mode.Ocean];

export const CargoFilterWidgetList: CargoListFilterWidgetType[] = [
  {
    label: 'Customer',
    key: 'customerId',
    supportedConditions: [
      {
        condition: SearchConditionType.HasAnyOf,
        filterWidgetType: FilterWidgetType.CUSTOMER,
      },
    ],
    supportedModes: allSupportedModes,
  },
  {
    label: 'Status',
    key: 'status',
    supportedConditions: [
      {
        condition: SearchConditionType.HasAnyOf,
        filterWidgetType: FilterWidgetType.MULTI_SELECT,
        filterWidgetOptions: ContainerStatusMappings,
      },
      {
        condition: SearchConditionType.Equals,
        filterWidgetType: FilterWidgetType.TEXT,
      },
    ],
    supportedModes: allSupportedModes,
  },
  {
    label: 'Cargo Id',
    key: 'cargoId',
    supportedConditions: conditionForIDs,
    supportedModes: allSupportedModes,
  },
  {
    label: 'MAWB',
    key: 'mawb',
    supportedConditions: conditionForIDs,
    supportedModes: [Mode.Air],
  },
  {
    label: 'Container number',
    key: 'containerNumber',
    supportedConditions: conditionForIDs,
    supportedModes: [Mode.Ocean],
  },
  {
    label: 'Carrier',
    key: 'carrierName',
    supportedConditions: textConditions,
    supportedModes: [Mode.Ocean],
  },
  {
    label: 'Airline',
    key: 'carrierName',
    supportedConditions: textConditions,
    supportedModes: [Mode.Air],
  },
  {
    label: 'Flight Number',
    key: 'flightNumber',
    supportedConditions: textConditions,
    supportedModes: [Mode.Air],
  },
  {
    label: 'Vessel Name',
    key: 'vesselName',
    supportedConditions: textConditions,
    supportedModes: [Mode.Ocean],
  },
  {
    label: 'POL',
    key: 'departurePort',
    supportedConditions: textConditions,
    supportedModes: allSupportedModes,
  },
  {
    label: 'POD',
    key: 'arrivalPort',
    supportedConditions: textConditions,
    supportedModes: allSupportedModes,
  },
  {
    label: 'Departure Date',
    key: 'departureDate',
    supportedConditions: dateRangeConditions,
    supportedModes: allSupportedModes,
  },
  {
    label: 'Arrival Date',
    key: 'arrivalDate',
    supportedConditions: dateRangeConditions,
    supportedModes: allSupportedModes,
  },
];
