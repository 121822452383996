import { UserState } from '../reduxTypes';

export const USER_INITIAL_STATE: UserState = {
  isAuthenticated: undefined,
  loading: false,
  data: undefined,
  selected: {
    data: undefined,
    loading: false,
    error: null,
  },
  roles: {
    userRoles: [],
    rolesList: [],
  },
};

export enum UserActions {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  SET_USER_IS_AUTHENTICATED = 'SET_USER_IS_AUTHENTICATED',
  GET_SELECTED_USER_REQUEST = 'GET_SELECTED_USER_REQUEST',
  GET_SELECTED_USER_ERROR = 'GET_SELECTED_USER_ERROR',
  DELETE_SELECTED_USER_REQUEST = 'DELETE_SELECTED_USER_REQUEST',
  DELETE_SELECTED_USER_ERROR = 'DELETE_SELECTED_USER_ERROR',
  UPDATE_SELECTED_USER_REQUEST = 'UPDATE_SELECTED_USER_REQUEST',
  UPDATE_SELECTED_USER_ERROR = 'UPDATE_SELECTED_USER_ERROR',
  SET_SELECTED_USER = 'SET_SELECTED_USER',
  SET_SELECTED_USER_ROLES = 'SET_SELECTED_USER_ROLES',
  SET_ROLES_LIST = 'SET_ROLES_LIST',
}
