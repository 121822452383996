import { UnleashClient } from 'unleash-proxy-client';
import { noop } from 'lodash';
import { GATEWAY_SERVICE, ENVIRONMENT } from '../constants';
import { getAccessToken } from './Auth';

interface FeatureFlags {
  isEnabled: (flag: string) => boolean;
  start: () => Promise<void> | void;
  stop: () => void;
}

const featureFlags: FeatureFlags = {
  isEnabled: () => false,
  start: noop,
  stop: noop,
};

const initialiseFeatureFlags = async () => {
  const featureFlagsClient: UnleashClient = new UnleashClient({
    url: `${GATEWAY_SERVICE}/v1/feature-flags`,
    clientKey: `should-not-be-used`,
    appName: 'beacon-webapp',
    environment: ENVIRONMENT,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetch: async (url: string, config: any) => {
      const updatedConfig = {
        ...config,
      };

      const token = await getAccessToken();
      updatedConfig.headers.Authorization = token ? `Bearer ${token}` : undefined;

      return fetch(url, config);
    },
  });

  featureFlags.isEnabled = (flagName) =>
    featureFlagsClient.getAllToggles().length > 0 &&
    featureFlagsClient.isEnabled(`${ENVIRONMENT}-frontend-${flagName}`);

  featureFlags.start = async () => {
    await featureFlagsClient.start();
  };
  featureFlags.stop = featureFlagsClient.stop;

  await featureFlags.start();
};

export { featureFlags, initialiseFeatureFlags };
