import _get from 'lodash/get';
import _map from 'lodash/map';
import _some from 'lodash/some';
import _every from 'lodash/every';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { UserGroup, Customer, CustomerCapability } from '@beacon-devops/customer-service-client';
import { notEmpty } from '@utils/generalUtilities';
import { emailRegex } from '@utils/forms/validation.regexp';
import UserService from '@services/User';
import CustomerService from '@services/CustomerService';
import { User } from '@services/constants';
import { CreateOrUpdateCustomerProps, CustomerFormValues, UserWithRoles } from '@beacon-types/customer';

export const validateUserList = ({ users }: CustomerFormValues): boolean => {
  const hasUsers = notEmpty(users);
  const containsAccessAdmin =
    hasUsers &&
    _some(users, (u) => {
      const permissions = _get(u, 'permissions');
      return permissions && _find(permissions, { name: 'Access Admin' });
    });
  const allValidUserEmails =
    hasUsers &&
    _every(users, (u) => {
      return new RegExp(emailRegex).test(u.email);
    });

  const allUsersHavePermissions =
    hasUsers &&
    _every(users, (u) => {
      const permissions = _get(u, 'permissions');
      return notEmpty(permissions);
    });
  return hasUsers && containsAccessAdmin && allValidUserEmails && allUsersHavePermissions;
};

const getExternalUsers = (users: User[]) => _filter(users, { userGroup: UserGroup.External });

const createOrUpdateUserCalls = (
  customerId: string,
  users?: UserWithRoles[],
  existingUsers?: User[],
): Promise<User>[] => {
  const filteredUsers = users?.filter((user) => !!user.email);
  return _map(filteredUsers, (user) => {
    const { permissions, email } = user;
    const existingUser = _find(existingUsers, { email });
    const roles = permissions ? _map(permissions, 'id') : [];
    const clonedUser = {
      roleIds: roles as string[],
      userGroup: UserGroup.External,
      customerId,
      email,
    };

    if (notEmpty(existingUser)) {
      const userId = _get(existingUser, 'id', '');
      return UserService.updateUserRoles(customerId, userId, { roleIds: roles as string[] }).then(() => {
        return UserService.getUserById(userId);
      });
    }
    return UserService.addUserV2(clonedUser);
  });
};

const createOrUpdateUsers = async (customerId: string, invitedUsers: UserWithRoles[]): Promise<User[]> => {
  const existingUsers = await UserService.getAllUsersByCustomerId(customerId).then((users) => getExternalUsers(users));
  const users = await Promise.all([...createOrUpdateUserCalls(customerId, invitedUsers, existingUsers)]);
  return users;
};

const createOrUpdateCapabilities = async (
  customerId: string,
  capabilities: CustomerCapability[],
): Promise<Customer> => {
  return CustomerService.upsertCustomerCapabilities(customerId, capabilities);
};

const createCustomer = async ({
  customerDetails,
  invitedUsers,
}: CreateOrUpdateCustomerProps): Promise<[Customer, CustomerCapability[], User[], boolean]> => {
  try {
    const createdCustomer = customerDetails
      ? await CustomerService.createCustomer(customerDetails)
      : await Promise.reject(Error('Could not perform create customer request'));
    const customerId = _get(createdCustomer, 'customerId');
    const createdCapabilities =
      customerDetails && customerDetails.capabilities && customerId
        ? (await createOrUpdateCapabilities(customerId, customerDetails.capabilities)).capabilities
        : await Promise.reject(Error('Could not perform create customer capability request.'));
    const createdUsers =
      invitedUsers && customerId
        ? await createOrUpdateUsers(customerId, invitedUsers)
        : await Promise.reject(Error('Could not perform create users request'));
    return [createdCustomer, createdCapabilities || [], createdUsers, true];
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCustomer = async ({
  customerId,
  customerDetails,
  version,
  addCredits,
}: CreateOrUpdateCustomerProps): Promise<[Customer, CustomerCapability[], User[], boolean]> => {
  try {
    const updateCustomerCall = () =>
      customerId && version && customerDetails
        ? CustomerService.updateCustomer(customerId, version, customerDetails)
        : Promise.reject(Error('Could not perform customer update request'));
    const updateCapabilitiesCall = () =>
      customerDetails && customerDetails.capabilities && customerId
        ? CustomerService.upsertCustomerCapabilities(customerId, customerDetails.capabilities)
        : Promise.reject(Error('Could not perform create customer capability request.'));
    const updateAddCreditsCall = () => {
      if (customerId && addCredits && addCredits.quantity > 0) {
        return CustomerService.addCreditsToCustomer(customerId, addCredits);
      }
      return Promise.resolve(true);
    };

    const updated: [Customer, CustomerCapability[], User[], boolean] = await Promise.all([
      await updateCustomerCall(),
      (await updateCapabilitiesCall()).capabilities || [],
      [],
      await updateAddCreditsCall(),
    ]);

    return updated;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createOrUpdateCustomer = ({
  formValues,
  options,
}: CreateOrUpdateCustomerProps): Promise<[Customer, CustomerCapability[], User[], boolean]> => {
  const customerId = _get(options, 'customer.customerId');
  const version = _get(options, 'customer.version');
  const editMode = _get(options, 'editMode');

  const invitedUsers = _get(formValues, 'users');
  const customerDetails = _omit(formValues, ['users']);
  const capabilities = _get(formValues, ['capabilities']);

  const addCredits = _get(formValues, ['addCredits']);

  return editMode
    ? updateCustomer({ customerId, customerDetails, version, addCredits })
    : createCustomer({ customerDetails, capabilities, invitedUsers });
};

export function getCapabilityById(id: string, capabilities?: CustomerCapability[]): CustomerCapability | undefined {
  return capabilities?.find((cap) => cap.id === id);
}
