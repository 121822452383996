import {
  Configuration,
  ContainerTrackingApi,
  PlatformCxApi,
  PatchCargoDTO,
  Mode,
} from '@beacon-devops/container-tracking-api-model-ts-client';
import { GATEWAY_SERVICE } from '@constants/index';
import { getAccessToken } from './Auth';

const accessToken = () => getAccessToken();

const base = `${GATEWAY_SERVICE}/container-tracking`;

const containerTrackingApi = new ContainerTrackingApi(new Configuration({ basePath: base, accessToken }));
const platformCxApi = new PlatformCxApi(new Configuration({ basePath: base, accessToken }));

const manualStartTrackingById = async (id: string, mode: Mode = 'OCEAN') => {
  const response = await containerTrackingApi.manualStartTrackingById(id, mode);
  return response.data;
};

const manualStopTrackingById = async (id: string, mode: Mode = 'OCEAN') => {
  const response = await containerTrackingApi.manualStopTrackingById(id, mode);
  return response.data;
};

const pauseUpdatesById = async (id: string, mode: Mode = 'OCEAN') => {
  const response = await containerTrackingApi.pauseUpdatesById(id, mode);
  return response.data;
};

const resumeUpdatesById = async (id: string, mode: Mode = 'OCEAN') => {
  const response = await containerTrackingApi.resumeUpdatesById(id, mode);
  return response.data;
};

const getVessels = async () => {
  const response = await platformCxApi.getVessels();
  return response.data;
};

const patchCargoData = async (patchCargoDTO?: PatchCargoDTO) => {
  const response = await platformCxApi.patchCargoData(patchCargoDTO);
  return response.data;
};

const getCargoTrackingStatus = async (id: string, mode: Mode = 'OCEAN') => {
  const response = await containerTrackingApi.getCargoStatus(id, mode);
  return response.data;
};

export const ContainerTrackingService = {
  manualStartTrackingById,
  manualStopTrackingById,
  pauseUpdatesById,
  resumeUpdatesById,
  getVessels,
  patchCargoData,
  getCargoTrackingStatus,
};
