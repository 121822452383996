import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  AirContainer,
  AirListingPage,
  FlaggedShipmentsPage,
  OceanContainer,
  OceanListingPage,
  ScvCommanderPage,
} from '../pages';
import { ScvCommanderEntityPage } from '../pages/ScvCommander/ScvCommanderEntityPage';
import {
  AIR_CONTAINER_PATH,
  basePathAir,
  basePathFlaggedShipments,
  basePathOcean,
  OCEAN_CONTAINER_PATH,
  SCV_COMMANDER_PATH,
  SCV_COMMANDER_CARGO_PATH,
} from './paths';

const Routes: FunctionComponent = () => {
  return (
    <Switch>
      <Route path={basePathOcean} exact component={OceanListingPage} />
      <Route path={OCEAN_CONTAINER_PATH} exact component={OceanContainer} />

      <Route path={basePathAir} exact component={AirListingPage} />
      <Route path={AIR_CONTAINER_PATH} exact component={AirContainer} />
      <Route path={SCV_COMMANDER_PATH} exact component={ScvCommanderPage} />
      <Route path={SCV_COMMANDER_CARGO_PATH} exact component={ScvCommanderEntityPage} />

      <Route path={basePathFlaggedShipments} exact component={FlaggedShipmentsPage} />
    </Switch>
  );
};

export default Routes;
