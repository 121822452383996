import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '@components/PageLayout';
import UserForm from '@components/User/UserForm';
import UserHeader from '@components/User/UserHeader';

export const UserInviteToCompany = (): ReactElement => {
  const { customerId } = useParams<{ customerId: string }>();
  return (
    <PageLayout>
      <div style={{ marginBottom: '32px' }}>
        <UserHeader customerId={customerId} />
      </div>
      <UserForm customerId={customerId} />
    </PageLayout>
  );
};

export default UserInviteToCompany;
