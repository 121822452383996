import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { Alert, Input, message, Modal, Spin, Timeline } from 'antd';
import { Controller, FormProvider, UseControllerProps, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Box } from '@beacon-devops/components';
import PageLayout from '@components/PageLayout';
import { DevTool } from '@hookform/devtools';
import { Cargo, Mode, SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { ScvContainerDate } from '@platform/components/ContainerDate';
import PortSearch from '@platform/components/PortSearch';
import CarrierSearch from '@platform/components/CarrierSearch';
import { SCVContainerFormValues } from '@platform/types';
import { buildEventProps, LocationType, mapSCVCargoFormatToForm } from '@platform/utils';
import { patchArrivalZone, patchDepartureZone } from '@platform/pages/OceanContainer/utils/OceanContainerUtils';
import { useDeleteCargoMutation, useManualCTATrackingMutation, usePatchCargoDataMutation } from '@queries/containers';
import { useSearchCargoForAllCustomers } from '@queries/graphql';
import { useCustomer } from '@queries/customers';
import TimelineMilestoneIcon from '@assets/icons/Timeline_milestone.svg';
import { ContainerDeleteModal } from '@platform/components/ContainerDeleteModal';
import ScvContainerDetailsHeader from '@platform/components/ScvContainerDetailsHeader';
import EquipmentKindInput from '@platform/components/EquipmentKindInput';
import { handleCargoFormSubmit } from '@platform/utils/updateCargoOcean';
import { handleCargoOceanDelete } from '@platform/utils/deleteCargo';
import { getValidEventRanges } from '@platform/utils/handleValidation';
import { usePurgeShipmentData } from '@queries/shipmentTracker/usePurgeShipmentData';
import { ShipmentPurgeModal } from '@platform/components/ShipmentPurgeModal';
import {
  CDetailsCard,
  CDetailsCardContentLayout,
  CDetailsTimelineItem,
  CDetailsTimelinePoint,
  ErrorWrapper,
  FormLabel,
  FormSection,
  LoadingCentered,
} from './styles';
import { ShipmentDates } from './ShipmentDates';
import TranshipmentFieldArray from './TranshipmentFieldArray';

interface CargoPageRouteParams {
  customerId: string;
  cargoId: string;
}

const DEPARTURE_PORT_FIELD_NAME = 'departurePort';
const ARRIVAL_PORT_FIELD_NAME = 'arrivalPort';

export const OceanContainer = (): JSX.Element => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isJSONModalOpen, setJSONModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isPurgeModalOpen, setPurgeModalOpen] = useState(false);
  const { customerId, cargoId } = useParams<CargoPageRouteParams>();
  const { data: customer } = useCustomer(customerId);

  const [deletedEventIds, setDeletedEventIds] = useState<string[]>([]);
  const addDeletedEvent = (eventId: string) => {
    setDeletedEventIds([...deletedEventIds, eventId]);
  };
  const removeDeletedEvent = (eventId: string) => {
    setDeletedEventIds(deletedEventIds.filter((id) => id !== eventId));
  };

  const {
    data: searchData,
    isError,
    error,
    isLoading: isLoadingCargo,
    refetch: refetchCargo,
  } = useSearchCargoForAllCustomers({
    criteria: [
      {
        field: 'id',
        condition: SearchConditionType.Equals,
        values: [cargoId],
      },
    ],
  });
  const cargo = get(searchData, 'cargos[0]', {}) as Cargo;

  const cargoInFormData = useMemo(() => {
    if (cargo) {
      return mapSCVCargoFormatToForm(cargo, cargo.carrierShipmentId, customerId);
    }
    return null;
  }, [JSON.stringify(cargo), cargoId, customerId]);

  const {
    mutate: patchCargoData,
    isLoading: isLoadingPatchCargoData,
    isSuccess: isPatchCargoSuccess,
    error: patchCargoDataError,
  } = usePatchCargoDataMutation(cargo?.carrierShipmentId || '', customerId);

  const {
    mutate: deleteCargoData,
    isLoading: isLoadingDeleteCargoData,
    isSuccess: isDeleteCargoSuccess,
    error: deleteCargoDataError,
  } = useDeleteCargoMutation(cargo?.carrierShipmentId || '', customerId);

  const {
    mutate: updateManualTracking,
    isLoading: isUpdatingCTATracking,
    error: updateCTATrackingError,
  } = useManualCTATrackingMutation(cargoInFormData?.identifier || '');

  const {
    mutate: purgeShipmentData,
    isSuccess: isPurgeShipmentSuccess,
    error: purgeShipmentError,
  } = usePurgeShipmentData(cargo?.carrierShipmentId || '');

  const formMethods = useForm<SCVContainerFormValues>({ mode: 'all' });
  const {
    watch,
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty, dirtyFields },
  } = formMethods;

  const watchedFormData = watch();

  const watchPOL = watch(DEPARTURE_PORT_FIELD_NAME);
  const watchPOD = watch(ARRIVAL_PORT_FIELD_NAME);

  useEffect(() => {
    const formValues = formMethods.getValues();
    const newZone = get(formValues, 'departurePort.zone');
    if (!cargoInFormData || !newZone) return;
    patchDepartureZone(newZone, formValues, setValue);
  }, [watchPOL]);

  useEffect(() => {
    const formValues = formMethods.getValues();
    const newZone = get(formValues, 'arrivalPort.zone');
    if (!cargoInFormData || !newZone) return;
    patchArrivalZone(newZone, formValues, setValue);
  }, [watchPOD]);

  const canSubmit = cargoInFormData?.identifier;

  // Startup the form data
  useEffect(() => {
    if (cargoInFormData) {
      reset({
        ...cargoInFormData,
      });
    }
  }, [cargoInFormData]);

  useEffect(() => {
    // We want to refetch the cargo model upon submission to reset the form
    if (isPatchCargoSuccess) {
      refetchCargo();

      messageApi.open({
        type: 'success',
        content: 'Ocean cargo updated successfully',
        duration: 3,
      });
    }
  }, [isPatchCargoSuccess]);

  useEffect(() => {
    if (isDeleteCargoSuccess) {
      refetchCargo();

      messageApi.open({
        type: 'success',
        content: 'Ocean cargo deleted successfully',
        duration: 3,
      });
    }
  }, [isDeleteCargoSuccess]);

  useEffect(() => {
    if (isPurgeShipmentSuccess) {
      setTimeout(() => {
        setPurgeModalOpen(false);

        messageApi.open({
          type: 'success',
          content: 'Ocean cargo purged successfully',
          duration: 3,
        });

        refetchCargo();
      }, 3500); // needed, otherwise won't get back updated cargo after refetch
    }
  }, [isPurgeShipmentSuccess]);

  useEffect(() => {
    if (purgeShipmentError) {
      setPurgeModalOpen(false);
    }
  }, [purgeShipmentError]);

  if (isLoadingCargo || isLoadingPatchCargoData || isLoadingDeleteCargoData) {
    return (
      <PageLayout>
        <LoadingCentered>
          <Spin size="large" className="bo-empty-content-icon" aria-label="Loading scv content" />
        </LoadingCentered>
      </PageLayout>
    );
  }

  const eventValidationRanges = getValidEventRanges({
    formData: watchedFormData,
    originalCargo: cargo,
  });

  const getDateProps = (eventKey: UseControllerProps<SCVContainerFormValues>['name']) => {
    return {
      ...buildEventProps({
        deleteActor: addDeletedEvent,
        unDeleteActor: removeDeletedEvent,
        eventValidationRanges,
        eventKey,
      }),
    };
  };

  return (
    <PageLayout>
      {contextHolder}
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit((formData) => {
            if (cargo && cargoInFormData && isDirty) {
              patchCargoData({
                patchCargoDTO: handleCargoFormSubmit(cargo, formData, dirtyFields, deletedEventIds),
              });
              reset({}, { keepValues: true });
            }
          })}
        >
          <ScvContainerDetailsHeader
            containerId={cargo.carrierShipmentId}
            data={cargoInFormData}
            customer={customer}
            mode={Mode.Ocean}
            isSubmitDisabled={isLoadingPatchCargoData || !canSubmit || !isValid || !isDirty}
            isDeleting={false}
            updateManualTracking={updateManualTracking}
            isUpdatingTracking={isUpdatingCTATracking}
            setJSONModalOpen={setJSONModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            setPurgeModalOpen={setPurgeModalOpen}
          />
          <p>{cargoId}</p>
          {isError && (
            <ErrorWrapper>
              <Alert
                message="Error while getting container data"
                showIcon
                description={error?.toString()}
                type="error"
              />
            </ErrorWrapper>
          )}
          {patchCargoDataError && (
            <ErrorWrapper>
              <Alert
                message="Error updating the container"
                showIcon
                description={`Error: ${patchCargoDataError?.message}`}
                type="error"
              />
            </ErrorWrapper>
          )}
          {deleteCargoDataError && (
            <ErrorWrapper>
              <Alert
                message="Error deleting this cargo"
                showIcon
                description={`Error: ${deleteCargoDataError?.message}`}
                type="error"
              />
            </ErrorWrapper>
          )}
          {updateCTATrackingError && (
            <ErrorWrapper>
              <Alert
                message="CTA: There was an error updating the container's tracking status."
                showIcon
                description={`Error: ${updateCTATrackingError?.message}`}
                type="error"
              />
            </ErrorWrapper>
          )}
          {purgeShipmentError && (
            <ErrorWrapper>
              <Alert
                message="Purging Cargo: There was an error purging the cargo"
                showIcon
                description={`Error: ${purgeShipmentError?.message}`}
                type="error"
              />
            </ErrorWrapper>
          )}
          {!canSubmit && (
            <ErrorWrapper>
              <Alert message="Can't submit container" showIcon description="Container Number is missing" type="error" />
            </ErrorWrapper>
          )}
          {!isValid && (
            <ErrorWrapper>
              <Alert
                message="Please resolve validation issues before Submit"
                showIcon
                description="There are validation issues in the form, see inline messages in red."
                type="warning"
              />
            </ErrorWrapper>
          )}

          <FormSection>
            <Timeline>
              <CDetailsTimelineItem dot={<CDetailsTimelinePoint />}>
                <CDetailsCard>
                  <Box display="flex" justifyContent="space-between">
                    <Box flexBasis="45%">
                      <FormLabel htmlFor="masterBillOfLading">Master Bill Of Lading</FormLabel>
                      <Controller
                        control={control}
                        name="masterBillOfLading"
                        render={({ field }) => <Input {...field} />}
                      />
                    </Box>
                    <Box flexBasis="45%">
                      <FormLabel htmlFor="freightForwarderName">Freight Forwarder Name</FormLabel>
                      <Controller
                        control={control}
                        name="freightForwarderName"
                        render={({ field }) => <Input {...field} />}
                      />
                    </Box>
                  </Box>
                </CDetailsCard>
              </CDetailsTimelineItem>
              <CDetailsTimelineItem dot={<CDetailsTimelinePoint />}>
                <CDetailsCard>
                  <Box display="flex" flexAlign="row" justifyContent="space-between" width="100%">
                    <Box flexBasis="30%">
                      <FormLabel htmlFor="status">Status</FormLabel>
                      <Controller
                        control={control}
                        name="status"
                        render={({ field }) => <Input {...field} disabled id="status" />}
                      />
                    </Box>
                    <Box flexBasis="30%">
                      <CarrierSearch name="carrier" label="Carrier Name" />
                    </Box>
                    <Box flexBasis="30%">
                      <EquipmentKindInput cargo={cargo} />
                    </Box>
                  </Box>
                </CDetailsCard>
              </CDetailsTimelineItem>
              <CDetailsTimelineItem dot={<TimelineMilestoneIcon />}>
                <CDetailsCard>
                  <CDetailsCardContentLayout>
                    <PortSearch label="Port of Loading" name="departurePort" type={LocationType.Seaport} />
                    <ScvContainerDate label="Gate Out Empty" {...getDateProps('gateOutEmpty.actualDate')} />
                    <ScvContainerDate label="Gate In Full" {...getDateProps('gateInFull.actualDate')} />
                    <ScvContainerDate label="Loaded" {...getDateProps('loaded.actualDate')} />
                  </CDetailsCardContentLayout>
                </CDetailsCard>
              </CDetailsTimelineItem>

              <CDetailsTimelineItem dot={<TimelineMilestoneIcon />}>
                <ShipmentDates mode={Mode.Ocean} getDateProps={getDateProps} />
              </CDetailsTimelineItem>

              <TranshipmentFieldArray getDateProps={getDateProps} mode={Mode.Ocean} />

              <CDetailsTimelineItem dot={<TimelineMilestoneIcon />}>
                <CDetailsCard>
                  <CDetailsCardContentLayout>
                    <PortSearch label="Port of Discharge" name="arrivalPort" type={LocationType.Seaport} />
                    <ScvContainerDate label="Discharged" {...getDateProps('discharged.actualDate')} />
                    <ScvContainerDate label="Gate Out Full" {...getDateProps('gateOutFull.actualDate')} />
                    <ScvContainerDate label="Gate In Empty" {...getDateProps('gateInEmpty.actualDate')} />
                  </CDetailsCardContentLayout>
                </CDetailsCard>
              </CDetailsTimelineItem>
            </Timeline>
          </FormSection>

          <ContainerDeleteModal
            isOpen={isDeleteModalOpen}
            containerNumber={cargo?.container?.containerNumber || ''}
            customer={customer?.fullCompanyName || customer?.customerId || ''}
            setDeleteModalOpen={setDeleteModalOpen}
            deleteContainer={() => null}
            onCancel={() => setDeleteModalOpen(false)}
            onDelete={() => {
              if (cargoInFormData) {
                deleteCargoData({ deleteCargoDTO: handleCargoOceanDelete(cargoInFormData) });
                setDeleteModalOpen(false);
              }
            }}
          />

          <ShipmentPurgeModal
            isOpen={isPurgeModalOpen}
            carrierShipmentId={cargo?.carrierShipmentId || ''}
            customer={customer?.fullCompanyName || customer?.customerId || ''}
            onCancel={() => setPurgeModalOpen(false)}
            onPurge={() => {
              if (cargoInFormData) {
                purgeShipmentData();
              }
            }}
          />

          <Modal
            title="Cargo JSON"
            visible={isJSONModalOpen}
            width={800}
            centered
            onCancel={() => setJSONModalOpen(false)}
            footer={null}
          >
            <Box pt={2} pb={4}>
              <Input.TextArea rows={15} value={JSON.stringify(cargo, null, 4)} />
            </Box>
          </Modal>

          <DevTool control={control} placement="top-right" />
        </form>
      </FormProvider>
    </PageLayout>
  );
};

export default OceanContainer;
