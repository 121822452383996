import { SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { CargoFilterCurrentValue, CargoListFilterWidgetType } from '@beacon-types/cargo/cargoFilters';

export const getCargoDefaultFilterCondition = (cargoFilter: CargoListFilterWidgetType) =>
  cargoFilter.supportedConditions[0].condition;

export const getCargoFilterDefaults = (
  cargoFilterWidget: CargoListFilterWidgetType,
  condition?: SearchConditionType,
): CargoFilterCurrentValue => {
  const { supportedConditions } = cargoFilterWidget;
  const existingCondition =
    condition && supportedConditions.find((supportedCondition) => supportedCondition.condition === condition);
  const conditionObj = existingCondition || supportedConditions[0];

  return {
    condition: conditionObj.condition,
    value: conditionObj.defaultValue ? conditionObj.defaultValue : [],
  };
};

export const getCargoSupportedCondition = (cargoFilter: CargoListFilterWidgetType, condition?: SearchConditionType) =>
  cargoFilter.supportedConditions.find((supportedCondition) => supportedCondition.condition === condition);
