import { Auth0Client } from '@auth0/auth0-spa-js';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { sessionStorageService } from '@utils/generalUtilities';
import { LOGOUT } from '@routes/constants';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, PUBLIC_URL } from '../constants';

const authClient = new Auth0Client({
  domain: AUTH0_DOMAIN,
  client_id: AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin,
  audience: 'BeaconApi',
  cacheLocation: 'localstorage',
});

export const redirectToLogin = () => {
  authClient.loginWithRedirect({ prompt: 'login', appState: { redirectUrl: PUBLIC_URL } });
};

export const logoutAuth0 = () => {
  authClient.logout({ returnTo: `${window.location.origin}${LOGOUT}` });
};

export const getAccessToken = async (): Promise<string> => {
  try {
    const token = await authClient.getTokenSilently();

    const tokenDecoded: JwtPayload = jwt_decode(token);
    const isTokenExpired = tokenDecoded.exp && Date.now() >= tokenDecoded.exp * 1000;
    if (isTokenExpired) {
      throw new Error('TokenExpired');
    }
    return token;
  } catch (error) {
    sessionStorageService.clear();
    redirectToLogin();
  }

  return '';
};
