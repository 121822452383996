import qs from 'query-string';
import { find, get, sortBy, uniqBy } from 'lodash';
import moment from 'moment';
import { Carrier } from '@beacon-devops/carrier-provider';
import {
  CarrierDropdownEntry,
  DisabledDateRangesParams,
  SCVContainerFormValues,
  TranshipmentFields,
} from '@platform/types';
import type { Cargo, Milestone } from '@beacon-devops/graphql-typescript-client';
import {
  CargoEvent,
  CargoEventType,
  EventClassifier,
  MilestoneType,
  Mode,
  ShipmentReferenceTypeCode,
  TransportEvent,
  TransportEventType,
} from '@beacon-devops/graphql-typescript-client';
import { getAll as getAllLocations, Location } from '@beacon-devops/location-provider';
import { EventDateTime } from '@beacon-devops/container-tracking-api-model-ts-client';
import { CargoFilterURLEntry, CargoListFilterWidgetType } from '@beacon-types/cargo/cargoFilters';
import { CargoFilterWidgetList } from '@platform/constants/cargoFilterWidgetList';
import {
  findLatestCargoOrTransportEvent,
  mapEquipmentISOCodeToKind,
} from '@platform/pages/OceanContainer/utils/OceanContainerUtils';
import { ISO_WITHOUT_TIMEZONE } from '@platform/components/ContainerDate/utils';
import { EventValidationRanges, ValidationRange } from '@platform/utils/handleValidation';
import { UseControllerProps } from 'react-hook-form';

export const getCargoFiltersFromURL = (
  locationSearch: string,
  mode: Mode = Mode.Ocean,
): CargoListFilterWidgetType[] => {
  let filterWidgetList: CargoListFilterWidgetType[] = [];
  const query = qs.parse(locationSearch);

  if (query.filters) {
    const cargoFilterValues: CargoFilterURLEntry[] = JSON.parse(query.filters as string);
    filterWidgetList = cargoFilterValues.map((filterValue) => {
      const filterObj = CargoFilterWidgetList.find(
        (filterWidget) => filterWidget.key === filterValue.key && filterWidget.supportedModes?.includes(mode),
      ) as CargoListFilterWidgetType;
      return {
        ...filterObj,
        currentValue: filterValue.currentValue,
      };
    });
  }

  return filterWidgetList;
};

/** Carrier DTO => DropDownEntry mapping function */
export const transformCarriers = (carriers: Carrier[]): CarrierDropdownEntry[] => {
  const carriersWithNMFTA = carriers.filter((c) => !(c.nmftaCode === undefined || c.nmftaCode === ''));

  const transformedCarriers = uniqBy(carriersWithNMFTA, 'nmftaCode').map((carrier: Carrier) => ({
    label: `${carrier.nmftaCode} (${carrier.displayName})`,
    value: carrier.nmftaCode || '',
  }));

  return sortBy(transformedCarriers, 'label');
};

const emptyLocation: Location = {
  id: 'N/A',
  type: 'FACILITY',
  name: '',
  unLocation: { code: '', countryCode: '', countryName: '' },
  zone: '',
};

export enum LocationType {
  Seaport = 'SEAPORT',
  Airport = 'AIRPORT',
}

export const getPortLocations = (locations: Location[], type: LocationType): Location[] => {
  const filteredLocations = locations.filter((x) => x.facility?.type === type);
  return [emptyLocation, ...sortBy(filteredLocations, 'name')];
};

/**
 * to use with antd DatePicker component
 *
 * disabling date range before the startDate or after endDate
 *
 * also can be used with a definite [startDate, endDate] range. In this case, every date would be disabled outside of the range.
 */
export const disableDateOutsideRanges = ({ startDate = '', endDate = '' }: DisabledDateRangesParams) => {
  return function disabledDate(current: moment.Moment): boolean {
    let startCheck = false;
    let endCheck = false;
    if (startDate && startDate.length > 0) {
      startCheck = current && current < moment(startDate);
    }
    if (endDate && endDate.length > 0) {
      endCheck = current && current > moment(endDate);
    }

    return startCheck || endCheck;
  };
};

export const mapLatestCargoOrTransportEventDateTime = (
  events: CargoEvent[] | TransportEvent[],
  classifier: EventClassifier,
  subType: CargoEventType | TransportEventType,
  defaultZone?: string,
  transportCallId?: string,
): EventDateTime => {
  const predicate = findLatestCargoOrTransportEvent(events, classifier, subType, transportCallId);

  const timestamp = predicate?.eventDateTime.timestamp || '';
  const zone = predicate?.eventDateTime?.zone || defaultZone || 'Etc/UTC';

  let returnedTimestamp = '';
  if (timestamp && zone) {
    returnedTimestamp = moment(timestamp).tz(zone).format(ISO_WITHOUT_TIMEZONE);
  } else if (timestamp) {
    returnedTimestamp = moment(timestamp).format(ISO_WITHOUT_TIMEZONE);
  }

  return {
    date: get(predicate, `eventDateTime.date`, ''),
    timestamp: returnedTimestamp,
    zone,
  };
};

export const mapMilestoneEventDateTime = (
  milestones: Milestone[],
  milestoneType: MilestoneType,
  defaultZone?: string,
): EventDateTime => {
  const predicate = find(milestones, { type: milestoneType });

  return {
    date: get(predicate, `dateInfo.eventDateTime.date`, ''),
    timestamp: get(predicate, `dateInfo.eventDateTime.timestamp`, ''),
    zone: get(predicate, `dateInfo.eventDateTime.zone`, defaultZone),
  };
};

export const getLocationZone = (locationID: string): string => {
  const data = getAllLocations();
  const location = find(data, { id: locationID });
  return get(location, `zone`, '');
};

export const mapSCVCargoFormatToForm = (
  cargo: Cargo,
  carrierShipmentId: string,
  customerId: string,
): SCVContainerFormValues => {
  const { mode } = cargo;
  const cargoEvents = cargo?.cargoEvents || [];
  const shipmentReferences = cargo?.shipmentReferences || [];

  const legs = cargo.transportSummary?.legs || 0;
  const hasTranshipments = legs > 1;

  const transport = cargo?.transports?.find(
    (t) => t.carrierShipmentSequenceNo === (hasTranshipments ? 1 : cargo?.transportSummary?.currentLeg),
  );
  const journey = transport?.journey;
  const loadTransportCallId = transport?.loadTransportCall?.id || '';
  const loadTransportCallLocation = (transport?.loadTransportCall?.location || []) as Location;
  const loadTransportCallEvents = (transport?.loadTransportCall?.transportEvents || []) as TransportEvent[];
  const dischargeTransportCallId = transport?.dischargeTransportCall?.id || '';
  const dischargeTransportCallEvents = (transport?.dischargeTransportCall?.transportEvents || []) as TransportEvent[];
  const dischargeTransportCallLocation = (transport?.dischargeTransportCall?.location || []) as Location;

  const polZone = getLocationZone(loadTransportCallLocation?.id || '');
  const podZone = getLocationZone(dischargeTransportCallLocation.id || '');

  const mawb = get(find(shipmentReferences, { type: ShipmentReferenceTypeCode.Mawb }), 'value') || '';

  let cargoInFormFormat: SCVContainerFormValues = {
    correlationIds: {
      customerId,
      carrierShipmentId,
      cargoId: cargo.id,
    },
    identifier: cargo.container?.containerNumber || mawb || '',
    status: cargo.status,
    carrier: {
      id: cargo.carrier?.id || '',
      name: cargo.carrier?.name || '',
      mawbPrefix: cargo.carrier?.mawbPrefix || '',
    },
    vessel: {
      id: transport?.vehicle?.identification?.imoNumber || '',
      name: transport?.vehicle?.displayName || '',
    },
    voyagerNumber: journey?.references?.voyageNumber || '',
    equipmentKind: mapEquipmentISOCodeToKind(cargo.container?.isoCode || ''),
    departurePort: {
      id: loadTransportCallLocation?.id || '',
      name: loadTransportCallLocation?.name || '',
    },
    arrivalPort: {
      id: dischargeTransportCallLocation?.id || '',
      name: dischargeTransportCallLocation?.name || '',
    },
    bookingConfirmed: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.BookingConfirmed,
        polZone,
      ),
    },
    receive: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.Receive,
        polZone,
      ),
    },
    gateOutEmpty: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.GateOutEmpty,
        polZone,
      ),
    },
    gateInFull: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.GateInFull,
        polZone,
      ),
    },
    loaded: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.Load,
        polZone,
        loadTransportCallId,
      ),
    },
    discharged: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.Discharge,
        podZone,
        dischargeTransportCallId,
      ),
    },
    gateOutFull: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.GateOutFull,
        podZone,
      ),
    },
    collect: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.Collect,
        podZone,
      ),
    },
    gateInEmpty: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        EventClassifier.Actual,
        CargoEventType.GateInEmpty,
        podZone,
      ),
    },
    departure: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        loadTransportCallEvents,
        EventClassifier.Actual,
        TransportEventType.Depart,
        polZone,
      ),
      expectedDate: mapLatestCargoOrTransportEventDateTime(
        loadTransportCallEvents,
        EventClassifier.Estimated,
        TransportEventType.Depart,
        polZone,
      ),
    },
    arrival: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        dischargeTransportCallEvents,
        EventClassifier.Actual,
        TransportEventType.Arrive,
        podZone,
      ),
      expectedDate: mapLatestCargoOrTransportEventDateTime(
        dischargeTransportCallEvents,
        EventClassifier.Estimated,
        TransportEventType.Arrive,
        podZone,
      ),
    },
    berth: {
      actualDate: hasTranshipments
        ? undefined
        : mapLatestCargoOrTransportEventDateTime(
            dischargeTransportCallEvents,
            EventClassifier.Actual,
            TransportEventType.Berth,
            podZone,
          ),
    },
    freightForwarderName: get(find(shipmentReferences, { type: ShipmentReferenceTypeCode.Ff }), 'partyName') || '',
    masterBillOfLading: get(find(shipmentReferences, { type: ShipmentReferenceTypeCode.Mbol }), 'value') || '',
    mawb,
    pieces: cargo?.numberOfPackages?.toString() || '',
    weight: cargo?.weight || '',
    flightNumber: get(transport, 'journey.references.flightNumber', ''),
    transhipments: [],
  };

  /*
   * Load T/S into form format
   */
  if (hasTranshipments && cargoInFormFormat.transhipments) {
    for (let currentLeg = 2; currentLeg < legs + 1; currentLeg += 1) {
      const isLastLeg = currentLeg === legs;
      const legTransport = cargo?.transports?.find((t) => t.carrierShipmentSequenceNo === currentLeg);
      const legJourney = legTransport?.journey;
      const legLoadTransportCallId = legTransport?.loadTransportCall?.id || '';
      const legLoadTransportCallLocation = (legTransport?.loadTransportCall?.location || []) as Location;
      const legLoadTransportCallEvents = (legTransport?.loadTransportCall?.transportEvents || []) as TransportEvent[];
      const legDischargeTransportCallId = legTransport?.dischargeTransportCall?.id || '';
      const legDischargeTransportCallEvents = (legTransport?.dischargeTransportCall?.transportEvents ||
        []) as TransportEvent[];
      const legDischargeTransportCallLocation = (legTransport?.dischargeTransportCall?.location || []) as Location;

      const legPolZone = getLocationZone(loadTransportCallLocation?.id || '');
      const legPodZone = getLocationZone(dischargeTransportCallLocation.id || '');

      const previousLegTransport = cargo?.transports?.find((t) => t.carrierShipmentSequenceNo === currentLeg - 1);
      const previousLegDischargeTransportCallId = previousLegTransport?.dischargeTransportCall?.id || '';

      const transhipment: TranshipmentFields = {
        flightNumber: legJourney?.references?.flightNumber || '',
        port: {
          id: legLoadTransportCallLocation?.id || '',
          name: legLoadTransportCallLocation?.name || '',
        },
        discharged: {
          actualDate: mapLatestCargoOrTransportEventDateTime(
            cargoEvents,
            EventClassifier.Actual,
            CargoEventType.Discharge,
            legPolZone,
            mode === Mode.Air ? legDischargeTransportCallId : previousLegDischargeTransportCallId,
          ),
        },
        loaded: {
          actualDate: mapLatestCargoOrTransportEventDateTime(
            cargoEvents,
            EventClassifier.Actual,
            CargoEventType.Load,
            legPolZone,
            legLoadTransportCallId,
          ),
        },
        vessel: {
          id: legTransport?.vehicle?.identification?.imoNumber || '',
          name: legTransport?.vehicle?.displayName || '',
        },
        voyagerNumber: legJourney?.references?.voyageNumber || '',
        departure: {
          actualDate: mapLatestCargoOrTransportEventDateTime(
            legLoadTransportCallEvents,
            EventClassifier.Actual,
            TransportEventType.Depart,
            legPolZone,
          ),
          expectedDate: mapLatestCargoOrTransportEventDateTime(
            legLoadTransportCallEvents,
            EventClassifier.Estimated,
            TransportEventType.Depart,
            legPolZone,
          ),
        },
        arrival: {
          actualDate: mapLatestCargoOrTransportEventDateTime(
            legDischargeTransportCallEvents,
            EventClassifier.Actual,
            TransportEventType.Arrive,
            legPodZone,
          ),
          expectedDate: mapLatestCargoOrTransportEventDateTime(
            legDischargeTransportCallEvents,
            EventClassifier.Estimated,
            TransportEventType.Arrive,
            legPodZone,
          ),
        },
        berth: {
          actualDate: isLastLeg
            ? mapLatestCargoOrTransportEventDateTime(
                legDischargeTransportCallEvents,
                EventClassifier.Actual,
                TransportEventType.Berth,
                legPodZone,
              )
            : undefined,
        },
      };

      cargoInFormFormat.transhipments.push(transhipment);

      // IF is last leg the discharge transport call location will be the final destination
      if (isLastLeg) {
        cargoInFormFormat.arrivalPort = {
          id: legDischargeTransportCallLocation?.id || '',
          name: legDischargeTransportCallLocation?.name || '',
        };

        if (mode === Mode.Ocean) {
          cargoInFormFormat.discharged = {
            actualDate: mapLatestCargoOrTransportEventDateTime(
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.Discharge,
              legPolZone,
              legDischargeTransportCallId,
            ),
          };
        }
      }
    }
  }

  return cargoInFormFormat;
};

export const getTimeFromString = (date: string) => date.split('T')[1] || '';

/** takes a moment Date object and a full timestamp as a string. The goal is to keep the timestamp part with the new value */
export const getDateWithOriginalTimestamp = (value: moment.Moment, originalTimestamp = ''): string => {
  const timeStamp = getTimeFromString(originalTimestamp);

  if (timeStamp && timeStamp.length) {
    return `${value?.format('YYYY-MM-DD')}T${getTimeFromString(originalTimestamp)}`;
  }

  return `${value?.format('YYYY-MM-DD')}`;
};

export type EventActors = {
  onDelete: () => void;
  onUndelete: () => void;
};

export const buildEventActors = (
  deleteActor: (key: string) => void,
  unDeleteActor: (key: string) => void,
  eventKey: string,
): EventActors => {
  return {
    onDelete: () => deleteActor(eventKey),
    onUndelete: () => unDeleteActor(eventKey),
  };
};

export type EventProps = {
  validationRange?: ValidationRange;
  defaultTimeNow?: boolean;
  renderOnUpdate?: boolean;
  name: UseControllerProps<SCVContainerFormValues>['name'];
} & EventActors;

export const buildEventProps = ({
  deleteActor,
  unDeleteActor,
  eventKey,
  eventValidationRanges,
}: {
  deleteActor: (key: string) => void;
  unDeleteActor: (key: string) => void;
  eventKey: UseControllerProps<SCVContainerFormValues>['name'];
  eventValidationRanges?: EventValidationRanges;
}): EventProps => {
  return {
    ...buildEventActors(deleteActor, unDeleteActor, eventKey),
    validationRange: get(eventValidationRanges, eventKey, undefined),
    name: eventKey,
  };
};
