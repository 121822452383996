import { domainRegex, emailRegex } from './validation.regexp';

export const requiredRule = {
  required: true,
  message: 'Required',
};

export const domainRule = {
  pattern: domainRegex,
  message: 'Please enter correct website',
};

export const emailRule = {
  pattern: emailRegex,
  message: 'Please enter correct email',
};
