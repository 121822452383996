import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  margin-top: 10px;
`;

export const ErrorSectionWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;
