import React from 'react';
import { Card } from 'antd';
import { Location } from '@beacon-devops/shipment-tracker-client';
// Local imports
import { TitleBar, TitleContainer, TitleWithoutMargin } from './styles';
import { AddInspectionEntity, renderEntityItem, renderEntityItemDateTime } from './ScvCommanderEntityUtils';

interface ScvCommanderEntityLocationProps {
  entityId: string;
  entityData: Location;
  addInspectionEntityFunc: AddInspectionEntity;
  backgroundColor: string;
}

/**
 * This component renders a Location given some data from Shipment Tracker. It will also query shipment tracker for child elements like
 * transports & events which will also be rendered.
 */
export const ScvCommanderEntityLocation = (props: ScvCommanderEntityLocationProps): JSX.Element => {
  const location: Location = props.entityData;

  /**
   * Renders the location card plus any extra information for this entity
   * @param location Location entity from Shipment Tracker
   */
  function render(location: Location): JSX.Element {
    return (
      <>
        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Location {location?.name ? `: ${location.name}` : ''}</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Card>
          {renderEntityItemDateTime('Created At', location.createdAt)}
          {renderEntityItemDateTime('Last Updated At', location.updatedAt)}
          {renderEntityItem('Name', location.name)}
          {renderEntityItem('Type', location.type)}
          {renderEntityItem('UN Locode', location.unLocation?.code)}
          {renderEntityItem('Country Code', location.unLocation?.countryCode)}
          {renderEntityItem('Country Name', location.unLocation?.countryName)}
        </Card>
      </>
    );
  }

  return <div>{location ? render(location) : '...loading'}</div>;
};
