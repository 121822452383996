import { SearchCriteria, SearchSortCriteria } from '@beacon-devops/graphql-typescript-client';
import { CargoFilterURLEntry } from '@beacon-types/cargo/cargoFilters';
import { SearchConditionType, SearchSortType } from '@beacon-devops/graphql-typescript-client/dist/src/gql/graphql';
import { ShipmentReferenceType } from '@beacon-devops/container-tracking-api-model-ts-client';

export const generateSearchCriteria = (filters: string): SearchCriteria[] | [] => {
  const cargoFilterValues: CargoFilterURLEntry[] = JSON.parse(filters);

  const criteriaList: SearchCriteria[] = [];

  cargoFilterValues.forEach(({ key, currentValue }) => {
    const defaults = {
      condition: currentValue?.condition || SearchConditionType.Equals,
      values: currentValue?.value || [],
    };

    if (key === 'customerId' || key === 'status') {
      criteriaList.push({
        field: key,
        ...defaults,
      });
    }

    if (key === 'cargoId') {
      criteriaList.push({
        field: 'id',
        ...defaults,
      });
    }

    if (key === 'containerNumber') {
      criteriaList.push({
        field: 'container.containerNumber',
        ...defaults,
      });
    }

    if (key === 'carrierName') {
      criteriaList.push({
        field: 'carrier.displayName',
        ...defaults,
      });
    }

    if (key === 'flightNumber') {
      criteriaList.push({
        field: 'transports.journey.references.flightNumber',
        ...defaults,
      });
    }

    if (key === 'vesselName') {
      criteriaList.push({
        field: 'transportSummary.currentVehicle.displayName',
        ...defaults,
      });
    }

    if (key === 'departurePort') {
      criteriaList.push({
        field: 'transportSummary.primaryLoadLocation.name',
        ...defaults,
      });
    }

    if (key === 'arrivalPort') {
      criteriaList.push({
        field: 'transportSummary.primaryDischargeLocation.name',
        ...defaults,
      });
    }

    if (key === 'departureDate') {
      criteriaList.push({
        field: 'transports.loadTransportCall.transportEvents.eventDateTime.timestamp',
        ...defaults,
      });
    }

    if (key === 'arrivalDate') {
      criteriaList.push({
        field: 'transports.dischargeTransportCall.transportEvents.eventDateTime.timestamp',
        ...defaults,
      });
    }

    if (key === 'mawb') {
      criteriaList.push({
        field: 'shipmentReferences.value',
        ...defaults,
      });
      criteriaList.push({
        field: 'shipmentReferences.type',
        condition: SearchConditionType.Equals,
        values: [ShipmentReferenceType.Mawb],
      });
    }
  });

  return criteriaList;
};

export const generateSearchSort = (sorter: string): SearchSortCriteria[] | [] => {
  const cargoSortValues = JSON.parse(sorter);

  const sortList: SearchSortCriteria[] = [];

  const orderBy = cargoSortValues.order === 'ascend' ? SearchSortType.Asc : SearchSortType.Desc;

  if (
    cargoSortValues.columnKey === 'customerId' ||
    cargoSortValues.columnKey === 'status' ||
    cargoSortValues.columnKey === 'containerNumber'
  ) {
    sortList.push({
      field: cargoSortValues.columnKey,
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'cargoId') {
    sortList.push({
      field: 'container.containerNumber',
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'carrierName') {
    sortList.push({
      field: 'carrier.displayName',
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'vesselName') {
    sortList.push({
      field: 'transportSummary.currentVehicle.displayName',
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'flightNumber') {
    sortList.push({
      field: 'transports.journey.references.flightNumber',
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'departurePort') {
    sortList.push({
      field: 'transportSummary.primaryLoadLocation.name',
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'arrivalPort') {
    sortList.push({
      field: 'transportSummary.primaryDischargeLocation.name',
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'departureDate') {
    sortList.push({
      field: 'transports.loadTransportCall.transportEvents.eventDateTime.timestamp',
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'arrivalDate') {
    sortList.push({
      field: 'transports.dischargeTransportCall.transportEvents.eventDateTime.timestamp',
      order_by: orderBy,
    });
  }

  if (cargoSortValues.columnKey === 'mawb') {
    sortList.push({
      field: 'shipmentReferences.value',
      order_by: orderBy,
    });
  }

  return sortList;
};
