import { useMemo } from 'react';
import { Customer } from '@beacon-devops/customer-service-client';
import { useCustomerByIds } from '@queries/customers';

export const useCustomerIdNameMap = (ids: string[]) => {
  const customerQueries = useCustomerByIds(ids.filter((id, index) => ids.indexOf(id) === index));

  const customerMap = useMemo(() => {
    return (
      customerQueries
        ?.filter((x) => x.isSuccess)
        .reduce(
          (map, customer) => ({
            ...map,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [customer.data!.customerId]: customer.data!,
          }),
          {} as Record<string, Customer>,
        ) ?? {}
    );
  }, [customerQueries]);

  return { customerMap, isLoading: customerQueries.some((x) => x.isLoading) };
};
