import { FlaggedShipmentFieldName } from '@platform/types';
import { useRequestReportDetails } from '@queries/flaggedShipments';
import { Modal } from 'antd';
import { MessageInstance } from 'antd/lib/message';
import React from 'react';

interface Props {
  reportId: string;
  fieldName: FlaggedShipmentFieldName;
  onClose: () => void;
  messageApi: MessageInstance;
}

const getModalTitleByFieldName = (fieldName: 'rulesResult' | 'dataItem'): string =>
  fieldName === 'rulesResult' ? 'Rules Result Details' : 'Data Item Details';

export const RuleResultsDialog = ({ reportId, fieldName, onClose }: Props) => {
  const { data, isLoading } = useRequestReportDetails(reportId);
  const selectedData = data && fieldName in data ? data[fieldName] : null;

  return (
    <>
      <Modal
        title={getModalTitleByFieldName(fieldName)}
        onOk={onClose}
        onCancel={onClose}
        confirmLoading={isLoading}
        okText="Close"
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable
        style={{ top: 10, minWidth: '950px' }}
        visible={!!selectedData}
      >
        <div
          style={{
            marginTop: '16px',
          }}
        >
          <pre>{JSON.stringify(selectedData, null, '\t')}</pre>
        </div>
      </Modal>
    </>
  );
};
