import { useState } from 'react';

interface UseForceRender {
  render: () => void;
}

const useForceRender = (): UseForceRender => {
  const [state, setState] = useState(false);

  const render = () => {
    setState(!state);
  };

  return {
    render,
  };
};

export default useForceRender;
