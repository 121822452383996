import React, { ReactElement } from 'react';
import { Button, Tooltip } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { CUSTOMER_DETAIL_ROUTE } from '@routes/constants';

export const CustomerActionTemplate = (id: string): ReactElement => {
  const history = useHistory();
  const handleCustomerEdit = () => {
    const customerUrl = CUSTOMER_DETAIL_ROUTE.replace(':id', id);
    history.push(customerUrl);
  };
  return (
    <Tooltip placement="bottom" title="Edit Customer">
      <Button onClick={handleCustomerEdit} type="text" className="bo-action-icon">
        <FormOutlined />
      </Button>
    </Tooltip>
  );
};

export const StringTemplate = (fieldText: string): ReactElement => <span>{fieldText || '-'}</span>;
