import React, { FunctionComponent } from 'react';
import { Layout, Menu, Tag } from 'antd';
import Icon from '@ant-design/icons';
import _get from 'lodash/get';

import SubMenu from 'antd/lib/menu/SubMenu';
import { NavLink, useLocation } from 'react-router-dom';
import { getActiveMenu } from '@utils/generalUtilities';
import BeaconLogoSvg from '@assets/images/beacon-logo.svg';
import CustomersSvg from '@assets/icons/customer.svg';
import UsersSvg from '@assets/icons/users.svg';
import { CUSTOMERS_ROUTE, USERS_ROUTE } from '@routes/constants';
import { useCurrentUser, useCurrentUserRoles } from '@queries/users';
import UserDropdown from '../UserDropdown';
import TestMode from '../TestMode';
import { SidebarItem } from './constants';
import { platformNavigation } from '../../platform/navigation';
import './style.less';

export type ActiveMenu = {
  activeMenuItem: [string];
  openSubmenu: [string];
};

const { Content } = Layout;

const SideBar: FunctionComponent = () => {
  const { pathname } = useLocation();

  const { data: currentUser } = useCurrentUser();
  const { data: userRoles } = useCurrentUserRoles(currentUser);

  const canViewScf = userRoles?.some((role) => ['SCF OPS', 'SCF Viewer'].includes(role.name)) || false;

  const items: (SidebarItem | undefined)[] = [
    ...platformNavigation(),
    {
      name: 'Customers',
      type: 'menuItem',
      svg: CustomersSvg,
      key: CUSTOMERS_ROUTE,
      internalLink: CUSTOMERS_ROUTE,
      includeDivider: true,
    },
    {
      name: 'Users',
      type: 'menuItem',
      svg: UsersSvg,
      key: USERS_ROUTE,
      internalLink: USERS_ROUTE,
      includeDivider: true,
    },
  ];

  const getMenuItem = (item: SidebarItem | undefined) => {
    return item ? (
      <Menu.Item
        key={item.key}
        icon={
          <>
            {item.svg && <Icon component={item.svg} style={{ fontSize: '1.25rem' }} />}
            {!item.svg && item.icon}
          </>
        }
        style={{ borderBottom: item.includeDivider ? '1px solid #f0f0f0' : 'none' }}
      >
        {item.internalLink ? (
          <NavLink data-cy={item.selector} to={item.internalLink} onClick={item.onClick}>
            {item.name}
          </NavLink>
        ) : (
          <a onClick={item.onClick} href={item.href}>
            {item.svg && <Icon component={item.svg} style={{ fontSize: '1.25rem' }} />}
            {!item.svg && item.icon}
            {item.name}
          </a>
        )}
      </Menu.Item>
    ) : null;
  };

  const menuItems = items.map((item) => {
    if (item && item.type === 'subMenu') {
      return (
        <SubMenu
          key={item.key}
          style={{
            borderBottom: item.includeDivider ? '1px solid #f0f0f0' : 'none',
          }}
          icon={
            <>
              {item.svg && <Icon component={item.svg} style={{ fontSize: '20px' }} />}
              {!item.svg && item.icon}
            </>
          }
          title={<span data-cy={item.selector}>{item.name}</span>}
        >
          {item.items &&
            item.items.map((item) => {
              return getMenuItem(item);
            })}
        </SubMenu>
      );
    }
    return getMenuItem(item);
  });

  const activeMenu = getActiveMenu(items, pathname);

  return (
    <Layout className="bo__side-bar" data-testid="sidebar">
      <div className="logo">
        <Content className="logo-container" data-testid="beacon-logo-container">
          <a href="/">
            <BeaconLogoSvg />
          </a>
          <Tag className="ops-tag" color="#1c2d42">
            <div className="ops-text">OPS</div>
          </Tag>
        </Content>
        <div id="logo-divider" />
      </div>
      <Menu
        defaultSelectedKeys={['/']}
        selectedKeys={[pathname]}
        defaultOpenKeys={_get(activeMenu, 'openSubmenu', [])}
        mode="inline"
      >
        {menuItems}
      </Menu>
      <TestMode />
      <UserDropdown />
    </Layout>
  );
};

export default SideBar;
