import { CreditProfileDTO, CreditProfileApi, Configuration } from '@beacon-devops/scf-client';
import { getAccessToken } from '@services/Auth';
import { GATEWAY_SERVICE } from '../constants';

const base = `${GATEWAY_SERVICE}/scf`;
const LOGIN_ERROR_MESSAGE = 'You must be logged in to use supply chain finance service';

const accessToken = () => getAccessToken();

const creditProfileApi = new CreditProfileApi(new Configuration({ basePath: base, accessToken }));

const fetchAll = async (): Promise<CreditProfileDTO[]> => {
  const token = await getAccessToken();
  if (token) {
    return creditProfileApi.getAllCustomersCreditProfile().then((response) => response.data);
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const getCreditProfile = async (customerId: string): Promise<CreditProfileDTO> => {
  const token = await getAccessToken();
  if (token) {
    return creditProfileApi.getCreditProfileByCustomerId(customerId).then((response) => response.data);
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const createCreditProfile = async (creditProfile: CreditProfileDTO) => {
  const token = await getAccessToken();
  if (token) {
    return creditProfileApi.createNewCreditProfile(creditProfile);
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const updateCreditProfile = async (creditProfile: CreditProfileDTO, version: string) => {
  const token = await getAccessToken();
  if (token) {
    return creditProfileApi.updateCreditProfile(version, creditProfile);
  }

  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const CreditProfileService = {
  getAll: fetchAll,
  getCreditProfile,
  createCreditProfile,
  updateCreditProfile,
};

export default CreditProfileService;
