import React, { useEffect, FC } from 'react';
import { initialiseFeatureFlags } from '@services/featureFlag';
import useForceRender from '@utils/common/useForceRender';

const FeatureFlagProvider: FC = ({ children }) => {
  const { render } = useForceRender();

  useEffect(() => {
    const startServices = async () => {
      await initialiseFeatureFlags();
      render();
    };

    startServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default FeatureFlagProvider;
