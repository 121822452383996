import { PagedSearchResponse } from '@beacon-devops/graphql-typescript-client';
import { GraphQLClient } from 'graphql-request';
import { SearchCargoQueryType } from '@services/graphql/types';

const searchCargoForCargoSwitcher = `
query searchCargoForCargoSwitcher(
	$criteria: [SearchCriteria!]
	$sort: [SearchSortCriteria!]
	$page: Int! = 0
	$size: Int! = 20
) {
	searchCargoForAllCustomers(
		criteria: $criteria
		sort: $sort
		page: $page
		size: $size
	) {
		page
		pageSize
		totalPages
		totalRecords
		cargos {
        id
        transportSummary {
          currentLeg
        }
        numberOfPackages
        weight
        weightUnit
        transports {
          sequenceNo
          carrierShipmentSequenceNo
          journey {
            references {
              flightNumber
            }
          }
        }
    }
	}
}
`;

interface QuerySearchCargoByCustomerIdParams extends SearchCargoQueryType {
  client: GraphQLClient;
}

export const querySearchCargoForCargoSwitcher = ({
  client,
  page,
  size,
  criteria,
  sort,
}: QuerySearchCargoByCustomerIdParams): Promise<{
  searchCargoForAllCustomers: PagedSearchResponse;
}> => client.request(searchCargoForCargoSwitcher, { page: page > 0 ? page - 1 : 0, size, sort, criteria });
