import UserService from '@services/User';
import { User } from '@services/constants';
import { AllUsersActions } from './constants';
import { Action, Thunk } from '../reduxTypes';

export const setUsers = (data: User[]): Action => ({
  type: AllUsersActions.GET_ALL_USERS_SUCCESS,
  payload: data,
});

export const setUsersRequest = (): Action => ({
  type: AllUsersActions.GET_ALL_USERS_REQUEST,
  payload: null,
});

export const setUsersError = (error: Error): Action => ({
  type: AllUsersActions.GET_ALL_USERS_ERROR,
  payload: error,
});

export const getUsers = (): Thunk<void> => {
  return (dispatch) => {
    return UserService.getAllUsers().then((users) => {
      dispatch(setUsers(users));
    });
  };
};
