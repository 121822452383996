import { RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';

export const setQueryParam = (
  history: RouteComponentProps['history'],
  incomingURLParams: Record<string, string | number | undefined>,
) => {
  const currentSearch = history.location.search;
  const urlParams = qs.parse(currentSearch);

  const newURLParams = {
    ...urlParams,
    ...incomingURLParams,
  };

  const queryString = qs.stringify(newURLParams);

  const compareQueryString = currentSearch ? `?${queryString}` : queryString;

  const shouldPush = currentSearch !== compareQueryString;

  // Only push if there is a change in query params
  if (shouldPush) {
    history.push({
      search: queryString,
    });
  }
};
