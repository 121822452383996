import { notEmpty } from '@utils/generalUtilities';
import { CustomerState, Action } from '../reduxTypes';
import { CUSTOMER_INITIAL_STATE, CustomerActions } from './constants';

type Reducers = {
  [key: string]: (state: CustomerState, action: Action) => CustomerState;
};

export default (state = CUSTOMER_INITIAL_STATE, action: Action): CustomerState => {
  const reducers: Reducers = {
    [CustomerActions.CUSTOMER_LOADING]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [CustomerActions.CREATE_CUSTOMER_SUCCESS]: (state) => ({
      ...state,
      createCustomerSuccess: true,
    }),
    [CustomerActions.SET_CUSTOMER]: (state, action) => ({
      ...state,
      customer: action.payload,
      isLoading: false,
    }),
    [CustomerActions.SET_CUSTOMERS]: (state, action) => ({
      ...state,
      customers: action.payload,
      isLoading: false,
    }),
    [CustomerActions.CLEAR_CUSTOMER]: (state) => ({
      ...state,
      isLoading: false,
      hasError: false,
      customer: undefined,
      createCustomerSuccess: false,
    }),
  };

  if (notEmpty(action) && reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};
