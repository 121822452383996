import { Role } from '@beacon-devops/customer-service-client';
import { User } from '@services/constants';
import UserService from '@services/User';
import { useQuery, UseQueryResult } from 'react-query';

export const useCurrentUserRoles = (user?: User): UseQueryResult<Role[], unknown> =>
  useQuery(['current-user-roles', user?.id], () => (user ? UserService.getUserRoles(user.companyId, user.id) : []), {
    enabled: !!user,
  });

export const useCurrentUser = (): UseQueryResult<User, unknown> =>
  useQuery(['current-user'], () => UserService.getUser());
