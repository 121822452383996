import { CustomerCapability, CustomerCapabilityDefinition } from '@beacon-devops/customer-service-client';

const createCapabilityFromDefinition = (definition: CustomerCapabilityDefinition): CustomerCapability => {
  return {
    id: definition.id,
    name: definition.name,
    enabled: false,
    code: '',
    quantity: undefined,
  };
};

const getEditableCapabilitiesFromSavedCapabilities = (
  availableCapabilities: CustomerCapabilityDefinition[],
  savedCapabilities: CustomerCapability[],
): CustomerCapability[] => {
  const savedCapabilitiesIds: string[] = savedCapabilities.map((cap) => cap.id).filter(Boolean) as string[];
  const notSavedCapabilities: CustomerCapabilityDefinition[] = availableCapabilities.filter(
    (x: CustomerCapabilityDefinition) => !savedCapabilitiesIds.includes(x.id),
  );
  return savedCapabilities.concat(notSavedCapabilities.map((def) => createCapabilityFromDefinition(def)));
};

export default getEditableCapabilitiesFromSavedCapabilities;
