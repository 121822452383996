import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import moment, { Moment } from 'moment';
import { SidebarItem } from '@components/SideBar/constants';
import { LOCALE } from '../constants';

export const notEmpty = (data: unknown): boolean => !_isEmpty(data);

export function isNonEmptyString(str: unknown): str is string {
  return typeof str === 'string' && str.length > 0;
}

export const compareStrings = (a: string, b: string): number =>
  notEmpty(a) ? a.trim().localeCompare(b.trim(), LOCALE) : -1;

export const sessionStorageService = {
  getItem: (name: string): string | null => {
    const item: string | null = sessionStorage.getItem(name);
    return item ? JSON.parse(item) : null;
  },
  setItem: (name: string, body: unknown): void => sessionStorage.setItem(name, JSON.stringify(body)),
  removeItem: (name: string): void => sessionStorage.removeItem(name),
  clear: (): void => sessionStorage.clear(),
};

interface ValueType {
  activeMenuItem: string[];
  openSubmenu: string[];
}

export const getActiveMenu = (items: (SidebarItem | undefined)[], pathname: string): ValueType => {
  const initialValue: ValueType = {
    activeMenuItem: [],
    openSubmenu: [],
  };
  const locationMatch = (data: SidebarItem) => data.internalLink === pathname;

  const filteredItems: SidebarItem[] = <SidebarItem[]>items.filter((item) => !!item);

  return _reduce(
    filteredItems,
    (previousItem: ValueType, topLevelItem: SidebarItem) => {
      const previous = previousItem;
      if (locationMatch(topLevelItem)) {
        previous.activeMenuItem = topLevelItem.key ? [topLevelItem.key] : [];
      }

      if (topLevelItem.items) {
        topLevelItem.items.forEach((subItem: SidebarItem) => {
          if (locationMatch(subItem) && subItem.key) {
            previous.activeMenuItem = [subItem.key];
            previous.openSubmenu = topLevelItem.key ? [topLevelItem.key] : [];
          }
        });
      }
      return previous;
    },
    initialValue,
  );
};

export const compareDates = (first?: string | Moment | null, second?: string | Moment | null): number => {
  if (first && !second) {
    return -1;
  }
  if (!first && second) {
    return 1;
  }
  if (moment(first).isBefore(moment(second))) {
    return -1;
  }
  if (moment(first).isSame(moment(second))) {
    return 0;
  }

  return 1;
};

export const alphabetize = <T>(terms: T[], path: string): T[] => {
  if (notEmpty(terms)) {
    const items = [...terms];
    items.sort((a: T, b: T) => {
      const textA = _get(a, `${path}`, '').toUpperCase();
      const textB = _get(b, `${path}`, '').toUpperCase();
      if (textA < textB) return -1;
      if (textA > textB) return 1;
      return 0;
    });
    return items;
  }
  return terms;
};

const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
];
const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

const firstLetterToUppercase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const stringifyNumber = (n: number) => {
  if (n < 20) return firstLetterToUppercase(special[n]);
  if (n % 10 === 0) return firstLetterToUppercase(`${deca[Math.floor(n / 10) - 2]}ieth`);
  return firstLetterToUppercase(`${deca[Math.floor(n / 10) - 2]}y-${special[n % 10]}`);
};
