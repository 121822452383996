import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Mode, PagedSearchResponse } from '@beacon-devops/graphql-typescript-client';
import GraphQLService from '@services/GraphQLService';
import { SearchCargoQueryType } from '@services/graphql/types';

export const useSearchCargoForAllCustomers = ({
  criteria = [],
  page = 1,
  size = 1,
  sort = [],
  mode = Mode.Ocean,
}: Partial<SearchCargoQueryType>) =>
  useQuery<PagedSearchResponse, AxiosError, PagedSearchResponse>(
    [`${mode?.toLowerCase()}-cargo-list-all-customers`, page, size, criteria, sort],
    () => {
      return GraphQLService.getCargosForAllCustomers({
        criteria,
        page,
        size,
        sort,
      });
    },
    {
      staleTime: Infinity,
      enabled: size > 0,
      retry: false,
    },
  );

export const useSearchCargoForCargoSwitcher = ({
  criteria = [],
  page = 1,
  size = 1,
  sort = [],
  mode = Mode.Ocean,
}: Partial<SearchCargoQueryType>) =>
  useQuery<PagedSearchResponse, AxiosError, PagedSearchResponse>(
    [`${mode?.toLowerCase()}-cargo-list-cargo-switcher`, page, size, criteria, sort],
    () => {
      return GraphQLService.getCargosForCargoSwitcher({
        criteria,
        page,
        size,
        sort,
      });
    },
    {
      staleTime: Infinity,
      enabled: size > 0,
      retry: false,
    },
  );
