import { SCVContainerFormValues } from '@platform/types';
import { PatchCargoDTO } from '@beacon-devops/container-tracking-api-model-ts-client/models/patch-cargo-dto';
import { Mode } from '@beacon-devops/container-tracking-api-model-ts-client';

export const handleCargoOceanDelete = (cargoFormData: SCVContainerFormValues): PatchCargoDTO => {
  let patchCargoDTO: PatchCargoDTO = {
    correlationIds: cargoFormData.correlationIds,
    identifier: cargoFormData.identifier,
    mode: Mode.Ocean,
    carrierShipments: [{ delete: true }],
  };
  return patchCargoDTO;
};

export const handleCargoAirDelete = (cargoFormData: SCVContainerFormValues): PatchCargoDTO => {
  let patchCargoDTO: PatchCargoDTO = {
    correlationIds: cargoFormData.correlationIds,
    identifier: cargoFormData.mawb,
    mode: Mode.Air,
    carrierShipments: [{ delete: true }],
  };
  return patchCargoDTO;
};
