import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { PurgeText } from './styles';

interface Props {
  isOpen: boolean;
  carrierShipmentId: string;
  customer: string;
  onPurge: () => void;
  onCancel: () => void;
}

export const ShipmentPurgeModal = ({ isOpen, carrierShipmentId, customer, onCancel, onPurge }: Props) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Modal
      title="Purging a shipment"
      okButtonProps={{
        danger: true,
      }}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          data-testid="container-modal-purge-button"
          key="submit"
          type="primary"
          danger
          onClick={() => {
            // on refetch later we have a huge setTimeout, so we can't use the real isLoading prop from purgeShipment mutation
            // thus why we use this local state to imitate "real" loading experience
            setLoading(true);
            onPurge();
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          Purge
        </Button>,
      ]}
      visible={isOpen}
    >
      <p>
        You are purging the shipment <strong>{carrierShipmentId}</strong> related to this cargo from{' '}
        <strong>{customer}</strong>.
      </p>
      <p>
        This will reset all statuses and fields on the shipment and the cargo. For air shipments, it will also delete
        ALL cargos except the main parent cargo.
      </p>
      <p>
        A stop tracking signal will be sent to immediately stop tracking this shipment. If you want to re-enable
        tracking you will need to re-ingest the container / MAWB.
      </p>
      <p>
        Click <PurgeText>Purge</PurgeText> to proceed with purge or click&nbsp;
        <strong>Cancel</strong> to return to the cargo.
      </p>
    </Modal>
  );
};
