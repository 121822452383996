import React, { ReactElement } from 'react';
import PageLayout from '@components/PageLayout';
import UserForm from '@components/User/UserForm';
import UserHeader from '@components/User/UserHeader';

export const UserInvite = (): ReactElement => {
  return (
    <PageLayout>
      <div style={{ marginBottom: '32px' }}>
        <UserHeader />
      </div>
      <UserForm />
    </PageLayout>
  );
};

export default UserInvite;
