import { PagedSearchResponse } from '@beacon-devops/graphql-typescript-client';
import { GraphQLClient } from 'graphql-request';
import { SearchCargoQueryType } from '@services/graphql/types';
import { querySearchCargoForCargoSwitcher } from '@services/graphql/getSearchCargoForCargoSwitcher';
import { querySearchCargoForAllCustomers } from '@services/graphql/getSearchCargoForAllCustomersQuery';
import { getAccessToken } from './Auth';
import { GATEWAY_SERVICE } from '../constants';

const endpoint = `${GATEWAY_SERVICE}/shipment-data-gateway/v1/graphql`;

const graphqlService = async (): Promise<GraphQLClient> => {
  const token = await getAccessToken();
  return new GraphQLClient(endpoint, { headers: { authorization: `Bearer ${token}` } });
};

const getCargosForAllCustomers = async (params: SearchCargoQueryType): Promise<PagedSearchResponse> => {
  const client = await graphqlService();

  const data = await querySearchCargoForAllCustomers({ client, ...params });

  return data.searchCargoForAllCustomers;
};

const getCargosForCargoSwitcher = async (params: SearchCargoQueryType): Promise<PagedSearchResponse> => {
  const client = await graphqlService();

  const data = await querySearchCargoForCargoSwitcher({ client, ...params });

  return data.searchCargoForAllCustomers;
};

export default {
  getCargosForAllCustomers,
  getCargosForCargoSwitcher,
};
