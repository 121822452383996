import { Cargo, EventClassifier, TransportEventType } from '@beacon-devops/graphql-typescript-client';
import { SCVContainerEventData, SCVContainerFormValues } from '@platform/types';
import {
  CargoEventSubtype,
  EventClassifierCode,
  Mode,
  PatchCargoDTO,
  TransportEventSubtype,
} from '@beacon-devops/container-tracking-api-model-ts-client';
import { mapSCVCargoEventData } from '@platform/pages/OceanContainer/utils/OceanContainerUtils';
import { mapSCVCargoFormatToForm } from '@platform/utils/index';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import {
  formTransportEventPatchDTO,
  handleCargoEventUpdate,
  handleCarrierShipmentUpdate,
  handleEquipmentKindUpdate,
  handleFreightForwarderNameUpdate,
  handleMBoLUpdate,
  handleOceanTransportUpdate,
} from './handlesUpdateCargoOcean';
import { handleAirCarrierShipmentUpdate, handleAirTransportUpdate } from './handlesUpdateCargoAir';

export const handleCargoFormSubmit = (
  originalCargo: Cargo,
  formData: SCVContainerFormValues,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<SCVContainerFormValues>>>,
  deletedEventIds: string[],
  mode: Mode = Mode.Ocean,
): PatchCargoDTO => {
  let patchCargoDTO: PatchCargoDTO = {
    correlationIds: formData.correlationIds,
    identifier: formData.identifier,
    mode,
    shipmentReferences: [],
    carrierShipments: [],
    cargos: [],
    cargoEvents: [],
    transportEvents: [],
    oceanVehicles: [],
    journeys: [],
  };

  const isOcean = mode === Mode.Ocean;
  const isAir = mode === Mode.Air;

  if (dirtyFields.masterBillOfLading) {
    patchCargoDTO.shipmentReferences?.push(handleMBoLUpdate(formData));
  }

  if (dirtyFields.freightForwarderName) {
    patchCargoDTO.shipmentReferences?.push(handleFreightForwarderNameUpdate(formData));
  }

  if ((dirtyFields.carrier || dirtyFields.departurePort || dirtyFields.arrivalPort) && isOcean) {
    patchCargoDTO.carrierShipments?.push(handleCarrierShipmentUpdate(formData));
  }

  if (isAir) {
    patchCargoDTO.carrierShipments?.push(handleAirCarrierShipmentUpdate(formData));
  }

  if (dirtyFields.equipmentKind) {
    patchCargoDTO.cargos?.push(handleEquipmentKindUpdate(formData));
  }

  /*
   * Cargo Events
   */
  const initialFormData = mapSCVCargoFormatToForm(originalCargo, '', '');
  const containerEventData: SCVContainerEventData = mapSCVCargoEventData(originalCargo);

  const defaultCargoEventProps = {
    originalCargo,
    initialFormData,
    formData,
    classifier: EventClassifierCode.Actual,
  };

  if (dirtyFields.gateOutEmpty?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.GateOutEmpty,
        accessString: 'gateOutEmpty.actualDate',
        eventData: containerEventData.gateOutEmpty.actual,
        deletedEventIds,
      }),
    );
  }

  if (dirtyFields.gateInFull?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.GateInFull,
        accessString: 'gateInFull.actualDate',
        eventData: containerEventData.gateInFull.actual,
        deletedEventIds,
      }),
    );
  }

  if (dirtyFields.loaded?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.Load,
        accessString: 'loaded.actualDate',
        eventData: containerEventData.loaded.actual,
        deletedEventIds,
      }),
    );
  }

  if (dirtyFields.discharged?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.Discharge,
        accessString: 'discharged.actualDate',
        eventData: containerEventData.discharged.actual,
        deletedEventIds,
      }),
    );
  }

  if (dirtyFields.gateOutFull?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.GateOutFull,
        accessString: 'gateOutFull.actualDate',
        eventData: containerEventData.gateOutFull.actual,
        deletedEventIds,
      }),
    );
  }

  if (dirtyFields.gateInEmpty?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.GateInEmpty,
        accessString: 'gateInEmpty.actualDate',
        eventData: containerEventData.gateInEmpty.actual,
        deletedEventIds,
      }),
    );
  }

  if (dirtyFields.bookingConfirmed?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.BookingConfirmed,
        accessString: 'bookingConfirmed.actualDate',
        eventData: containerEventData.bookingConfirmed.actual,
        deletedEventIds,
      }),
    );
  }

  if (dirtyFields.receive?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.Receive,
        accessString: 'receive.actualDate',
        eventData: containerEventData.receive.actual,
        deletedEventIds,
      }),
    );
  }

  if (dirtyFields.collect?.actualDate) {
    patchCargoDTO.cargoEvents?.push(
      handleCargoEventUpdate({
        ...defaultCargoEventProps,
        subType: CargoEventSubtype.Collect,
        accessString: 'collect.actualDate',
        eventData: containerEventData.collect.actual,
        deletedEventIds,
      }),
    );
  }

  /*
   * Transport Events
   */
  const defaultTransportEventProps = {
    originalCargo,
    initialFormData,
    formData,
    containerEventData,
    deletedEventIds,
  };

  if (dirtyFields.berth?.actualDate) {
    patchCargoDTO.transportEvents?.push(
      formTransportEventPatchDTO({
        ...defaultTransportEventProps,
        subType: TransportEventSubtype.Berth,
        classifier: EventClassifierCode.Actual,
        accessString: 'berth.actualDate',
        eventAccessString: 'berth.actual',
      }),
    );
  }

  if (dirtyFields?.departure?.expectedDate) {
    patchCargoDTO.transportEvents?.push(
      formTransportEventPatchDTO({
        ...defaultTransportEventProps,
        subType: TransportEventType.Depart,
        classifier: EventClassifier.Estimated,
        accessString: 'departure.expectedDate',
        eventAccessString: 'departure.expected',
      }),
    );
  }

  if (dirtyFields?.departure?.actualDate) {
    patchCargoDTO.transportEvents?.push(
      formTransportEventPatchDTO({
        ...defaultTransportEventProps,
        subType: TransportEventType.Depart,
        classifier: EventClassifier.Actual,
        accessString: 'departure.actualDate',
        eventAccessString: 'departure.actual',
      }),
    );
  }

  if (dirtyFields?.arrival?.actualDate) {
    patchCargoDTO.transportEvents?.push(
      formTransportEventPatchDTO({
        ...defaultTransportEventProps,
        subType: TransportEventType.Arrive,
        classifier: EventClassifier.Actual,
        accessString: 'arrival.actualDate',
        eventAccessString: 'arrival.actual',
      }),
    );
  }

  if (dirtyFields?.arrival?.expectedDate) {
    patchCargoDTO.transportEvents?.push(
      formTransportEventPatchDTO({
        ...defaultTransportEventProps,
        subType: TransportEventType.Arrive,
        classifier: EventClassifier.Estimated,
        accessString: 'arrival.expectedDate',
        eventAccessString: 'arrival.expected',
      }),
    );
  }

  if (dirtyFields?.transhipments?.length) {
    dirtyFields?.transhipments?.forEach((transhipment, index) => {
      if (transhipment.loaded?.actualDate) {
        patchCargoDTO.cargoEvents?.push(
          handleCargoEventUpdate({
            ...defaultCargoEventProps,
            subType: CargoEventSubtype.Load,
            accessString: `transhipments[${index}].loaded.actualDate`,
            eventData: containerEventData.transhipments[index].loaded.actual,
            deletedEventIds,
          }),
        );
      }

      if (transhipment.discharged?.actualDate) {
        patchCargoDTO.cargoEvents?.push(
          handleCargoEventUpdate({
            ...defaultCargoEventProps,
            subType: CargoEventSubtype.Discharge,
            accessString: `transhipments[${index}].discharged.actualDate`,
            eventData: containerEventData.transhipments[index].discharged.actual,
            deletedEventIds,
          }),
        );
      }

      if (transhipment.berth?.actualDate) {
        patchCargoDTO.transportEvents?.push(
          formTransportEventPatchDTO({
            ...defaultTransportEventProps,
            subType: TransportEventSubtype.Berth,
            classifier: EventClassifierCode.Actual,
            accessString: `transhipments[${index}].berth.actualDate`,
            eventAccessString: `transhipments[${index}].berth.actual`,
          }),
        );
      }

      if (transhipment?.departure?.expectedDate) {
        patchCargoDTO.transportEvents?.push(
          formTransportEventPatchDTO({
            ...defaultTransportEventProps,
            subType: TransportEventType.Depart,
            classifier: EventClassifier.Estimated,
            accessString: `transhipments[${index}].departure.expectedDate`,
            eventAccessString: `transhipments[${index}].departure.expected`,
          }),
        );
      }

      if (transhipment?.departure?.actualDate) {
        patchCargoDTO.transportEvents?.push(
          formTransportEventPatchDTO({
            ...defaultTransportEventProps,
            subType: TransportEventType.Depart,
            classifier: EventClassifier.Actual,
            accessString: `transhipments[${index}].departure.actualDate`,
            eventAccessString: `transhipments[${index}].departure.actual`,
          }),
        );
      }

      if (transhipment?.arrival?.actualDate) {
        patchCargoDTO.transportEvents?.push(
          formTransportEventPatchDTO({
            ...defaultTransportEventProps,
            subType: TransportEventType.Arrive,
            classifier: EventClassifier.Actual,
            accessString: `transhipments[${index}].arrival.actualDate`,
            eventAccessString: `transhipments[${index}].arrival.actual`,
          }),
        );
      }

      if (transhipment?.arrival?.expectedDate) {
        patchCargoDTO.transportEvents?.push(
          formTransportEventPatchDTO({
            ...defaultTransportEventProps,
            subType: TransportEventType.Arrive,
            classifier: EventClassifier.Estimated,
            accessString: `transhipments[${index}].arrival.expectedDate`,
            eventAccessString: `transhipments[${index}].arrival.expected`,
          }),
        );
      }

      if (isOcean && (transhipment.port || transhipment.voyagerNumber || transhipment.vessel)) {
        patchCargoDTO = {
          ...patchCargoDTO,
          ...handleOceanTransportUpdate({
            originalCargo,
            formData,
          }),
        };
      }

      if (isAir && (transhipment.port || transhipment.flightNumber)) {
        patchCargoDTO = {
          ...patchCargoDTO,
          ...handleAirTransportUpdate({
            originalCargo,
            formData,
          }),
        };
      }
    });
  }

  if (
    isOcean &&
    (dirtyFields.departurePort || dirtyFields.arrivalPort || dirtyFields.voyagerNumber || dirtyFields.vessel)
  ) {
    patchCargoDTO = {
      ...patchCargoDTO,
      ...handleOceanTransportUpdate({
        originalCargo,
        formData,
      }),
    };
  }

  if (isAir && (dirtyFields.departurePort || dirtyFields.arrivalPort || dirtyFields.flightNumber)) {
    patchCargoDTO = {
      ...patchCargoDTO,
      ...handleAirTransportUpdate({
        originalCargo,
        formData,
      }),
    };
  }

  return patchCargoDTO;
};
