import { Mode, SearchConditionType } from '@beacon-devops/graphql-typescript-client';

// Type of widget for a filter in filter list
export enum FilterWidgetType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  CUSTOMER = 'CUSTOMER',
}

interface FilterOptionsType {
  label: string;
  value: string;
}

interface CargoFilterSupportedCondition {
  condition: SearchConditionType;
  filterWidgetType: FilterWidgetType;
  filterWidgetOptions?: FilterOptionsType[];
  disabled?: boolean;
  defaultValue?: string[];
  // Separators for enabling users to multi-paste values
  tokenSeparators?: string[];
}

export interface CargoFilterCurrentValue {
  condition: SearchConditionType;
  value: string[];
}

export type CargoSortColumns =
  | 'customerId'
  | 'status'
  | 'cargoId'
  | 'mawb'
  | 'containerNumber'
  | 'flightNumber'
  | 'carrierName'
  | 'vesselName'
  | 'departurePort'
  | 'arrivalPort'
  | 'departureDate'
  | 'arrivalDate';

export interface CargoListFilterWidgetType {
  // Label to show on filter list
  label: string;
  // Identify a filter and serialize in URL
  key: CargoSortColumns;
  // Display extra info for a label on filter list
  labelSub?: string;
  // Current condition and values in filters list
  currentValue?: CargoFilterCurrentValue;
  // All supported conditions for a filter
  supportedConditions: CargoFilterSupportedCondition[];
  supportedModes?: Mode[];
}

export type CargoFilterURLEntry = Pick<CargoListFilterWidgetType, 'key' | 'currentValue'>;
