import React, { ReactElement, useEffect, useState } from 'react';
import { Customer } from '@beacon-devops/customer-service-client';
import { useParams } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { Skeleton } from 'antd';
import { notEmpty } from '@utils/generalUtilities';
import CustomerService from '@services/CustomerService';
import PageLayout from '@components/PageLayout';
import './styles.less';

import { useAllCustomerCapabilityDefinitions } from '@queries/customers';
import CustomerOnboardingForm from './CustomerOnboardingForm';

const CustomerPage = (): ReactElement => {
  const { data: capabilityDefinitions } = useAllCustomerCapabilityDefinitions();
  const [customer, setCustomer] = useState({} as Customer);
  const [isLoading, setIsLoading] = useState(false);
  const { id: customerId } = useParams<{ id: string }>();
  const isEditing = notEmpty(customerId);

  const getCustomer = async () => {
    if (_isEmpty(customer) && notEmpty(customerId)) {
      setIsLoading(true);

      const customer = await CustomerService.getCustomer(customerId);

      if (customer) setCustomer(customer);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-customer-page">
      <PageLayout>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            {isEditing
              ? !!customer &&
                !!capabilityDefinitions && (
                  <CustomerOnboardingForm
                    customer={customer}
                    customerId={customerId}
                    editMode
                    setCustomer={setCustomer}
                    capabilityDefinitions={capabilityDefinitions}
                  />
                )
              : !!capabilityDefinitions && (
                  <CustomerOnboardingForm
                    editMode={false}
                    setCustomer={setCustomer}
                    capabilityDefinitions={capabilityDefinitions}
                  />
                )}
          </>
        )}
      </PageLayout>
    </div>
  );
};

export default CustomerPage;
