import { Customer } from '@beacon-devops/customer-service-client';
import _get from 'lodash/get';
import { AppState, CustomerFE } from '../reduxTypes';

const transformCustomer = (customer: Customer): CustomerFE => {
  const firstName = _get(customer, 'ownerDetails.firstName', '');
  const lastName = _get(customer, 'ownerDetails.lastName', '');
  const createdAt = _get(customer, 'created.triggerAt', undefined);
  const domains = _get(customer, 'companyDomains', []);

  return {
    ...customer,
    ownerName: `${firstName}${firstName ? ` ${lastName}` : lastName}`,
    createdAt,
    companyDomainsCombined: domains.join(','),
  };
};

export const getCustomersFromState = (state: AppState) => state.customerState.customers.map(transformCustomer);
