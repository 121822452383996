import React from 'react';
import { ZoomInOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import moment from 'moment';

import { Location } from '@beacon-devops/shipment-tracker-client';

/**
 * All possible entity types that can be viewed in this tool. These appear in URLs as well.
 */
export enum ScvEntityType {
  cargo = 'cargo',
  cargoEvent = 'cargoEvent',
  carrierShipment = 'carrierShipment',
  location = 'location',
  transport = 'transport',
  transportCall = 'transportCall',
  transportEvent = 'transportEvent',
}

// Type definitions for changing / adding entities on the main page
export type AddInspectionEntity = (entityType: ScvEntityType, entityId: string) => void;
export type RemoveInspectionEntity = (entityId: string) => void;

/**
 * Renders a table row containing the entity item name and value, only if the value exists!
 */
export function renderEntityItem(name: string, value): JSX.Element {
  return value ? (
    <Row>
      <Col span={6}>{name}:</Col>
      <Col span={18}>{value}</Col>
    </Row>
  ) : (
    <></>
  );
}

/**
 * Renders a table row containing the entity item name and date / time value, only if the value exists!
 */
export function renderEntityItemDateTime(name: string, value: string | undefined): JSX.Element {
  return value ? renderEntityItem(name, moment(value).format('YYYY/MM/DD hh:mm:ss')) : <></>;
}

/**
 * Builds a location link which consists of the location name as well as inspection links
 */
export function buildLocationLink(
  location: Location | undefined,
  locationId: string | undefined,
  addInspectEntityFunc: AddInspectionEntity,
): JSX.Element {
  if (location && locationId) {
    const link = (
      <>
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            addInspectEntityFunc(ScvEntityType.location, locationId);
          }}
        >
          <ZoomInOutlined />
        </a>
        &nbsp;&nbsp;
        <a href={`/platform/scvcom/${ScvEntityType.location}/${locationId}`}>
          <FullscreenOutlined />
        </a>
      </>
    );
    return (
      <div>
        {location.name} {link}
      </div>
    );
  }
  return <></>;
}

/**
 * Builds a link to open up the entity in the inspection area of the page.
 * @param entityType The entity type for the link e.g. cargo, carrierShipment
 * @param entityId The Beacon ID for the entity e.g. bcn1.ti.cargo.foo
 */
export function buildInspectLink(
  entityType: ScvEntityType,
  entityId: string | undefined,
  addInspectEntityFunc: AddInspectionEntity,
  showText = false,
): JSX.Element {
  if (entityId) {
    const link = (
      <>
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            addInspectEntityFunc(entityType, entityId);
          }}
        >
          <ZoomInOutlined />
        </a>
        &nbsp;&nbsp;
        <a href={`/platform/scvcom/${entityType}/${entityId}`}>
          <FullscreenOutlined />
        </a>
      </>
    );
    return showText ? (
      <div>
        {entityId} {link}
      </div>
    ) : (
      link
    );
  }
  return <></>;
}
