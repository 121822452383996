import { notEmpty } from '@utils/index';
import { UserActions, USER_INITIAL_STATE } from './constants';
import { Action, UserState } from '../reduxTypes';

type Reducers = {
  [key: string]: (state: UserState, action: Action) => UserState;
};

const reducers: Reducers = {
  [UserActions.GET_USER_REQUEST]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },

  [UserActions.GET_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
    };
  },

  [UserActions.SET_USER_IS_AUTHENTICATED]: (state, action: Action) => {
    return {
      ...state,
      isAuthenticated: notEmpty(action.payload),
      loading: false,
    };
  },

  [UserActions.GET_SELECTED_USER_REQUEST]: (state) => {
    return {
      ...state,
      selected: {
        data: undefined,
        loading: true,
        error: null,
      },
    };
  },

  [UserActions.GET_SELECTED_USER_ERROR]: (state, action: Action) => {
    return {
      ...state,
      selected: {
        data: undefined,
        loading: false,
        error: action.payload,
      },
    };
  },

  [UserActions.DELETE_SELECTED_USER_REQUEST]: (state) => {
    return {
      ...state,
      selected: {
        data: state.selected.data,
        loading: true,
        error: null,
      },
    };
  },

  [UserActions.UPDATE_SELECTED_USER_REQUEST]: (state) => {
    return {
      ...state,
      selected: {
        data: state.selected.data,
        loading: true,
        error: null,
      },
    };
  },

  [UserActions.DELETE_SELECTED_USER_ERROR]: (state, action: Action) => {
    return {
      ...state,
      selected: {
        data: state.selected.data,
        loading: false,
        error: action.payload,
      },
    };
  },

  [UserActions.UPDATE_SELECTED_USER_ERROR]: (state, action: Action) => {
    return {
      ...state,
      selected: {
        data: state.selected.data,
        loading: false,
        error: action.payload,
      },
    };
  },

  [UserActions.SET_SELECTED_USER]: (state, action: Action) => {
    return {
      ...state,
      selected: {
        data: action.payload,
        loading: false,
        error: null,
      },
    };
  },

  [UserActions.SET_SELECTED_USER_ROLES]: (state, action: Action) => {
    return {
      ...state,
      roles: {
        userRoles: action?.payload,
        rolesList: state.roles.rolesList,
      },
    };
  },

  [UserActions.SET_ROLES_LIST]: (state, action: Action) => {
    return {
      ...state,
      roles: {
        userRoles: state.roles.userRoles,
        rolesList: action.payload,
      },
    };
  },
};

export default (state = USER_INITIAL_STATE, action: Action): UserState => {
  if (notEmpty(action) && reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};
