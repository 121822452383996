import React, { ReactElement } from 'react';
import CargoSearchProvider from '@platform/components/CargoSearchProvider';
import PageLayout from '@components/PageLayout';
import OceanCargoList from '@platform/components/OceanCargoList';

const OceanList = (): ReactElement => {
  return (
    <PageLayout>
      <CargoSearchProvider>
        <OceanCargoList />
      </CargoSearchProvider>
    </PageLayout>
  );
};

export default OceanList;
