import styled, { css } from 'styled-components';
import { List } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

export const CargoSearchFieldsWrapper = styled.section`
  margin: 16px 0;
`;

export const SelectedFiltersWrapper = styled.div<{ isFirst: boolean }>`
  ${({ isFirst }) =>
    isFirst
      ? css`
          margin-top: 0;
        `
      : css`
          margin-top: 20px;
        `}
`;

export const FilterListWrapper = styled.div`
  padding-top: 20px;
`;

const filterItemBGColor = '#dbe2eb';
const filterAddIconColor = '#737981';

export const AddFilterIcon = styled(PlusOutlined)`
  font-size: 20px;
  color: ${filterAddIconColor};
  cursor: pointer;
`;

export const FilterListItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: none !important;

  &:hover {
    background: ${filterItemBGColor};
  }
`;
