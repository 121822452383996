import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale-provider/en_GB';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { Store } from '@state/store';
import { App } from './App';
import 'moment/locale/en-gb';

const store = Store();

Sentry.init({
  dsn: 'https://53a7b791f6e34f31b0084e5a0a283c38@o447732.ingest.sentry.io/5782102',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root'),
);
