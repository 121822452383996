import React from 'react';
import { Cargo } from '@beacon-devops/graphql-typescript-client';
import { SCVContainerFormValues } from '@platform/types';
import { ErrorSectionWrapper, ErrorWrapper } from '@platform/components/CargoValidationErrorSection/styles';
import { UseFormReturn } from 'react-hook-form';
import { handleValidation, validationRules } from '@platform/utils/handleValidation';
import { Alert } from 'antd';

interface CargoValidationErrorSectionProps {
  originalCargo: Cargo;
  formMethods: UseFormReturn<SCVContainerFormValues>;
}

const ErrorMessage = (props: { message: string }) => {
  return (
    <ErrorWrapper>
      <Alert message={props.message} type="warning" showIcon />
    </ErrorWrapper>
  );
};

const CargoValidationErrorSection = ({ formMethods, originalCargo }: CargoValidationErrorSectionProps) => {
  const formData = formMethods.watch();

  const validationResult = handleValidation({
    originalCargo,
    formData,
  });

  return (
    <ErrorSectionWrapper>
      {!validationResult.mawbValid && <ErrorMessage message={validationRules.mawbValid.errorMessage} />}
      {!validationResult.statusValid && <ErrorMessage message={validationRules.statusValid.errorMessage} />}
    </ErrorSectionWrapper>
  );
};

export default CargoValidationErrorSection;
