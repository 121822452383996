import { notEmpty } from '@utils/index';
import { AllUsersActions, ALL_USER_INITIAL_STATE } from './constants';
import { Action, AllUsersState } from '../reduxTypes';

type Reducers = {
  [key in AllUsersActions]: (state: AllUsersState, action: Action) => AllUsersState;
};

const reducers: Reducers = {
  [AllUsersActions.GET_ALL_USERS_REQUEST]: () => {
    return {
      data: [],
      loading: true,
      error: null,
    };
  },
  [AllUsersActions.GET_ALL_USERS_SUCCESS]: (state, action) => {
    return {
      data: action.payload,
      loading: false,
      error: null,
    };
  },
  [AllUsersActions.GET_ALL_USERS_ERROR]: (state, action) => {
    return {
      data: [],
      loading: false,
      error: action.payload,
    };
  },
};

export default (state = ALL_USER_INITIAL_STATE, action: Action): AllUsersState => {
  if (notEmpty(action) && reducers[action.type as AllUsersActions]) {
    return reducers[action.type as AllUsersActions](state, action);
  }
  return state;
};
