import React, { FC, Fragment } from 'react';
import { Badge, Input } from 'antd';
import { Controller, UseControllerProps, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import TimelineMilestoneIcon from '@assets/icons/Timeline_milestone.svg';
import { SCVContainerFormValues } from '@platform/types';
import { Box } from '@beacon-devops/components';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { ContainerFromToDisplay } from '@platform/components/ContainerFromToDisplay';
import ScvVesselSearch from '@platform/components/VesselSearch/ScvVesselSearch';
import { ScvContainerDate } from '@platform/components/ContainerDate';
import PortSearch from '@platform/components/PortSearch';
import { stringifyNumber } from '@utils/generalUtilities';
import { EventProps, LocationType } from '@platform/utils';
import {
  BerthedPlacement,
  CargoLoadedPlacement,
  CargoOffLoadedPlacement,
  CDetailsCard,
  CDetailsCardContent2RowsLayout,
  CDetailsCardContentLayout,
  CDetailsTimelineItem,
  FirstVesselImoPlacement,
  FormLabel,
  FromToPlacement,
} from './styles';

interface TranshipmentFieldArrayProps {
  mode?: Mode;
  getDateProps: (eventKey: UseControllerProps<SCVContainerFormValues>['name']) => EventProps;
}

// const defaultSearchField = {
//   id: '',
//   name: '',
// };
//
// const defaultDateEventTime = {
//   date: '',
//   timestamp: '',
//   zone: '',
// };

// const defaultTranshipmentField: TranshipmentFields = {
//   port: defaultSearchField,
//   discharged: {
//     actualDate: defaultDateEventTime,
//   },
//   loaded: {
//     actualDate: defaultDateEventTime,
//   },
//   vessel: defaultSearchField,
//   voyagerNumber: '',
//   departure: {
//     actualDate: defaultDateEventTime,
//     expectedDate: defaultDateEventTime,
//   },
//   arrival: {
//     actualDate: defaultDateEventTime,
//     expectedDate: defaultDateEventTime,
//   },
//   berth: {
//     actualDate: defaultDateEventTime,
//   },
// };

const getPortLabel = (mode: Mode): string => {
  switch (mode) {
    case Mode.Ocean:
      return 'Seaport';
    case Mode.Air:
      return 'Airport';
    default:
      return 'Port';
  }
};

const TranshipmentFieldArray: FC<TranshipmentFieldArrayProps> = ({ getDateProps, mode = Mode.Ocean }) => {
  const { control, setValue, watch } = useFormContext<SCVContainerFormValues>();
  // const { fields, insert, remove } = useFieldArray({
  const { fields } = useFieldArray({
    control,
    name: 'transhipments',
  });

  const portOfDischarge = useWatch({
    control,
    name: 'arrivalPort',
  });

  const watchTranshipments = watch('transhipments');
  const portLabel = getPortLabel(mode);

  const handlePortOfLoading = (position: number) => {
    const thisTranshipment = watchTranshipments ? watchTranshipments[position] : undefined;
    return thisTranshipment ? thisTranshipment?.port?.name : '';
  };

  const handlePortOfDischarge = (position: number) => {
    const nextTranshipment = watchTranshipments ? watchTranshipments[position + 1] : undefined;
    if (nextTranshipment) {
      return `${nextTranshipment?.port?.name || `Transhipment ${portLabel} ${position + 2}`}`;
    }

    return portOfDischarge?.name || '';
  };

  // const addTranshipment = (position: number) => (
  //   <CDetailsTimelineItem dot={<CDetailsTimelinePoint />}>
  //     <Popconfirm
  //       title='Are you sure to add a new transhipment?'
  //       onConfirm={() => {
  //         insert(position, defaultTranshipmentField);
  //         message.success('Transhipment added!');
  //       }}
  //       okText='Yes'
  //       cancelText='No'
  //       placement='right'
  //     >
  //       <Button type='primary' icon={<PlusOutlined />}>
  //         Add transhipment
  //       </Button>
  //     </Popconfirm>
  //   </CDetailsTimelineItem>
  // );

  // const removeTranshipment = (position: number) => (
  //   <Popconfirm
  //     title='Are you sure to delete this transhipment?'
  //     onConfirm={() => {
  //       remove(position);
  //       message.success('Transhipment removed!');
  //     }}
  //     okText='Yes'
  //     cancelText='No'
  //     placement='left'
  //   >
  //     <Tooltip placement='left' title='Delete this transhipment'>
  //       <RemoveTranshipment type='ghost' danger shape='circle' icon={<CloseOutlined />} size='small' />
  //     </Tooltip>
  //   </Popconfirm>
  // );

  const isOcean = mode === Mode.Ocean;
  const isAir = mode === Mode.Air;

  return (
    <>
      {/* {addTranshipment(0)} */}

      {fields.length
        ? fields.map((field, index) => {
            const outputTranshipmentNumber = index + 1;

            return (
              <Fragment key={field.id}>
                <Badge.Ribbon text={`Transhipment ${outputTranshipmentNumber}`}>
                  <CDetailsTimelineItem dot={<TimelineMilestoneIcon />}>
                    <CDetailsCard mb={8}>
                      <CDetailsCardContentLayout>
                        <PortSearch
                          name={`transhipments.${index}.port`}
                          label={`Transhipment ${portLabel} ${outputTranshipmentNumber}`}
                          type={mode === Mode.Air ? LocationType.Airport : LocationType.Seaport}
                        />
                        {isOcean && (
                          <>
                            <ScvContainerDate
                              label="Discharged at T/S"
                              {...getDateProps(`transhipments.${index}.discharged.actualDate`)}
                            />
                            <ScvContainerDate
                              label="Loaded at T/S"
                              {...getDateProps(`transhipments.${index}.loaded.actualDate`)}
                            />
                          </>
                        )}
                      </CDetailsCardContentLayout>

                      {/* {removeTranshipment(index)} */}
                    </CDetailsCard>
                    <CDetailsCard>
                      <CDetailsCardContent2RowsLayout>
                        <FromToPlacement>
                          <ContainerFromToDisplay from={handlePortOfLoading(index)} to={handlePortOfDischarge(index)} />
                        </FromToPlacement>

                        {isOcean && (
                          <>
                            <ScvVesselSearch
                              label={`${stringifyNumber(index + 2)} Vessel Name`}
                              name={`transhipments.${index}.vessel.name`}
                              setImoNumber={(imoNumber: string) =>
                                setValue(`transhipments.${index}.vessel.id`, imoNumber)
                              }
                            />
                            <FirstVesselImoPlacement>
                              <FormLabel htmlFor={`transhipments.${index}.voyagerNumber`}>Voyage Number</FormLabel>
                              <Controller
                                control={control}
                                name={`transhipments.${index}.voyagerNumber`}
                                render={({ field }) => <Input {...field} id={`transhipments.${index}.voyagerNumber`} />}
                              />
                              <Box mt={7}>
                                <FormLabel htmlFor={`transhipments.${index}.vessel.id`}>
                                  {stringifyNumber(index + 2)} Vessel IMO
                                </FormLabel>
                                <Controller
                                  control={control}
                                  name={`transhipments.${index}.vessel.id`}
                                  rules={{
                                    pattern: /^[0-9]{7}$/i,
                                  }}
                                  render={({ field }) => (
                                    <Input disabled {...field} id={`transhipments.${index}.vessel.id`} />
                                  )}
                                />
                              </Box>
                            </FirstVesselImoPlacement>
                          </>
                        )}

                        {isAir && (
                          <>
                            <Box>
                              <FormLabel htmlFor={`transhipments.${index}.flightNumber`}>Flight Number</FormLabel>
                              <Controller
                                control={control}
                                name={`transhipments.${index}.flightNumber`}
                                render={({ field }) => <Input {...field} id={`transhipments.${index}.flightNumber`} />}
                              />
                            </Box>

                            <CargoLoadedPlacement>
                              <ScvContainerDate
                                label="Cargo Loaded"
                                {...getDateProps(`transhipments.${index}.loaded.actualDate`)}
                              />
                            </CargoLoadedPlacement>
                          </>
                        )}

                        <ScvContainerDate
                          label="Estimated Departure"
                          {...getDateProps(`transhipments.${index}.departure.expectedDate`)}
                        />
                        <ScvContainerDate
                          label="Estimated Arrival"
                          {...getDateProps(`transhipments.${index}.arrival.expectedDate`)}
                        />
                        <ScvContainerDate
                          label="Actual Departure"
                          {...getDateProps(`transhipments.${index}.departure.actualDate`)}
                        />
                        <ScvContainerDate
                          label="Actual Arrival"
                          {...getDateProps(`transhipments.${index}.arrival.actualDate`)}
                        />
                        {isAir && (
                          <CargoOffLoadedPlacement>
                            <ScvContainerDate
                              label="Cargo Off-Loaded"
                              {...getDateProps(`transhipments.${index}.discharged.actualDate`)}
                            />
                          </CargoOffLoadedPlacement>
                        )}

                        {isOcean && (
                          <>
                            {index === fields.length - 1 ? (
                              <BerthedPlacement>
                                <ScvContainerDate
                                  label="Actual Berthed"
                                  {...getDateProps(`transhipments.${index}.berth.actualDate`)}
                                />
                              </BerthedPlacement>
                            ) : null}
                          </>
                        )}
                      </CDetailsCardContent2RowsLayout>
                    </CDetailsCard>
                  </CDetailsTimelineItem>
                </Badge.Ribbon>

                {/* {addTranshipment(outputTranshipmentNumber)} */}
              </Fragment>
            );
          })
        : null}
    </>
  );
};
export default TranshipmentFieldArray;
