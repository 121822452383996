export const USERS_ROUTE = '/users';
export const USER_INVITE_ROUTE = '/users/invite';
export const USER_INVITE_TO_COMPANY_ROUTE = '/users/invite/:customerId';
export const USER_DETAIL_ROUTE = '/users/:customerId/:userId';
export const USER_UPDATE_ROUTE = '/users/:customerId/:userId/update';
export const CUSTOMERS_ROUTE = '/customers';
export const CUSTOMERS_CREATE_ROUTE = '/customers/create';
export const CUSTOMER_DETAIL_ROUTE = '/customers/:id';
export const PLATFORM_ROUTE = '/platform';
export const LOGOUT = '/logout';
