import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import _isEmpty from 'lodash/isEmpty';
import { UnParsedTableURLParams } from '@beacon-types/Table';

export function getTableURLParams<T>(
  pagination: TablePaginationConfig,
  sorter: SorterResult<T>,
): UnParsedTableURLParams {
  const { pageSize, current } = pagination;
  const urlParams: UnParsedTableURLParams = {};
  if (pageSize) {
    urlParams.pageSize = pageSize.toString();
  }
  if (current) {
    urlParams.current = current.toString();
  }

  if (!_isEmpty(sorter) && sorter && sorter.order) {
    urlParams.sorter = JSON.stringify({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  } else {
    urlParams.sorter = undefined;
  }

  return urlParams;
}
