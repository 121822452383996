import React, { useContext } from 'react';
import TableHeader from '@components/common/TableHeader';
import { Alert, Button, Empty, Table, TablePaginationConfig } from 'antd';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { SearchButton } from '@platform/components/OceanCargoList/styles';
import { CargoSearchContext } from '@platform/components/CargoSearchProvider/context';
import { useHistory, useLocation } from 'react-router';
import { parseCargoURLParamsFromSearch } from '@utils/platform/cargo';
import { useSearchCargoForAllCustomers } from '@queries/graphql';
import { Cargo, Mode, SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { basePathAir } from '@platform/routes/paths';
import { CargoFilterWidgetList } from '@platform/constants/cargoFilterWidgetList';
import { getCargoFilterDefaults } from '@utils/common/filters';
import { AirCargoListColumnKey, getColumns } from '@platform/components/AirCargoList/AirCargoList.definition';
import { useCustomerIdNameMap } from '@platform/hooks/useCustomerIdNameMap';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { isArray } from 'lodash';
import { setQueryParam } from '@utils/location';
import { getTableURLParams } from '@utils/table';
import CargoComplexSearch from '@platform/components/CargoComplexSearch';

const AirCargoList = () => {
  const { isOpen: isFiltersOpen, toggleOpen, addFilter, isFilterSelected } = useContext(CargoSearchContext);
  const history = useHistory();
  const location = useLocation();
  const { sort, criteria = [], size, page } = parseCargoURLParamsFromSearch(location.search);

  const {
    data,
    isLoading: isLoadingCargos,
    isError,
    error,
    refetch: refetchSearchedCargos,
  } = useSearchCargoForAllCustomers({
    page,
    size,
    sort: sort || [],
    mode: Mode.Air,
    criteria: [
      {
        field: 'mode',
        condition: SearchConditionType.Equals,
        values: [Mode.Air],
      },
      ...criteria,
    ],
  });

  const customerIds = data?.cargos ? data?.cargos.map((x) => x?.customerId || '') : [];
  const { customerMap, isLoading: isCustomersLoading } = useCustomerIdNameMap(customerIds);

  const filterIconClickHandler = (columnKey: AirCargoListColumnKey) => {
    if (!isFiltersOpen) {
      const filterWidget = CargoFilterWidgetList.find((widget) => widget.key === AirCargoListColumnKey[columnKey]);
      toggleOpen();

      if (filterWidget) {
        addFilter({ ...filterWidget, currentValue: getCargoFilterDefaults(filterWidget) });
      }
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Cargo> | SorterResult<Cargo>[],
  ) => {
    if (!isArray(sorter)) {
      setQueryParam(
        history,
        getTableURLParams<Cargo>(pagination, sorter) as Record<string, string | undefined | number>,
      );
    }
  };

  let tableColumns = getColumns({ customers: customerMap, filterIconClickHandler, isFilterSelected });
  // if (columnControlValues) {
  //   tableColumns = assignControlledValues(tableColumns, columnControlValues);
  // }

  return (
    <>
      <TableHeader
        title="Air Cargo"
        renderRightSide={() => (
          <>
            <Button onClick={() => refetchSearchedCargos()} style={{ marginRight: '16px' }}>
              <ReloadOutlined /> Refresh
            </Button>
            <SearchButton type="primary" ghost onClick={toggleOpen}>
              <SearchOutlined />
              Search
            </SearchButton>
          </>
        )}
      />
      {isError && (
        <Alert
          message="Error fetching containers"
          showIcon
          description={`Error: ${error?.message} - ${error?.response?.data}`}
          type="error"
          action={
            <Button size="small" danger onClick={() => history.push(basePathAir)}>
              Reset
            </Button>
          }
        />
      )}

      <div>
        <Table
          pagination={{
            current: page,
            pageSize: size,
            total: data?.totalRecords || 1,
            position: ['bottomCenter'],
          }}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          dataSource={data ? (data.cargos as Cargo[]) : []}
          rowKey="id"
          bordered={false}
          locale={{
            emptyText: <Empty />,
          }}
          columns={tableColumns}
          loading={isLoadingCargos || isCustomersLoading}
        />
      </div>

      <CargoComplexSearch />
    </>
  );
};

export default AirCargoList;
