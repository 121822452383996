import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import { SCVContainerFormValues, SearchField } from '@platform/types';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';
import { Select } from 'antd';
import { Box } from '@beacon-devops/components';
import useDebounce from '@utils/hooks/useDebouncedValue';
import { FormLabel } from '@platform/pages/OceanContainer/styles';
import { getAll } from '@beacon-devops/location-provider';
import { getPortLocations, LocationType } from '@platform/utils';

interface ContainerFacilitySearchProps {
  label: string;
  disabled?: boolean;
  name: UseControllerProps<SCVContainerFormValues>['name'];
  type: LocationType;
}

const PortSearch = ({ label, disabled = false, name, type }: ContainerFacilitySearchProps): JSX.Element => {
  const { control } = useFormContext<SCVContainerFormValues>();
  const [searchValue, setSearchValue] = useState('');
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const debouncedSearchValue = useDebounce(searchValue, 250);

  const portsList = useMemo(() => {
    return getPortLocations(getAll(), type);
  }, [type]);

  const filteredPorts = useMemo(
    () =>
      portsList.reduce<
        Array<{
          id: string;
          name: string;
          zone?: string;
        }>
      >((acc, location) => {
        const iataCode = location.facility?.iataCode || '';
        const trimmedSearchValue = debouncedSearchValue.trim();
        const includesCode = location.unLocation?.code.includes(trimmedSearchValue.toUpperCase());
        const includesIataCode = iataCode.includes(trimmedSearchValue.toUpperCase());
        const includesName = location.name.toLowerCase().includes(trimmedSearchValue.toLowerCase());

        if (includesCode || includesName || includesIataCode) {
          return [
            ...acc,
            {
              id: location.id,
              name:
                type === LocationType.Airport ? `${location.name} ${iataCode ? `(${iataCode})` : ''}` : location.name,
              zone: location.zone,
            },
          ];
        }
        return acc;
      }, []),
    [portsList, debouncedSearchValue],
  );

  const typedValue = value as SearchField;

  return (
    <Box width="100%">
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        id={name}
        style={{ width: '100%' }}
        loading={false}
        onSearch={setSearchValue}
        filterOption={false}
        showSearch
        placeholder={type === LocationType.Airport ? 'Please select a airport' : 'Please select a seaport'}
        onSelect={(value) => {
          const selectedValue = filteredPorts.find((facility) => facility.id === value);
          if (selectedValue) {
            onChange(selectedValue);
          }
        }}
        value={get(typedValue, 'name', '')}
        disabled={disabled}
      >
        {filteredPorts?.map((x) => (
          <Select.Option value={x.id} key={x.id} title={x.name}>
            {x.name}
          </Select.Option>
        ))}
      </Select>
    </Box>
  );
};

export default PortSearch;
