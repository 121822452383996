import React, { ReactElement } from 'react';
import CargoSearchProvider from '@platform/components/CargoSearchProvider';
import AirCargoList from '@platform/components/AirCargoList';
import PageLayout from '@components/PageLayout';

const AirListingPage = (): ReactElement => {
  return (
    <PageLayout>
      <CargoSearchProvider>
        <AirCargoList />
      </CargoSearchProvider>
    </PageLayout>
  );
};

export default AirListingPage;
