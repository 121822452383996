import { Configuration, TrackingInfoApi } from '@beacon-devops/tracking-info-client-ts';
import { getAccessToken } from '@services/Auth';
import { GATEWAY_SERVICE } from '../constants';

const base = `${GATEWAY_SERVICE}/trackinginfo`;

const accessToken = () => getAccessToken();

const api = new TrackingInfoApi(new Configuration({ basePath: base, accessToken }));

const isTrackingActive = async (containerId: string) => {
  const response = await api.isTrackingActive(containerId);
  return response.data;
};

const setTrackingActive = async (containerId: string, active: boolean) => {
  const response = await api.setContainerTrackingActive(containerId, active);
  return response.data;
};

const TrackingInfoService = {
  isTrackingActive,
  setTrackingActive,
};

export default TrackingInfoService;
