import React, { useMemo, useState } from 'react';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';
import { Select } from 'antd';
import { Box } from '@beacon-devops/components';
import { SCVContainerFormValues } from '@platform/types';
import useDebounce from '@utils/hooks/useDebouncedValue';
import { FormLabel } from '@platform/pages/OceanContainer/styles';
import { useVesselList } from '@queries/containers';

interface ComponentProps {
  label: string;
  disabled?: boolean;
  setImoNumber: (a: string) => void;
}

type VesselSearchProps = UseControllerProps<SCVContainerFormValues> & ComponentProps;

const ScvVesselSearch = ({ label, disabled = false, setImoNumber, name }: VesselSearchProps): JSX.Element => {
  const { control } = useFormContext<SCVContainerFormValues>();
  const [searchValue, setSearchValue] = useState('');
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const debouncedSearchValue = useDebounce(searchValue, 250);

  const { data: vesselList = [], isLoading: isLoadingVesselList } = useVesselList();

  const filteredVessels = useMemo(
    () =>
      vesselList.filter((vessel) =>
        vessel.name ? vessel.name.includes(debouncedSearchValue.trim().toUpperCase()) : false,
      ),
    [vesselList, debouncedSearchValue],
  );

  const onVesselSelect = (value: string) => {
    const splitValue = value.split('-');
    const vesselName = splitValue[0];
    const vesselImoNumber = splitValue[1];

    onChange(vesselName);
    setImoNumber(vesselImoNumber);
  };

  return (
    <Box width="200px">
      <FormLabel>{label}</FormLabel>
      <Select
        style={{ width: '100%' }}
        loading={isLoadingVesselList}
        onSearch={setSearchValue}
        filterOption={false}
        showSearch
        placeholder="Please select a vessel"
        onSelect={onVesselSelect}
        value={value as string}
        disabled={disabled}
      >
        {filteredVessels?.map((vessel) => (
          <Select.Option
            value={`${vessel.name}-${vessel.imo}`}
            key={`${vessel.name}-${vessel.imo}`}
            title={vessel.name}
          >
            {vessel.name}
          </Select.Option>
        ))}
      </Select>
    </Box>
  );
};

export default ScvVesselSearch;
