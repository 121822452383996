import React, { ReactElement } from 'react';
import { Tooltip, Row, Tag } from 'antd';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { EmailVerifiedStatus, Role } from '@beacon-devops/customer-service-client';
import { User } from '@services/constants';
import { USER_DETAIL_ROUTE, USER_UPDATE_ROUTE } from '@routes/constants';

export const UserActionTemplate = (record: User): ReactElement => (
  <Row justify="space-between">
    <Tooltip placement="bottom" title="Edit User">
      <Link
        to={USER_UPDATE_ROUTE.replace(':customerId', record.companyId).replace(':userId', record.id)}
        className="ant-btn ant-btn-text"
      >
        <FormOutlined />
      </Link>
    </Tooltip>
    <Tooltip placement="bottom" title="View User">
      <Link
        to={USER_DETAIL_ROUTE.replace(':customerId', record.companyId).replace(':userId', record.id)}
        className="ant-btn ant-btn-text"
      >
        <EyeOutlined />
      </Link>
    </Tooltip>
  </Row>
);

export const StringTemplate = (fieldText: string): ReactElement => <span>{fieldText || '-'}</span>;

export const RoleTagTemplate = (record: User): ReactElement => {
  const role = record.userGroup;

  if (!role) {
    return <></>;
  }

  return <>{role}</>;
};

export const RoleListTemplate = ({ roles }: { roles: Role[] }): ReactElement => {
  return (
    <>
      {roles.map((role) => (
        <Tag key={role.id} style={{ margin: 0, marginLeft: '10px' }}>
          {role.name}
        </Tag>
      ))}
    </>
  );
};

export const ActiveTagTemplate = (record: User): ReactElement => (
  <Tag color={record.isActive ? 'green' : 'red'} style={{ margin: 0 }}>
    {record.isActive ? 'Active' : 'Deactive'}
  </Tag>
);

export const StatusTagTemplate = ({ user }: { user: User }): ReactElement => (
  <Tag color={user.emailVerified === EmailVerifiedStatus.Verified ? 'green' : 'red'} style={{ margin: 0 }}>
    {user.emailVerified}
  </Tag>
);
