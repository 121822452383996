import {
  Configuration,
  CreateCustomerData,
  Customer,
  CustomerApi,
  CustomerCapabilitiesApi,
  CustomerCapability,
  CustomerCapabilityDefinition,
  CustomerCapabilityDefinitionsApi,
} from '@beacon-devops/customer-service-client';
import { getAccessToken } from '@services/Auth';
import { AddCredits } from '@beacon-types/customer';
import { getConfig } from '@utils/httpRequests';
import { GATEWAY_SERVICE } from '../constants';

const base = `${GATEWAY_SERVICE}/customers`;
const LOGIN_ERROR_MESSAGE = 'You must be logged in to use customer service';

const accessToken = () => getAccessToken();
const customerServiceApi = new CustomerApi(new Configuration({ basePath: base, accessToken }));
const customerCapabilitiesApi = new CustomerCapabilitiesApi(new Configuration({ basePath: base, accessToken }));
const customerCapabilitiesDefinitionsApi = new CustomerCapabilityDefinitionsApi(
  new Configuration({
    basePath: base,
    accessToken,
  }),
);

const getAll = async (isTest?: boolean, isFreeTracking?: boolean): Promise<Customer[]> => {
  const token = await getAccessToken();
  if (token) {
    return customerServiceApi.getAllCustomers(isTest, isFreeTracking).then((response) => response.data);
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const getCustomer = async (customerId: string): Promise<Customer> => {
  const token = await getAccessToken();
  if (token) {
    return customerServiceApi.getCustomerById(customerId).then((response) => response.data);
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const updateCustomer = async (
  customerId: string,
  version: string,
  customerData: CreateCustomerData,
): Promise<Customer> => {
  const token = await getAccessToken();
  if (token) {
    return customerServiceApi.updateCustomer(customerId, version, customerData).then((response) => response.data);
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const createCustomer = async (customerData: CreateCustomerData): Promise<Customer> => {
  const token = await getAccessToken();
  if (token) {
    return customerServiceApi.createCustomer(customerData).then((response) => response.data);
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const upsertCustomerCapabilities = async (
  customerId: string,
  customerCapabilities: CustomerCapability[],
): Promise<Customer> => {
  const token = await getAccessToken();
  if (token) {
    return customerCapabilitiesApi
      .createCustomerCapabilities(customerId, customerCapabilities)
      .then((response) => response.data);
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const getCustomerCapabilityDefinitions = (): Promise<CustomerCapabilityDefinition[]> => {
  return customerCapabilitiesDefinitionsApi.getAllCustomerCapabilityDefinitions().then((response) => response.data);
};

const addCreditsToCustomer = async (customerId: string, addCredits: AddCredits): Promise<boolean> => {
  const token = await getAccessToken();
  if (token) {
    const config = await getConfig();

    return fetch(`${GATEWAY_SERVICE}/subscription/v1/tracking-credits/customer/${customerId}/mode/${addCredits.mode}`, {
      headers: config.headers,
      method: 'post',
      body: JSON.stringify({
        quantity: addCredits.quantity,
      }),
    })
      .then((response) => response.ok)
      .catch(() => false);
  }

  return Promise.resolve(false);
};

export default {
  getCustomer,
  getAll,
  updateCustomer,
  createCustomer,
  upsertCustomerCapabilities,
  getCustomerCapabilityDefinitions,
  addCreditsToCustomer,
};
