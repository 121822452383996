import { Modal, Typography } from 'antd';
import React from 'react';
import { MessageInstance } from 'antd/lib/message';
import { useDeleteQuarantinedReportData } from '@queries/flaggedShipments';

interface Props {
  reportId: string;
  onClose: () => void;
  messageApi: MessageInstance;
}

export const DeleteConfirmDialog = ({ messageApi, reportId, onClose }: Props) => {
  const { mutate: deleteReportData, isLoading } = useDeleteQuarantinedReportData({
    onSuccess: () => {
      messageApi.success('Report deleted succesfully');
    },
    onError: (error, reportId) => {
      messageApi.error(`Failed to delete reportId: ${reportId ?? ''}, reason: ${error}`);
    },
  });

  return (
    <>
      <Modal
        title="Confirm Report Deletion"
        visible={!!reportId}
        onOk={() => {
          deleteReportData(reportId);
          onClose();
        }}
        onCancel={onClose}
        confirmLoading={isLoading}
        okButtonProps={{
          danger: true,
        }}
        okText="Delete"
      >
        <div>
          Are you sure you want to delete this report with the below ID?
          <br />
        </div>
        <div
          style={{
            marginTop: '16px',
          }}
        >
          <Typography.Text code>{reportId}</Typography.Text>
        </div>
      </Modal>
    </>
  );
};
