import qs from 'query-string';
import { SearchCriteria, SearchSortCriteria } from '@beacon-devops/graphql-typescript-client';
import { isNonEmptyString } from '@utils/index';
import { generateSearchCriteria, generateSearchSort } from './generateGqlSearch';

export interface CargoURLParams {
  size: number;
  page: number;
  sort: SearchSortCriteria[];
  criteria: SearchCriteria[];
}

export const parseCargoURLParamsFromSearch = (search: string): CargoURLParams => {
  const params = qs.parse(search);
  const ret: CargoURLParams = {
    size: 20,
    page: 1,
    sort: [],
    criteria: [],
  };

  if ('sorter' in params && isNonEmptyString(params.sorter)) {
    try {
      ret.sort = generateSearchSort(params.sorter);
    } catch (e) {
      console.error('Failed to parse cargo listing sorter query param');
    }
  }

  if ('filters' in params && isNonEmptyString(params.filters)) {
    try {
      ret.criteria = generateSearchCriteria(params.filters);
    } catch (e) {
      console.error('Failed to parse cargo filters query params');
    }
  }

  if ('pageSize' in params && isNonEmptyString(params.pageSize)) {
    ret.size = parseInt(params.pageSize, 10);
  }

  if ('current' in params && isNonEmptyString(params.current)) {
    ret.page = parseInt(params.current, 10);
  }

  return ret;
};
