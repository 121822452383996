import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme as beaconComponentsTheme } from '@beacon-devops/components';
import Routes from '@routes/index';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { getConfig } from '@utils/httpRequests';
import { GATEWAY_SERVICE } from '@constants/index';
import './App.less';
import FeatureFlagProvider from '@components/common/FeatureFlagProvider';
import { SharedServiceContext, sharedServices } from '@beacon-devops/services';
import { IServiceConfig } from '@beacon-devops/services/dist/services';
import FullPageSpinner from '@components/FullPageSpinner';

const SHOW_QUERY_CLIENT = ENVIRONMENT === 'dev' || ENVIRONMENT === 'local';

const queryClient = new QueryClient();

export const App = (): ReactElement => {
  const [serviceConfig, setServiceConfig] = useState<IServiceConfig>();

  useEffect(() => {
    getConfig().then((config) => {
      setServiceConfig({ ...config, baseURL: GATEWAY_SERVICE });
    });
  }, []);

  const contextValue = useMemo(() => ({ services: serviceConfig ? sharedServices(serviceConfig) : undefined }), [
    sharedServices,
    serviceConfig,
  ]);

  return (
    <div className="App">
      <FeatureFlagProvider>
        <ThemeProvider theme={beaconComponentsTheme}>
          {!serviceConfig ? (
            <FullPageSpinner />
          ) : (
            <SharedServiceContext.Provider value={contextValue}>
              <QueryClientProvider client={queryClient}>
                {SHOW_QUERY_CLIENT && <ReactQueryDevtools position="bottom-right" />}
                <Routes />
              </QueryClientProvider>
            </SharedServiceContext.Provider>
          )}
        </ThemeProvider>
      </FeatureFlagProvider>
    </div>
  );
};
