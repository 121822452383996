import { combineReducers, Reducer } from 'redux';
import { userReducer } from './user';
import { usersReducer } from './users';
import { customerReducer } from './customer';
import { Action, AppState } from './reduxTypes';

export const appReducer: Reducer<AppState | undefined, Action> = (state: AppState | undefined, action: Action) => {
  const finalState = combineReducers({
    user: userReducer,
    allUsers: usersReducer,
    customerState: customerReducer,
  })(state, action);

  return finalState;
};
