import React, { ReactElement } from 'react';
import PageLayout from '@components/PageLayout';
import { FlaggedShipmentList } from '@platform/components/FlaggedShipmentList';

export const FlaggedShipmentsPage = (): ReactElement => {
  return (
    <PageLayout>
      <FlaggedShipmentList />
    </PageLayout>
  );
};
