import React from 'react';
import { ContainerFromToWrapper, FromToLabel, Port, SubTitle } from './styles';

interface Props {
  from?: string;
  to?: string;
}

export const ContainerFromToDisplay = ({ from, to }: Props) => (
  <ContainerFromToWrapper>
    <FromToLabel>
      <SubTitle>PoL:</SubTitle>
      {from && <Port>{from}</Port>}
    </FromToLabel>
    <FromToLabel>
      <SubTitle>PoD:</SubTitle>
      {to && <Port>{to}</Port>}
    </FromToLabel>
  </ContainerFromToWrapper>
);
