import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { appReducer } from './index';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const Store = () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(appReducer, composeEnhancers(applyMiddleware(thunk)));
};

export { Store };
