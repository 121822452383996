import React, { FC, KeyboardEvent } from 'react';
import moment, { Moment } from 'moment';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { Typography, Col, Select, DatePicker, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import { RangeValue } from 'rc-picker/lib/interface';
import { SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { CargoListFilterWidgetType, FilterWidgetType } from '@beacon-types/cargo/cargoFilters';
import { getCargoSupportedCondition } from '@utils/common/filters';
import { ISO_WITH_TIMEZONE } from '@platform/components/ContainerDate/utils';
import { CustomerLookupWithoutForm } from './CustomerLookupWithoutForm';
import { FilterHeaderWrapper, FilterInputWrapper } from './styles';

const { Text } = Typography;

interface CargoFilterWidgetProps {
  cargoFilter: CargoListFilterWidgetType;
  onClose: (filterIndex: number) => void;
  setCondition: (condition: SearchConditionType, filterIndex: number) => void;
  setValue: (value: string[], filterIndex: number) => void;
  filterIndex: number;
}

export const CargoFilterWidget: FC<CargoFilterWidgetProps> = ({
  cargoFilter,
  onClose,
  setCondition,
  setValue,
  filterIndex,
}) => {
  const inputSubmitHandler = (e: React.FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim();
    setValue([value], filterIndex);
  };

  const dateChangeHandler = (values: RangeValue<Moment>) => {
    if (values && values[0] && values[1]) {
      setValue(
        [
          values[0].utc().startOf('day').format(ISO_WITH_TIMEZONE),
          values[1].utc().endOf('day').format(ISO_WITH_TIMEZONE),
        ],
        filterIndex,
      );
    }
  };

  const filterValueSelectionHandler = (value: string) => {
    const currentConditionObject = getCargoSupportedCondition(cargoFilter, cargoFilter.currentValue?.condition);
    const currentValue: string[] = _get(cargoFilter, ['currentValue', 'value'], []);
    let trimmedValue = value.trim();
    if (
      currentConditionObject?.filterWidgetType === FilterWidgetType.MULTI_SELECT ||
      currentConditionObject?.filterWidgetType === FilterWidgetType.CUSTOMER
    ) {
      if (trimmedValue && !currentValue.includes(trimmedValue)) {
        const targetValue = [...currentValue, trimmedValue];
        setValue(targetValue, filterIndex);
      }
    }

    if (currentConditionObject?.filterWidgetType === FilterWidgetType.SINGLE_SELECT) {
      setValue([trimmedValue], filterIndex);
    }
  };

  /** To enable multi-value paste we must use handler instead of selection/deselection handlers */
  const filterValueChangeHandler = (value: string[]) => {
    const currentConditionObject = getCargoSupportedCondition(cargoFilter, cargoFilter.currentValue?.condition);

    if (currentConditionObject?.filterWidgetType === FilterWidgetType.MULTI_SELECT) {
      setValue(value, filterIndex);
    }
  };

  const filterValueDeselectionHandler = (value: string) => {
    const currentValue: string[] | undefined = _get(cargoFilter, ['currentValue', 'value'], []);
    const targetValue = currentValue ? currentValue.filter((valueItem) => valueItem !== value) : [];
    setValue(targetValue, filterIndex);
  };

  const handleConditionSelect = (value: SelectValue) => {
    setCondition(value as SearchConditionType, filterIndex);
  };

  const currentValue = _get(cargoFilter, ['currentValue', 'value'], []);
  const supportedConditions = _map(cargoFilter.supportedConditions, 'condition');

  // Supported conditions list to render in select box, hence mapped to label and value
  const conditionFilterOptions = supportedConditions.map((conditionKey) => ({
    label: conditionKey.replace(/_/g, ' '),
    value: conditionKey,
  }));

  const currentConditionObject = getCargoSupportedCondition(cargoFilter, cargoFilter.currentValue?.condition);
  const currentDates =
    currentConditionObject?.filterWidgetType === FilterWidgetType.DATE
      ? currentValue.map((value: string) => moment(value))
      : [];

  const tokenSeparators = currentConditionObject?.tokenSeparators;
  return (
    <>
      <FilterHeaderWrapper>
        <Col>
          <Text style={{ fontWeight: 500 }}>{cargoFilter.label} </Text>
        </Col>
        <Col>
          <Select
            dropdownMatchSelectWidth={false}
            options={conditionFilterOptions}
            value={cargoFilter.currentValue?.condition}
            bordered={false}
            onChange={handleConditionSelect}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
          | &nbsp;{' '}
          <CloseOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onClose(filterIndex);
            }}
          />
        </Col>
      </FilterHeaderWrapper>
      {currentConditionObject && (
        <FilterInputWrapper>
          {currentConditionObject.filterWidgetType === FilterWidgetType.TEXT && (
            <Input
              disabled={currentConditionObject.disabled}
              style={{ width: '100%' }}
              defaultValue={currentValue[0]}
              onBlur={inputSubmitHandler}
              onSubmit={inputSubmitHandler}
              onPressEnter={inputSubmitHandler}
            />
          )}
          {currentConditionObject.filterWidgetType === FilterWidgetType.DATE && (
            <DatePicker.RangePicker
              allowClear={false}
              style={{ width: '100%' }}
              ranges={{
                Today: [moment().utc().startOf('day'), moment().utc().endOf('day')],
                'This Month': [moment().utc().startOf('month'), moment().utc().endOf('month')],
              }}
              value={currentDates}
              onChange={dateChangeHandler}
            />
          )}
          {currentConditionObject.filterWidgetType === FilterWidgetType.MULTI_SELECT && (
            <Select
              placeholder="Select"
              notFoundContent={null}
              options={currentConditionObject.filterWidgetOptions}
              mode={currentConditionObject.filterWidgetOptions ? 'multiple' : 'tags'}
              style={{ width: '100%' }}
              value={currentValue}
              onSelect={tokenSeparators ? undefined : filterValueSelectionHandler}
              onDeselect={tokenSeparators ? undefined : filterValueDeselectionHandler}
              onChange={tokenSeparators ? filterValueChangeHandler : undefined}
              tokenSeparators={tokenSeparators}
            />
          )}
          {currentConditionObject.filterWidgetType === FilterWidgetType.CUSTOMER && (
            <CustomerLookupWithoutForm
              value={currentValue}
              onSelect={filterValueSelectionHandler}
              onDeselect={filterValueDeselectionHandler}
            />
          )}
          {currentConditionObject.filterWidgetType === FilterWidgetType.SINGLE_SELECT && (
            <Select
              placeholder="Select"
              options={currentConditionObject.filterWidgetOptions}
              style={{ width: '100%' }}
              value={currentValue}
              onSelect={filterValueSelectionHandler}
            />
          )}
        </FilterInputWrapper>
      )}
    </>
  );
};
