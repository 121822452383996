import React, { FC, useMemo, useState } from 'react';
import { SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { getCargoDefaultFilterCondition, getCargoFilterDefaults } from '@utils/common/filters';
import { CargoListFilterWidgetType, CargoSortColumns } from '@beacon-types/cargo/cargoFilters';
import { CargoSearchContext } from './context';

const CargoSearchProvider: FC = ({ children }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<CargoListFilterWidgetType[]>([]);

  const addFilter = (widget: CargoListFilterWidgetType) => {
    const doesFilterWidgetExist = selectedFilters.find((filterWidget) => filterWidget.key === widget.key);

    if (!doesFilterWidgetExist) {
      setSelectedFilters([...selectedFilters, widget]);
    }
  };

  const isFilterSelected = (key: CargoSortColumns) =>
    !!selectedFilters.find((filterWidget) => filterWidget.key === key);

  const removeFilter = (filterIndex: number) => {
    const filters = [...selectedFilters];
    filters.splice(filterIndex, 1);
    setSelectedFilters(filters);
  };

  const setFieldValue = (value: string[], filterIndex: number) => {
    const filtersCopy = [...selectedFilters];
    const impactedFilter = filtersCopy[filterIndex];
    filtersCopy[filterIndex] = {
      ...impactedFilter,
      currentValue: {
        value,
        condition: impactedFilter.currentValue
          ? impactedFilter.currentValue.condition
          : getCargoDefaultFilterCondition(impactedFilter),
      },
    };
    setSelectedFilters(filtersCopy);
  };

  const setCondition = (condition: SearchConditionType, filterIndex: number) => {
    const filtersCopy = [...selectedFilters];
    const selectedFilter = filtersCopy[filterIndex];
    filtersCopy[filterIndex] = {
      ...selectedFilter,
      currentValue: getCargoFilterDefaults(selectedFilter, condition),
    };
    setSelectedFilters(filtersCopy);
  };

  const cargoSearchProviderValue = useMemo(
    () => ({
      isOpen: isSearchOpen,
      selectedFilters,
      toggleOpen: () => setIsSearchOpen(!isSearchOpen),
      isFilterSelected,
      setFieldValue,
      addFilter,
      removeFilter,
      setCondition,
      setSelectedFilters,
    }),
    [isSearchOpen, selectedFilters],
  );

  return <CargoSearchContext.Provider value={cargoSearchProviderValue}>{children}</CargoSearchContext.Provider>;
};

export default CargoSearchProvider;
