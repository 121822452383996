import React, { ReactElement, ReactNode, ReactText } from 'react';
import { Empty, Input, Spin, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import { ColumnControlledValues } from '@beacon-types/Table';
import { assignControlledValues } from '@utils/table';

enum ListColumnKey {
  Active = 'active',
  BeaconReferenceId = 'beacon_reference_id',
  Mode = 'mode',
  Status = 'status',
  Customer = 'customer',
  WaybillNumbers = 'waybill_numbers',
  Units = 'units',
  VehicleNames = 'vehicle_names',
  PoNumbers = 'po_numbers',
  BookingName = 'booking_name',
  PortOfArrival = 'port_of_arrival',
  NextEventDate = 'next_event_date',
  CollectionDeparture = 'collection_departure',
  Origin = 'origin',
  DeliveryArrivals = 'delivery_arrivals',
  Destinations = 'destinations',
  Actions = 'actions',
}

type Statuses = {
  text: string;
  value: string;
};
export interface TableViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  rowKey?: string;
  locale?: {
    emptyText?: string | ReactNode;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns: any;
  pagination?: TablePaginationConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandable?: any;
  isLoading?: boolean;

  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: SorterResult<any> | SorterResult<any>[],
  ) => void;
  statusFilters?: Statuses[];
  Extras?: ReactElement;
  columnControlValues?: Record<string, ColumnControlledValues>;
  onFilterIconClick?: (columnKey: ListColumnKey) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowSelection?: TableRowSelection<any>;
}

interface TableViewState {
  searchText: ReactText;
  searchedColumn: string;
  fixed?: boolean;
}

export const commonPagination: TablePaginationConfig = {
  position: ['bottomRight'],
  defaultPageSize: 20,
  hideOnSinglePage: true,
};

export default class TableView extends React.Component<TableViewProps, TableViewState> {
  searchInput: Input | null = null;

  constructor(props: TableViewProps) {
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: '',
      fixed: undefined,
    };
  }

  componentDidMount(): void {
    this.getColumnPosition();
    window.addEventListener('resize', this.getColumnPosition);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.getColumnPosition);
  }

  getColumnPosition = (): void => {
    const smallScreen = window.matchMedia('(max-width: 1400px');
    let fixed;
    if (smallScreen.matches) {
      fixed = false;
    } else {
      fixed = true;
    }
    this.setState({ fixed });
  };

  handleSearch = (selectedKeys: ReactText[], confirm: () => void, dataIndex: string): void => {
    if (confirm) {
      confirm();
    }
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleDateRangeSearch = (confirm: () => void, dataIndex: string): void => {
    if (confirm) {
      confirm();
    }
    this.setState({
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters?: () => void): void => {
    if (clearFilters) {
      clearFilters();
    }
    this.setState({ searchText: '' });
  };

  render(): ReactElement {
    const {
      data,
      isLoading,
      getColumns,
      pagination = commonPagination,
      expandable,
      statusFilters,
      Extras,
      onChange,
      columnControlValues,
      onFilterIconClick,
    } = this.props;

    const { searchedColumn, searchText, fixed } = this.state;

    let tableColumns = getColumns({
      forwardedRef: this.searchInput,
      onSearch: this.handleSearch,
      onFilterByDate: this.handleDateRangeSearch,
      onReset: this.handleReset,
      searchedColumn,
      searchText,
      fixed,
      statusFilters,
      columnControlValues,
      onFilterIconClick,
    });

    if (columnControlValues) {
      tableColumns = assignControlledValues(tableColumns, columnControlValues);
    }

    return (
      <div className="bo__booking-list">
        {Extras}
        <Table
          rowSelection={this.props.rowSelection}
          expandable={expandable}
          pagination={pagination}
          columns={tableColumns}
          scroll={{ x: 'max-content' }}
          dataSource={data}
          rowKey={this.props.rowKey}
          bordered={false}
          loading={{
            spinning: !!isLoading,
            indicator: (
              <div data-testid={isLoading ? 'table-loading-spinner' : undefined}>
                <Spin />
              </div>
            ),
          }}
          onChange={onChange}
          locale={{
            emptyText: <Empty />,
          }}
          sticky={{
            offsetHeader: -32,
          }}
        />
      </div>
    );
  }
}
