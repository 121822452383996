/* eslint-disable react/prop-types */
import { Form, Typography, Alert, Button, Space, Row, Col } from 'antd';
import _unique from 'lodash/uniqueId';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import { PlusCircleFilled } from '@ant-design/icons';
import React, { ReactElement, useEffect, useState } from 'react';
import { CreateOrUpdateCustomerProps, CustomerFormProps, UserWithRoles } from '@beacon-types/customer';
import UsersRolesTable from './UsersRolesTable';

const { Title, Text } = Typography;

const AddUsersForm = ({
  users = [],
  removeUser,
  setUserPermissionDetails,
  setUserEmail,
  addUser,
  isValid,
  rolesList = [],
  fullCompanyName,
  isTest,
  editMode,
  customerId,
}: CustomerFormProps & CreateOrUpdateCustomerProps): ReactElement => {
  const [additionalUsers, setAdditionalUsers] = useState<UserWithRoles[]>([{ id: _unique() }] as UserWithRoles[]);

  const onAddUser = () => {
    const templateUser = { id: _unique() } as UserWithRoles;
    setAdditionalUsers([...additionalUsers, templateUser]);
    addUser(templateUser);
  };
  const onRemoveUser = (id: string) => {
    const userIndex = _findIndex(additionalUsers, { id });
    const clonedUsers = _remove(additionalUsers, (_, index) => index !== userIndex);
    setAdditionalUsers(clonedUsers);
    removeUser(id);
  };

  const tableProps = {
    users: additionalUsers,
    setUserPermissionDetails,
    setUserEmail,
    removeUser: onRemoveUser,
    rolesList,
    permissionDataIndex: 'permissions',
    showUserEmail: true,
    totalItems: additionalUsers.length,
    editMode: !!editMode,
    customerId,
  };

  useEffect(() => {
    // Update parent with draft user when component loads
    addUser(additionalUsers[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form className="create-customer-form__invite_user" wrapperCol={{ span: 18 }} layout="horizontal">
      <Row>
        <Col span={20}>
          <Space direction="vertical" size={16}>
            <Title level={5}>User &amp; Permissions</Title>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Space direction="vertical" size={33}>
            <Text className="create-customer-form__description">
              Enter multiple email addresses to add people to your company. Choose roles to apply to all users.
              <br /> New users will receive an email invitation.
            </Text>
            {users && !isValid({ users, fullCompanyName, isTest }) && (
              <Alert
                message=""
                description="No permissions selected for one or more users. Please add at least one permission."
                type="error"
              />
            )}
            <div className="user-invite-table">
              <UsersRolesTable {...tableProps} />
            </div>
            <Button className="add-user-button" onClick={onAddUser} type="link">
              <Space size={5}>
                <PlusCircleFilled style={{ fontSize: '16px' }} />
                Add other users
              </Space>
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default AddUsersForm;
