import moment from 'moment-timezone';

export const ISO_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss';
export const ISO_WITH_TIMEZONE = 'YYYY-MM-DDTHH:mm:ssZ';

export const getDefaultTime = (zone: string | undefined, isNow: boolean): moment.Moment => {
  if (isNow) {
    return zone ? moment().tz(zone) : moment();
  }
  return moment('00:00:00', 'HH:mm:ss');
};
