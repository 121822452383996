import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { redirectToLogin } from '@services/Auth';

const Logout: FC = () => {
  return <LoadingOutlined spin />;
};

export default Logout;
