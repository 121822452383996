import React from 'react';
import { Card } from 'antd';
import { Transport } from '@beacon-devops/shipment-tracker-client';
// Local imports
import { TitleBar, TitleContainer, TitleWithoutMargin } from './styles';
import {
  ScvEntityType,
  AddInspectionEntity,
  buildInspectLink,
  renderEntityItem,
  renderEntityItemDateTime,
} from './ScvCommanderEntityUtils';

interface ScvCommanderEntityTransportProps {
  entityId: string;
  entityData: Transport;
  addInspectionEntityFunc: AddInspectionEntity;
  backgroundColor: string;
}

/**
 * This component renders a Transport Call given some data from Shipment Tracker.
 */
export const ScvCommanderEntityTransport = (props: ScvCommanderEntityTransportProps): JSX.Element => {
  const transport: Transport = props.entityData;

  /**
   * Renders the transport call card plus any extra information for this entity
   * @param transportCall TransportCall entity from Shipment Tracker
   */
  function render(transport: Transport): JSX.Element {
    return (
      <>
        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Transport {transport?.id ?? ''}</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Card>
          {renderEntityItemDateTime('Created At', transport.createdAt)}
          {renderEntityItemDateTime('Last Updated At', transport.updatedAt)}
          {renderEntityItem(
            'Cargo ID',
            buildInspectLink(ScvEntityType.cargo, transport.cargoId, props.addInspectionEntityFunc, true),
          )}
          {renderEntityItem('Sequence No', transport.carrierShipmentSequenceNo)}
          {renderEntityItem(
            'Load Transport Call',
            buildInspectLink(
              ScvEntityType.transportCall,
              transport.loadTransportCallId,
              props.addInspectionEntityFunc,
              true,
            ),
          )}
          {renderEntityItem(
            'Discharge Transport Call',
            buildInspectLink(
              ScvEntityType.transportCall,
              transport.dischargeTransportCallId,
              props.addInspectionEntityFunc,
              true,
            ),
          )}
        </Card>
      </>
    );
  }

  return <div>{transport ? render(transport) : '...loading'}</div>;
};
