import { useReleaseReportData } from '@queries/flaggedShipments';
import { Modal, Typography } from 'antd';
import { MessageInstance } from 'antd/lib/message';
import React from 'react';

interface Props {
  reportId: string;
  onClose: () => void;
  messageApi: MessageInstance;
}

const ReleaseConfirmDialog = ({ reportId, onClose, messageApi }: Props) => {
  const { mutate: releaseReportData, isLoading } = useReleaseReportData({
    onSuccess: () => {
      messageApi.success('Report released succesfully');
    },
    onError: (error, reportId) => {
      messageApi.error(`Failed to release reportId: ${reportId ?? ''}, reason: ${error}`);
    },
  });

  return (
    <>
      <Modal
        title="Confirm Report Release"
        visible={!!reportId}
        onOk={() => {
          releaseReportData(reportId);
          onClose();
        }}
        onCancel={onClose}
        confirmLoading={isLoading}
        okText="Release"
      >
        <div>Are you sure you want to release this report with the below ID?</div>
        <div
          style={{
            marginTop: '16px',
          }}
        >
          <Typography.Text code>{reportId}</Typography.Text>
        </div>
      </Modal>
    </>
  );
};

export default ReleaseConfirmDialog;
