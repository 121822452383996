declare const CUSTOMER_FRONTEND_URL: string;
declare const BACKEND_HOST: string;
declare const GATEWAY_SERVICE: string;
declare const PUBLIC_URL: string;
declare const ZENDESK_SUBDOMAIN: string;
declare const DOMAIN_NAME: string;
declare const ENVIRONMENT: string;
declare const AUTH0_CLIENT_ID: string;
declare const AUTH0_DOMAIN: string;
declare const AUTH0_REDIRECT_URL: string;
declare const GOOGLE_MAPS_API_KEY: string;

const CONSTANT_CUSTOMER_FRONTEND_URL = CUSTOMER_FRONTEND_URL;
const CONSTANT_BACKEND_HOST = BACKEND_HOST;
const CONSTANT_GATEWAY_SERVICE = GATEWAY_SERVICE;
const CONSTANT_PUBLIC_URL = PUBLIC_URL;
const CONSTANT_ZENDESK_SUBDOMAIN = ZENDESK_SUBDOMAIN;
const CONSTANT_DOMAIN_NAME = DOMAIN_NAME;
const CONSTANT_ENVIRONMENT = ENVIRONMENT;
const CONSTANT_AUTH0_CLIENT_ID = AUTH0_CLIENT_ID;
const CONSTANT_AUTH0_DOMAIN = AUTH0_DOMAIN;
const CONSTANT_AUTH0_REDIRECT_URL = AUTH0_REDIRECT_URL;
const CONSTANT_GOOGLE_MAPS_API_KEY = GOOGLE_MAPS_API_KEY;

export { CONSTANT_CUSTOMER_FRONTEND_URL as CUSTOMER_FRONTEND_URL };
export { CONSTANT_BACKEND_HOST as BACKEND_HOST };
export { CONSTANT_GATEWAY_SERVICE as GATEWAY_SERVICE };
export { CONSTANT_ZENDESK_SUBDOMAIN as ZENDESK_SUBDOMAIN };
export { CONSTANT_DOMAIN_NAME as DOMAIN_NAME };
export { CONSTANT_ENVIRONMENT as ENVIRONMENT };
export { CONSTANT_AUTH0_CLIENT_ID as AUTH0_CLIENT_ID };
export { CONSTANT_AUTH0_DOMAIN as AUTH0_DOMAIN };
export { CONSTANT_AUTH0_REDIRECT_URL as AUTH0_REDIRECT_URL };
export { CONSTANT_GOOGLE_MAPS_API_KEY as GOOGLE_MAPS_API_KEY };
export { CONSTANT_PUBLIC_URL as PUBLIC_URL };

export const LOCALE = 'en';
export * from './colors';
export * from './keyboard';
