import { UseMutationOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { ReportDto, ReportsDto } from '@beacon-devops/shipment-data-quality-engine-ts-client';
import { RequestReportDataArgs, ShipmentDataQualityEngineService } from '@services/ShipmentDataQualityEngineService';

const flaggedShipmentListKey = 'flagged-shipments';

export const useSearchFlaggedShipments = ({ page, size }: RequestReportDataArgs) =>
  useQuery<ReportsDto, AxiosError>(
    [flaggedShipmentListKey, page, size],
    async () => ShipmentDataQualityEngineService.requestQuarantinedReportData({ page, size }),
    {
      staleTime: Infinity,
      retry: false,
    },
  );

export const useRequestReportDetails = (reportId: string) =>
  useQuery<ReportDto, AxiosError>(
    ['report-details', reportId],
    async () => ShipmentDataQualityEngineService.requestReportDetails(reportId),
    {
      staleTime: Infinity,
      retry: false,
    },
  );

export const useDeleteQuarantinedReportData = (options?: UseMutationOptions<void, unknown, string, unknown>) => {
  const queryClient = useQueryClient();

  return useMutation((reportId: string) => ShipmentDataQualityEngineService.deleteQuarantinedReportData(reportId), {
    onSettled: () => {
      queryClient.invalidateQueries([flaggedShipmentListKey]);
    },
    ...options,
  });
};

export const useReleaseReportData = (options?: UseMutationOptions<void, unknown, string, unknown>) => {
  const queryClient = useQueryClient();

  return useMutation((reportId: string) => ShipmentDataQualityEngineService.releaseQuarantinedReportData(reportId), {
    onSettled: () => {
      queryClient.invalidateQueries([flaggedShipmentListKey]);
    },
    ...options,
  });
};
