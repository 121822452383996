import React from 'react';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import _get from 'lodash/get';
import { User } from '@services/constants';
import { ColumnFilterProps } from '@beacon-types/Table';
import { StringTemplate, UserActionTemplate, RoleTagTemplate } from './partials';
import { getColumnTextSearchProps } from '../common/TableView/partials';
import { TableDate } from '../common/TableDate';

export const getColumns = ({
  forwardedRef,
  onSearch,
  onReset,
  searchedColumn,
  searchText,
}: ColumnFilterProps): (ColumnType<User> & { export?: (date: number) => string })[] => {
  return [
    {
      title: 'Id',
      className: 'id',
      dataIndex: 'id',
      key: 'id',
      width: 250,
      fixed: 'left',
      ...getColumnTextSearchProps<User>({
        dataIndex: 'id',
        onSearch,
        onReset,
        forwardedRef,
        searchedColumn,
        searchText,
        placeholder: 'Search User ID',
        renderCell: StringTemplate,
      }),
    },
    {
      title: 'Full Name',
      className: 'full_name',
      dataIndex: ['firstName', 'lastName'],
      key: 'full_name',
      width: 250,
      sorter: (a: User, b: User) => `${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`),
      ...getColumnTextSearchProps<User>({
        dataIndex: 'firstName',
        onSearch,
        onReset,
        forwardedRef: null,
        searchedColumn,
        searchText,
        placeholder: 'Search First Name',
        renderCell: (_: string, record: User) => <span>{`${record.firstName || ''} ${record.lastName || ''}`}</span>,
      }),
    },
    {
      title: 'Email',
      className: 'email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: User, b: User) => _get(a, 'email', '').localeCompare(b.email),
      ...getColumnTextSearchProps<User>({
        dataIndex: 'email',
        onSearch,
        onReset,
        forwardedRef,
        searchedColumn,
        searchText,
        placeholder: 'Search Email address',
        renderCell: StringTemplate,
      }),
    },
    {
      title: 'User Group',
      className: 'user_group',
      dataIndex: 'user_group',
      key: 'user_group',
      width: 150,
      sorter: (a: User, b: User) => (a.userGroup || '').localeCompare(b.userGroup || ''),
      render: (_: string, record: User) => RoleTagTemplate(record),
    },
    {
      title: 'Creation Date',
      dataIndex: 'created',
      className: 'created',
      key: 'created',
      width: 180,
      render: TableDate,
      defaultSortOrder: 'descend',
      sorter: (a: User, b: User) => new Date(a.created).getTime() - new Date(b.created).getTime(),
      export: (date: number) => TableDate(date),
    },
    {
      title: '',
      className: 'user_action',
      dataIndex: ['customerId', 'id'],
      key: 'user_action',
      fixed: 'right',
      width: 130,
      render: (_: string, record: User) => UserActionTemplate(record),
    },
  ];
};

export const pagination: TablePaginationConfig = {
  position: ['bottomRight'],
  defaultPageSize: 20,
  hideOnSinglePage: true,
};
