import { PagedSearchResponse } from '@beacon-devops/graphql-typescript-client';
import { GraphQLClient } from 'graphql-request';
import { SearchCargoQueryType } from '@services/graphql/types';

const searchCargoForAllCustomersQuery = `
query getCargoForAllCustomers(
	$criteria: [SearchCriteria!]
	$sort: [SearchSortCriteria!]
	$page: Int! = 0
	$size: Int! = 20
) {
	searchCargoForAllCustomers(
		criteria: $criteria
		sort: $sort
		page: $page
		size: $size
	) {
		page
		pageSize
		totalPages
		totalRecords
		cargos {
        id
        carrierShipmentId
        createdAt
        customerId
        mode
        container {
            containerNumber
            isoCode
            equipmentKind
        }
        status
        weight
        weightUnit
        numberOfPackages
        volume
        volumeUnit
        transportSummary {
          currentJourneyNumber
          currentLeg
          legs
          primaryLoad {
            location {
              id
              name
            }
            milestoneViews {
              full {
                type
                classifier
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                }
              }
            }
          }
          primaryDischarge {
            location {
              id
              name
            }
            milestoneViews {
              full {
                type
                classifier
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                }
              }
            }
          }
          currentVehicle {
              displayName
              identification {
                  imoNumber
              }
          }
        }
        carrier {
            id
            name
            displayName
            nmftaCode
        }
        shipmentReferences {
            type
            value
            name
            purchaseOrderId
            partyId
            partyName
        }
        transports {
            id
            sequenceNo
            carrierShipmentSequenceNo
            vehicle {
              displayName
              mode
              identification {
                imoNumber
              }
            }
            journey {
                id
                references {
                    voyageNumber
                    flightNumber
                }
                status
            }
            loadTransportCall {
                id
                inboundJourneyId
                outboundJourneyId
                location {
                  id
                  name
                }
                transportEvents {
                  id
                  type
                  subType
                  classifier
                  updatedAt
                  createdAt
                  source {
                    confidence
                    sourceType
                  }
                  eventDateTime {
                      date
                      timestamp
                      zone
                  }
                  transportCall {
                      id
                      location {
                        id
                      }
                  }
                }
            }
            dischargeTransportCall {
                id
                inboundJourneyId
                outboundJourneyId
                location {
                  id
                  name
                }
                transportEvents {
                  id
                  type
                  subType
                  classifier
                  updatedAt
                  createdAt
                  source {
                    confidence
                    sourceType
                  }
                  eventDateTime {
                      date
                      timestamp
                      zone
                  }
                  transportCall {
                      id
                      location {
                        id
                      }
                  }
                }
            }
        }
        cargoEvents {
            id
            type
            subType
            classifier
            updatedAt
            createdAt
            source {
              confidence
              sourceType
            }
            eventDateTime {
                date
                timestamp
                zone
            }
            transportCall {
                id
                location {
                  id
                }
            }
        }
    }
	}
}
`;

interface QuerySearchCargoByCustomerIdParams extends SearchCargoQueryType {
  client: GraphQLClient;
}

export const querySearchCargoForAllCustomers = ({
  client,
  page,
  size,
  criteria,
  sort,
}: QuerySearchCargoByCustomerIdParams): Promise<{
  searchCargoForAllCustomers: PagedSearchResponse;
}> => client.request(searchCargoForAllCustomersQuery, { page: page > 0 ? page - 1 : 0, size, sort, criteria });
