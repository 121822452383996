/* eslint-disable no-underscore-dangle */
import {
  UserApi,
  Configuration,
  UserGroup,
  Role,
  User as UserDto,
  CreateUserData,
  UpdateRoles,
  CustomerCapabilitiesApi,
  CustomerCapability,
} from '@beacon-devops/customer-service-client';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { UserWithRoles } from '@beacon-types/customer';
import { GATEWAY_SERVICE } from '../constants';
import { User } from './constants';
import { getAccessToken } from './Auth';

const LOGIN_ERROR_MESSAGE = 'You must be logged in to use customer service';
const accessToken = () => getAccessToken();
const base = `${GATEWAY_SERVICE}/customers`;
const usersServiceApi = new UserApi(new Configuration({ basePath: base, accessToken }));
const customerCapabilitiesApi = new CustomerCapabilitiesApi(new Configuration({ basePath: base, accessToken }));

const convertUserFunc = (user: UserDto): User => {
  return {
    id: user.userId,
    companyId: user.customerId,
    firstName: _get(user, 'contactDetails.firstName'),
    lastName: _get(user, 'contactDetails.lastName'),
    phone: _get(user, 'contactDetails.phoneNumber'),
    email: user.email,
    isTest: user.isTest ? 1 : 0,
    version: user.version,
    isActive: user.isActive,
    created: user.created,
    userGroup: user.userGroup,
    emailVerified: user.emailVerified,
  };
};

const convertUserToCreateUserData = (user: User, roles: string[]): CreateUserData => {
  return {
    customerId: user.companyId,
    contactDetails: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      phoneNumber: user.phone === '' ? undefined : user.phone,
    },
    email: user.email,
    userGroup: user.userGroup,
    roleIds: roles,
  };
};

const getUser = (): Promise<User> => {
  return usersServiceApi.getCurrentUser().then((response) => convertUserFunc(response.data));
};

const getRoles = (userGroup: UserGroup): Promise<Role[]> => {
  return usersServiceApi.getRolesForGroup(userGroup).then((response) => response.data);
};

const getRolesByCap = (customerCapabilities: CustomerCapability[]): Promise<Role[]> => {
  return customerCapabilitiesApi.getRolesByCapabilities(customerCapabilities).then((response) => response.data);
};

const getRolesByCustomer = (customerId: string): Promise<Role[]> => {
  return customerCapabilitiesApi.getRolesByCustomerCapabilities(customerId).then((response) => response.data);
};

const getExternalRolesList = (): Promise<Role[]> => {
  return getRoles(UserGroup.External);
};

const getInternalRolesList = (): Promise<Role[]> => {
  return getRoles(UserGroup.Internal);
};

const getRolesForUser = (customerId: string, userId: string) => usersServiceApi.getRolesForUser(customerId, userId);

const getBulkUsersByCustomerId = async (customerId: string): Promise<UserWithRoles[]> => {
  return usersServiceApi.getAllUsersByCustomerId(customerId).then((response) => {
    const users = response.data;
    const convertedUsers = users.map((user) => ({
      ...convertUserFunc(user),
      permissions: [],
    }));
    return convertedUsers;
  });
};

const getBulkUserRoles = async (customerId: string, data: UserWithRoles[]): Promise<UserWithRoles[]> => {
  const users = await Promise.all(
    data.map((user) =>
      getRolesForUser(customerId, user.id).then(({ data: roles }) => {
        return {
          ...user,
          permissions: roles,
        };
      }),
    ),
  );
  return users;
};

const getAllUsers = (): Promise<User[]> => {
  return usersServiceApi.getAllUsers().then((response) => response.data.map(convertUserFunc));
};

const getUserById = (userId: string): Promise<User> => {
  return usersServiceApi.getUserById(userId).then((response) => convertUserFunc(response.data));
};

const deleteUserById = (userId: string): Promise<User> => {
  return usersServiceApi.deleteUserById(userId).then((response) => convertUserFunc(response.data));
};

const getUserByIdAndCustomerId = (customerId: string, userId: string): Promise<User> => {
  return usersServiceApi
    .getUserByIdAndCustomerId(customerId, userId)
    .then((response) => convertUserFunc(response.data));
};

const updateUserRoles = (customerId: string, userId: string, updateRoles: UpdateRoles): Promise<Role[]> => {
  return usersServiceApi.updateUserRoles(customerId, userId, updateRoles).then((response) => response.data);
};

const getAllUsersByCustomerId = (customerId: string): Promise<User[]> => {
  return usersServiceApi.getAllUsersByCustomerId(customerId).then((response) => {
    return _map(response.data, (user) => convertUserFunc(user));
  });
};

const updateUser = (userId: string, version: string, userData: User, roleIds: string[]): Promise<User> => {
  return usersServiceApi
    .updateUser(userId, version, convertUserToCreateUserData(userData, roleIds))
    .then((response) => convertUserFunc(response.data));
};

const addUser = async (userData: User, roleIds: string[]): Promise<User> => {
  const token = await getAccessToken();
  if (token) {
    return usersServiceApi
      .addUser(convertUserToCreateUserData(userData, roleIds))
      .then((response) => convertUserFunc(response.data));
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const addUserV2 = async (userData: CreateUserData): Promise<User> => {
  const token = await getAccessToken();
  if (token) {
    return usersServiceApi.addUser(userData).then((response) => convertUserFunc(response.data));
  }
  const error = new Error(LOGIN_ERROR_MESSAGE);
  return Promise.reject(error);
};

const sendInviteEmail = (customerId: string, userId: string): Promise<void> => {
  return usersServiceApi.sendInviteEmailToUser(customerId, userId).then();
};

const getUserRoles = (customerId: string, userId: string): Promise<Role[]> => {
  return usersServiceApi.getRolesForUser(customerId, userId).then((response) => response.data);
};

export default {
  getUser,
  getAllUsers,
  getUserById,
  deleteUserById,
  getUserByIdAndCustomerId,
  getAllUsersByCustomerId,
  updateUser,
  addUser,
  addUserV2,
  sendInviteEmail,
  getUserRoles,
  getExternalRolesList,
  getRolesByCustomer,
  getRolesByCap,
  getInternalRolesList,
  updateUserRoles,
  getBulkUserRoles,
  getBulkUsersByCustomerId,
};
