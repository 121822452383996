import { ContainerEventData, SCVContainerEventData, SCVContainerFormValues, TransportDates } from '@platform/types';
import {
  Cargo,
  CargoEvent,
  CargoEventType,
  EventClassifier,
  SourceType,
  TransportCall,
  TransportEvent,
  TransportEventType,
} from '@beacon-devops/graphql-typescript-client';
import { get } from 'lodash';
import { Maybe } from 'yup/es/types';
import { EquipmentKind } from '@beacon-devops/container-tracking-api-model-ts-client';
import { EventBase } from '@beacon-devops/graphql-typescript-client/dist/src/gql/graphql';
import { compareDates } from '@utils/generalUtilities';
import { UseFormSetValue } from 'react-hook-form';

export const patchDepartureZone = (
  zone: string,
  formValues: SCVContainerFormValues,
  valueSetter: UseFormSetValue<SCVContainerFormValues>,
) => {
  if (formValues.gateOutEmpty?.actualDate) {
    formValues.gateOutEmpty.actualDate.zone = zone;
    valueSetter('gateOutEmpty', formValues.gateOutEmpty);
  }
  if (formValues.gateInFull?.actualDate) {
    formValues.gateInFull.actualDate.zone = zone;
    valueSetter('gateInFull', formValues.gateInFull);
  }
  if (formValues.loaded?.actualDate) {
    formValues.loaded.actualDate.zone = zone;
    valueSetter('loaded', formValues.loaded);
  }
};

export const patchArrivalZone = (
  zone: string,
  formValues: SCVContainerFormValues,
  valueSetter: UseFormSetValue<SCVContainerFormValues>,
) => {
  if (formValues.discharged?.actualDate) {
    formValues.discharged.actualDate.zone = zone;
    valueSetter('discharged', formValues.discharged);
  }
  if (formValues.gateOutFull?.actualDate) {
    formValues.gateOutFull.actualDate.zone = zone;
    valueSetter('gateOutFull', formValues.gateOutFull);
  }
  if (formValues.gateInEmpty?.actualDate) {
    formValues.gateInEmpty.actualDate.zone = zone;
    valueSetter('gateInEmpty', formValues.gateInEmpty);
  }
};

const compareToNumbers = (eventBConfidence: number, eventAConfidence: number): number => {
  if (eventBConfidence < eventAConfidence) return -1;
  if (eventBConfidence > eventAConfidence) return 1;
  return 0;
};

/**
 * Returns a comparison result for sorting purposes, when two events are very similar.
 * - First try to find an event with a higher confidence score
 * - If confidence scores are the same, use the most recently created one
 * - If anything goes wrong during the comparisons above, return equals
 */
const compareSimilarEvents = (eventA: EventBase, eventB: EventBase): number => {
  const eventAConfidence: number = eventA?.source?.confidence || 0.0;
  const eventBConfidence: number = eventB?.source?.confidence || 0.0;

  if (eventA.classifier === eventB.classifier && eventAConfidence === eventBConfidence) {
    // Identical as far as we can tell. Pick the most recent
    const eventADate = eventA?.updatedAt || eventA?.createdAt || '';
    const eventBDate = eventB?.updatedAt || eventB?.createdAt || '';
    return compareDates(eventBDate, eventADate);
  }
  return compareToNumbers(eventBConfidence, eventAConfidence);
};

export const findLatestCargoOrTransportEvent = (
  events: CargoEvent[] | TransportEvent[],
  classifier: EventClassifier,
  subType: CargoEventType | TransportEventType,
  transportCallId?: string,
): CargoEvent | TransportEvent | undefined => {
  let latestEvents = events
    // We don't want to include internal service pseudo-events in the list of milestones. Third party and ShayUI updates are fine
    // @ts-expect-error: this is because we are assuming CargoEvent[] | TransportEvent[]
    .filter(
      (event: CargoEvent | TransportEvent) =>
        event.subType === subType &&
        event.classifier === classifier &&
        event?.source?.sourceType !== SourceType.InternalService,
    )
    .sort(compareSimilarEvents);

  if (transportCallId) {
    latestEvents = latestEvents.filter((event: CargoEvent | TransportEvent) =>
      event?.transportCall ? event?.transportCall?.id === transportCallId : true,
    );
  }

  return latestEvents[0];
};

export const mapCargoEventData = (
  transportCall: Maybe<TransportCall>,
  events: CargoEvent[],
  classifier: EventClassifier,
  subType: CargoEventType,
): ContainerEventData => {
  let predicate;

  // For these cargo events we don't want to filter with transport call
  if (
    subType === CargoEventType.GateOutEmpty ||
    subType === CargoEventType.GateInFull ||
    subType === CargoEventType.GateOutFull ||
    subType === CargoEventType.GateInEmpty ||
    subType === CargoEventType.BookingConfirmed ||
    subType === CargoEventType.Receive ||
    subType === CargoEventType.Collect
  ) {
    predicate = findLatestCargoOrTransportEvent(events, classifier, subType);
  } else {
    predicate = findLatestCargoOrTransportEvent(events, classifier, subType, transportCall?.id || '');
  }
  if (predicate) {
    return {
      eventID: get(predicate, `id`, ''),
      transportCallID: get(predicate, `transportCall.id`, ''),
      locationID: get(predicate, `transportCall.location.id`, ''),
    };
  }
  return {
    eventID: get(predicate, `id`, ''),
    transportCallID: transportCall?.id || '',
    locationID: transportCall?.location?.id || '',
  };
};

export const mapTransportEventData = (
  transportCall: Maybe<TransportCall>,
  classifier: EventClassifier,
  subType: TransportEventType,
): ContainerEventData => {
  const transportEvents = get(transportCall, `transportEvents`, []);
  const predicate = findLatestCargoOrTransportEvent(transportEvents as TransportEvent[], classifier, subType);
  return {
    eventID: get(predicate, `id`, ''),
    transportCallID: transportCall?.id || '',
    locationID: transportCall?.location?.id || '',
  };
};

export const mapSCVCargoEventData = (cargo: Cargo): SCVContainerEventData => {
  const cargoEvents = get(cargo, `cargoEvents`, []);

  const legs = cargo?.transportSummary?.legs || 1;
  const hasTranshipments = legs > 1;

  let eventData: SCVContainerEventData = { transhipments: [] as TransportDates[] } as SCVContainerEventData;

  if (!hasTranshipments) {
    const transport = cargo?.transports.find(
      (t) => t.carrierShipmentSequenceNo === cargo?.transportSummary?.currentLeg,
    );
    const loadTransportCall = transport?.loadTransportCall;
    const dischargeTransportCall = transport?.dischargeTransportCall;

    eventData = {
      arrival: {
        actual: mapTransportEventData(dischargeTransportCall, EventClassifier.Actual, TransportEventType.Arrive),
        expected: mapTransportEventData(dischargeTransportCall, EventClassifier.Estimated, TransportEventType.Arrive),
      },
      berth: {
        actual: mapTransportEventData(dischargeTransportCall, EventClassifier.Actual, TransportEventType.Berth),
      },
      departure: {
        actual: mapTransportEventData(loadTransportCall, EventClassifier.Actual, TransportEventType.Depart),
        expected: mapTransportEventData(loadTransportCall, EventClassifier.Estimated, TransportEventType.Depart),
      },
      discharged: {
        actual: mapCargoEventData(
          dischargeTransportCall,
          cargoEvents,
          EventClassifier.Actual,
          CargoEventType.Discharge,
        ),
      },
      gateInEmpty: {
        actual: mapCargoEventData(
          dischargeTransportCall,
          cargoEvents,
          EventClassifier.Actual,
          CargoEventType.GateInEmpty,
        ),
      },
      gateInFull: {
        actual: mapCargoEventData(loadTransportCall, cargoEvents, EventClassifier.Actual, CargoEventType.GateInFull),
      },
      gateOutEmpty: {
        actual: mapCargoEventData(loadTransportCall, cargoEvents, EventClassifier.Actual, CargoEventType.GateOutEmpty),
      },
      bookingConfirmed: {
        actual: mapCargoEventData(
          loadTransportCall,
          cargoEvents,
          EventClassifier.Actual,
          CargoEventType.BookingConfirmed,
        ),
      },
      receive: {
        actual: mapCargoEventData(loadTransportCall, cargoEvents, EventClassifier.Actual, CargoEventType.Receive),
      },
      gateOutFull: {
        actual: mapCargoEventData(
          dischargeTransportCall,
          cargoEvents,
          EventClassifier.Actual,
          CargoEventType.GateOutFull,
        ),
      },
      collect: {
        actual: mapCargoEventData(dischargeTransportCall, cargoEvents, EventClassifier.Actual, CargoEventType.Collect),
      },
      loaded: {
        actual: mapCargoEventData(loadTransportCall, cargoEvents, EventClassifier.Actual, CargoEventType.Load),
      },
      transhipments: [],
    };
  } else {
    cargo?.transports.forEach((transport, index) => {
      const loadTransportCall = transport?.loadTransportCall;
      const previousDischargeTransportCall = cargo.transports[index - 1]?.dischargeTransportCall;
      const dischargeTransportCall = transport?.dischargeTransportCall;

      if (index === 0) {
        eventData = {
          ...eventData,
          arrival: {
            actual: mapTransportEventData(dischargeTransportCall, EventClassifier.Actual, TransportEventType.Arrive),
            expected: mapTransportEventData(
              dischargeTransportCall,
              EventClassifier.Estimated,
              TransportEventType.Arrive,
            ),
          },
          berth: {
            actual: mapTransportEventData(dischargeTransportCall, EventClassifier.Actual, TransportEventType.Berth),
          },
          departure: {
            actual: mapTransportEventData(loadTransportCall, EventClassifier.Actual, TransportEventType.Depart),
            expected: mapTransportEventData(loadTransportCall, EventClassifier.Estimated, TransportEventType.Depart),
          },
          gateInFull: {
            actual: mapCargoEventData(
              loadTransportCall,
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.GateInFull,
            ),
          },
          gateOutEmpty: {
            actual: mapCargoEventData(
              loadTransportCall,
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.GateOutEmpty,
            ),
          },
          loaded: {
            actual: mapCargoEventData(loadTransportCall, cargoEvents, EventClassifier.Actual, CargoEventType.Load),
          },
          bookingConfirmed: {
            actual: mapCargoEventData(
              loadTransportCall,
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.BookingConfirmed,
            ),
          },
          receive: {
            actual: mapCargoEventData(loadTransportCall, cargoEvents, EventClassifier.Actual, CargoEventType.Receive),
          },
        };
      }

      if (index > 0) {
        eventData.transhipments.push({
          arrival: {
            actual: mapTransportEventData(dischargeTransportCall, EventClassifier.Actual, TransportEventType.Arrive),
            expected: mapTransportEventData(
              dischargeTransportCall,
              EventClassifier.Estimated,
              TransportEventType.Arrive,
            ),
          },
          berth: {
            actual: mapTransportEventData(dischargeTransportCall, EventClassifier.Actual, TransportEventType.Berth),
          },
          departure: {
            actual: mapTransportEventData(loadTransportCall, EventClassifier.Actual, TransportEventType.Depart),
            expected: mapTransportEventData(loadTransportCall, EventClassifier.Estimated, TransportEventType.Depart),
          },
          discharged: {
            actual: mapCargoEventData(
              previousDischargeTransportCall,
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.Discharge,
            ),
          },
          loaded: {
            actual: mapCargoEventData(loadTransportCall, cargoEvents, EventClassifier.Actual, CargoEventType.Load),
          },
        });
      }

      if (index === cargo.transports.length - 1) {
        eventData = {
          ...eventData,
          discharged: {
            actual: mapCargoEventData(
              dischargeTransportCall,
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.Discharge,
            ),
          },
          gateInEmpty: {
            actual: mapCargoEventData(
              dischargeTransportCall,
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.GateInEmpty,
            ),
          },
          gateOutFull: {
            actual: mapCargoEventData(
              dischargeTransportCall,
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.GateOutFull,
            ),
          },
          collect: {
            actual: mapCargoEventData(
              dischargeTransportCall,
              cargoEvents,
              EventClassifier.Actual,
              CargoEventType.Collect,
            ),
          },
        };
      }
    });
  }

  return eventData;
};

export const mapEquipmentISOCodeToKind = (isoCode: string): EquipmentKind | undefined => {
  return Object.values(EquipmentKind).find((i: EquipmentKind) => i.endsWith(`_${isoCode}`));
};
