/* eslint-disable react/prop-types */
import React, { ReactElement, useState, useEffect } from 'react';
import { Button, Card, Checkbox, Col, Dropdown, Form, Input, Popover, Radio, Row, Space, Tag, Typography } from 'antd';
import { EditOutlined, InfoCircleFilled, MoreOutlined } from '@ant-design/icons';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { useHistory, useParams } from 'react-router-dom';
import { USER_INVITE_TO_COMPANY_ROUTE, USER_UPDATE_ROUTE } from '@routes/constants';
import { CustomerFormProps, UserWithRoles } from '@beacon-types/customer';
import {
  availableCapabilities,
  availableCustomerAddOns,
  getEnabledCustomerPlan,
} from '@utils/customer/customerCapabilities';
import { CustomerCapabilityType, UserGroup } from '@beacon-devops/customer-service-client';
import UserService from '@services/User';
import { Box } from '@beacon-devops/components';
import { notEmpty } from '../../utils';
import { CustomerStatusTag, UserMenu, UserStatus } from './partials';
import './styles.less';
import { getCapabilityById } from './utils';

const { Title, Text } = Typography;

const MoreButton = (props: { onEditUser: () => void; sendInvite: () => void; user: UserWithRoles }) => (
  <Dropdown overlay={<UserMenu {...props} />} placement="bottomRight">
    <Button type="link">
      <MoreOutlined />
    </Button>
  </Dropdown>
);

const CustomerPreviewForm = ({
  fullCompanyName,
  companyDomains,
  isTest,
  legalBusinessEntity,
  capabilities,
  status,
  users,
  onGoBack,
  editMode,
  sendUserInvite,
  customerLoaded,
  usersLoaded,
  capabilityDefinitions,
}: CustomerFormProps): ReactElement => {
  const getFormattedValue = (field?: string) => {
    return notEmpty(field) ? field : '-';
  };

  const { id: customerId } = useParams<{ id: string }>();
  const history = useHistory();
  const navigateToUserInvite = () => {
    history.push(USER_INVITE_TO_COMPANY_ROUTE.replace(':customerId', customerId));
  };
  const navigateToUserEdit = (customerId: string, userId: string) => {
    history.push(USER_UPDATE_ROUTE.replace(':customerId', customerId).replace(':userId', userId));
  };

  const userList = _filter(users, (u) => notEmpty(_get(u, 'email')));
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const isActiveExternalUser = (u: UserWithRoles) => u.userGroup === UserGroup.External && u.isActive;
  const externalUsers = _filter(userList, isActiveExternalUser);
  const [updatedUsers, setUpdatedUsers] = useState<UserWithRoles[]>([]);

  useEffect(() => {
    if (usersLoaded) {
      const updateUsersData = async () => {
        const usersToDisplay = externalUsers.slice(startIndex, endIndex);
        const result = await UserService.getBulkUserRoles(customerId, usersToDisplay);
        setUpdatedUsers(result);
      };

      updateUsersData();
    }
  }, [currentPage, usersLoaded, users]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Form
        className="customer-preview-form"
        labelCol={{ span: 6 }}
        labelAlign="left"
        wrapperCol={{ span: 18 }}
        layout="horizontal"
      >
        {!editMode && (
          <Row>
            <Text className="customer-preview-form__description">
              You&apos;re about to create a company with the following details
            </Text>
          </Row>
        )}
        <Row>
          <Col span={20}>
            <Space className="form-action-header">
              <Title level={5}> Company Details</Title>
              {editMode ? (
                <Button disabled={!customerLoaded} type="primary" onClick={() => onGoBack('company')}>
                  Edit company details
                </Button>
              ) : (
                <Button type="link" onClick={() => onGoBack('company')}>
                  <EditOutlined />
                  Edit
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={20}>
            <Form.Item label="Company Name" required>
              {getFormattedValue(fullCompanyName)}
            </Form.Item>
            <Form.Item label="Additional Company Domains">{getFormattedValue(companyDomains?.join(','))}</Form.Item>
            {status && (
              <Form.Item label="Status">
                <CustomerStatusTag status={status} />
              </Form.Item>
            )}
            <Form.Item wrapperCol={{ offset: 6 }}>
              <Checkbox disabled defaultChecked={isTest}>
                {' '}
                Flag as Test Account{' '}
                <Popover
                  title="What is a test account ?"
                  content="Test account gives the user to ability to create test payments and test the platform without any real events. "
                  trigger="hover"
                  placement="bottom"
                  overlayClassName="test-account-popover"
                >
                  <InfoCircleFilled />
                </Popover>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Text strong className="form-section-header">
            Legal Entity
          </Text>
        </Row>
        <Row>
          <Col span={20}>
            <Form.Item label="Legal Entity Name" required>
              {getFormattedValue(_get(legalBusinessEntity, 'name'))}
            </Form.Item>
            <Form.Item label="Legal Entity Reg. No" required>
              {getFormattedValue(_get(legalBusinessEntity, 'registrationId'))}
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row>
          <Text strong className="form-section-header">
            Customer Capabilities
          </Text>
        </Row>
        <Row>
          <Col span={20}>
            <Form.Item label="Tracking plan" required style={{ marginBottom: '8px' }}>
              <Radio.Group
                disabled
                defaultValue={getEnabledCustomerPlan(capabilityDefinitions || [], capabilities || [])?.id}
                buttonStyle="solid"
              >
                {availableCapabilities(capabilityDefinitions || [], CustomerCapabilityType.Plan).map((plan) => (
                  <Radio.Button key={plan.id} value={plan.id}>
                    {plan.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Add-ons" required>
              {availableCustomerAddOns(capabilityDefinitions || []).map((addon) =>
                addon.type === CustomerCapabilityType.AddOn ? (
                  <Box key={addon.id}>
                    <Checkbox disabled defaultChecked={getCapabilityById(addon.id, capabilities)?.enabled || false}>
                      {addon.name}
                    </Checkbox>
                  </Box>
                ) : (
                  <Box key={addon.id} display="flex" alignItems="center">
                    {addon.limits?.map((limit) => (
                      <Box width="60px" mr={3} key={limit.type}>
                        <Input defaultValue={getCapabilityById(addon.id, capabilities)?.quantity} disabled />
                      </Box>
                    ))}

                    <Text disabled>{addon.name}</Text>
                  </Box>
                ),
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="customer-preview-form__users-title">
          <Col span={20}>
            <Space className="form-action-header">
              <Title level={5}> User &amp; Permissions</Title>
              {editMode ? (
                <Button disabled={!usersLoaded} type="primary" onClick={navigateToUserInvite}>
                  Add new users
                </Button>
              ) : (
                <Button type="link" onClick={() => onGoBack('invite')}>
                  <EditOutlined />
                  Edit
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <Row className="customer-preview-form__users-list">
          <Col span={20}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              {updatedUsers &&
                updatedUsers.map((user) => {
                  const { email, permissions, emailVerified, id } = user;
                  const defaultUserStatus = 'INVITATION WILL BE SENT';
                  const userMenuProps = {
                    onEditUser: () => {
                      navigateToUserEdit(user.companyId, user.id);
                    },
                    sendInvite: () => sendUserInvite(id),
                    user,
                  };

                  return (
                    <div key={`customer-user-${id}`}>
                      <Card>
                        <Row align="middle">
                          <Col span={editMode ? 15 : 16}>
                            <Space direction="vertical" size={14}>
                              <div> {email} </div>
                              <Space size={19}>
                                {permissions &&
                                  permissions.map(({ id, name }) => (
                                    <Tag className="user-permission-tag" key={id} closable={false}>
                                      {name}
                                    </Tag>
                                  ))}
                              </Space>
                            </Space>
                          </Col>
                          <Col className="user-status" span={editMode ? 4 : 5} offset={3}>
                            {editMode ? <UserStatus status={emailVerified} /> : <span>{defaultUserStatus}</span>}
                          </Col>
                          {editMode && (
                            <Col span="1">
                              <MoreButton {...userMenuProps} />
                            </Col>
                          )}
                        </Row>
                      </Card>
                    </div>
                  );
                })}
            </Space>
          </Col>
        </Row>
        <div>
          <Row style={{ marginTop: '12px' }} align="middle">
            <Col span={20}>
              <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                <Button
                  style={{ marginRight: '8px' }}
                  type="primary"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </Button>
                <Button
                  style={{ marginLeft: '8px' }}
                  type="primary"
                  disabled={currentPage === Math.ceil(externalUsers.length / pageSize)}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CustomerPreviewForm;
