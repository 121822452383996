import React from 'react';
import { SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { CargoListFilterWidgetType, CargoSortColumns } from '@beacon-types/cargo/cargoFilters';
import { noop } from 'lodash';

interface CargoSearchContextValues {
  isOpen: boolean;
  selectedFilters: CargoListFilterWidgetType[];
  toggleOpen: () => void;
  addFilter: (field: CargoListFilterWidgetType) => void;
  isFilterSelected: (key: CargoSortColumns) => boolean;
  removeFilter: (filterIndex: number) => void;
  setFieldValue: (value: string[], filterIndex: number) => void;
  setCondition: (condition: SearchConditionType, filterIndex: number) => void;
  setSelectedFilters: React.Dispatch<React.SetStateAction<CargoListFilterWidgetType[]>>;
}

export const CargoSearchContext = React.createContext<CargoSearchContextValues>({
  isOpen: false,
  selectedFilters: [],
  toggleOpen: noop,
  addFilter: noop,
  isFilterSelected: () => false,
  removeFilter: noop,
  setFieldValue: noop,
  setCondition: noop,
  setSelectedFilters: noop,
});
