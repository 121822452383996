import { SidebarItem } from '@components/SideBar/constants';
import { basePathAir, basePathFlaggedShipments, basePathOcean, basePathScvCommander } from '../routes/paths';

export const platformNavigation = (): SidebarItem[] => [
  {
    name: 'Platform',
    type: 'subMenu',
    selector: 'platform-sub-menu',
    includeDivider: true,
    key: 'platform-sub-menu',
    items: [
      {
        name: 'Ocean',
        type: 'menuItem',
        key: basePathOcean,
        internalLink: basePathOcean,
        selector: 'OceanContainers',
      },
      {
        name: 'Air',
        type: 'menuItem',
        key: basePathAir,
        internalLink: basePathAir,
        selector: 'Air',
      },
      {
        name: 'Flagged Shipments',
        type: 'menuItem',
        key: basePathFlaggedShipments,
        internalLink: basePathFlaggedShipments,
        selector: 'FlaggedShipments',
      },
      {
        name: 'SCV Commander',
        type: 'menuItem',
        key: basePathScvCommander,
        internalLink: basePathScvCommander,
        selector: 'ScvCommander',
      },
    ],
  },
];
