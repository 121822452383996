import React, { FC } from 'react';
import { Box } from '@beacon-devops/components';
import { Input } from 'antd';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { Controller, UseControllerProps, useFormContext, useWatch } from 'react-hook-form';
import { ContainerFromToDisplay } from '@platform/components/ContainerFromToDisplay';
import ScvVesselSearch from '@platform/components/VesselSearch/ScvVesselSearch';
import { ScvContainerDate } from '@platform/components/ContainerDate';
import { SCVContainerFormValues } from '@platform/types';
import { EventProps } from '@platform/utils';

import {
  BerthedPlacement,
  CargoLoadedPlacement,
  CargoOffLoadedPlacement,
  CDetailsCard,
  CDetailsCardContent2RowsLayout,
  FirstVesselImoPlacement,
  FormLabel,
  FromToPlacement,
} from './styles';

interface ShipmentDatesProps {
  mode?: Mode;
  getDateProps: (eventKey: UseControllerProps<SCVContainerFormValues>['name']) => EventProps;
}

export const ShipmentDates: FC<ShipmentDatesProps> = ({ getDateProps, mode = Mode.Ocean }) => {
  const { control, setValue } = useFormContext<SCVContainerFormValues>();

  const isOcean = mode === Mode.Ocean;
  const isAir = mode === Mode.Air;

  const firstTranshipment = useWatch({
    control,
    name: 'transhipments.0',
  });

  const portOfLoading = useWatch({
    control,
    name: 'departurePort',
  });

  const portOfDischarge = useWatch({
    control,
    name: 'arrivalPort',
  });

  const port1 = isAir ? 'Transhipment Airport 1' : 'Transhipment Seaport 1';

  const handlePortOfDischarge = () => {
    if (firstTranshipment) {
      return firstTranshipment?.port?.name || port1;
    }

    return portOfDischarge?.name || '';
  };

  return (
    <CDetailsCard>
      <CDetailsCardContent2RowsLayout>
        <FromToPlacement>
          <ContainerFromToDisplay from={portOfLoading?.name} to={handlePortOfDischarge()} />
        </FromToPlacement>

        {isOcean && (
          <>
            <ScvVesselSearch
              label="First Vessel Name"
              name="vessel.name"
              setImoNumber={(imoNumber: string) => setValue('vessel.id', imoNumber)}
            />
            <FirstVesselImoPlacement>
              <FormLabel htmlFor="voyagerNumber">Voyage Number</FormLabel>
              <Controller
                control={control}
                name="voyagerNumber"
                render={({ field }) => <Input {...field} id="voyagerNumber" />}
              />
              <Box mt={7}>
                <FormLabel htmlFor="vessel.id">First Vessel IMO</FormLabel>
                <Controller
                  control={control}
                  name="vessel.id"
                  rules={{
                    pattern: /^[0-9]{7}$/i,
                  }}
                  render={({ field }) => <Input disabled {...field} id="vesselImoNumber" />}
                />
              </Box>
            </FirstVesselImoPlacement>
          </>
        )}

        {isAir && (
          <>
            <Box>
              <FormLabel htmlFor="flightNumber">Flight Number</FormLabel>
              <Controller
                control={control}
                name="flightNumber"
                render={({ field }) => <Input {...field} id="flightNumber" />}
              />
            </Box>

            <CargoLoadedPlacement>
              <ScvContainerDate label="Cargo Loaded" {...getDateProps('loaded.actualDate')} />
            </CargoLoadedPlacement>
          </>
        )}

        <ScvContainerDate label="Estimated Departure" {...getDateProps('departure.expectedDate')} />
        <ScvContainerDate label="Estimated Arrival" {...getDateProps('arrival.expectedDate')} />
        <ScvContainerDate label="Actual Departure" {...getDateProps('departure.actualDate')} />
        <ScvContainerDate label="Actual Arrival" {...getDateProps('arrival.actualDate')} />
        {isAir && (
          <CargoOffLoadedPlacement>
            <ScvContainerDate label="Cargo Off-Loaded" {...getDateProps(`discharged.actualDate`)} />
          </CargoOffLoadedPlacement>
        )}
        {!firstTranshipment && isOcean && (
          <BerthedPlacement>
            <ScvContainerDate label="Actual Berthed" {...getDateProps(`berth.actualDate`)} />
          </BerthedPlacement>
        )}
      </CDetailsCardContent2RowsLayout>
    </CDetailsCard>
  );
};
