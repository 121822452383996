import React, { ReactElement } from 'react';
import _get from 'lodash/get';
import _every from 'lodash/every';
import _find from 'lodash/find';
import { notEmpty } from '@utils/generalUtilities';
import { emailRegex } from '@utils/forms/validation.regexp';
import { CUSTOMERS_ROUTE } from '@routes/constants';
import { CustomerFormProps, CustomerFormValues, CustomerWorkflow } from '@beacon-types/customer';
import UserInviteForm from './UserInviteForm';
import CustomerFormV2 from './CustomerFormV2';
import CustomerPreview from './CustomerPreviewForm';
import { createOrUpdateCustomer, validateUserList } from './utils';
import EditUserForm from './EditUserForm';
import AddUsersForm from './AddUsersForm';

export const COMPANY_FORM_ROUTE = 'company';
export const USERS_INVITE_ROUTE = 'invite';
export const COMPANY_PREVIEW_ROUTE = 'preview';
export const EDIT_USER_ROUTE = 'editUser';
export const ADD_USERS_ROUTE = 'addUsers';

const createCustomerWorkflow: CustomerWorkflow = {
  [COMPANY_FORM_ROUTE]: {
    id: COMPANY_FORM_ROUTE,
    Header: (fields?: CustomerFormValues): ReactElement => {
      const editMode = _get(fields, 'editMode');
      return editMode ? <> Edit Company details</> : <> Create a new company (1 of 3)</>;
    },
    Form: CustomerFormV2,
    isValid: ({ fullCompanyName, legalBusinessEntity, enabledPlan }: CustomerFormValues): boolean =>
      notEmpty(fullCompanyName) &&
      notEmpty(legalBusinessEntity?.name) &&
      notEmpty(legalBusinessEntity?.registrationId) &&
      notEmpty(enabledPlan),
    canGoNext: (formValues: CustomerFormValues): boolean => {
      const step = createCustomerWorkflow[COMPANY_FORM_ROUTE];
      return step.isValid(formValues) && notEmpty(step.nextStep);
    },
    previousStep: CUSTOMERS_ROUTE,
    nextStep: USERS_INVITE_ROUTE,
    onSubmit: (formValues, options) => createOrUpdateCustomer({ formValues, options }),
  },
  [USERS_INVITE_ROUTE]: {
    canSkip: true,
    id: USERS_INVITE_ROUTE,
    Header: (fields?: CustomerFormValues): ReactElement => {
      const customerName = _get(fields, 'fullCompanyName');
      const editMode = _get(fields, 'editMode');
      return editMode ? <>Edit users</> : <>Add users to {customerName} (2 of 3)</>;
    },
    Form: UserInviteForm,
    isValid: validateUserList,
    canGoNext: (formValues: CustomerFormValues): boolean => {
      const { users } = formValues;
      const step = createCustomerWorkflow[USERS_INVITE_ROUTE];
      const hasAllUsersWithPermissions = _every(users, ({ permissions }) => {
        return notEmpty(permissions);
      });
      const hasNextStep = step.isValid(formValues) && notEmpty(step.nextStep);

      return hasNextStep && hasAllUsersWithPermissions;
    },
    previousStep: COMPANY_FORM_ROUTE,
    nextStep: COMPANY_PREVIEW_ROUTE,
    onSubmit: (formValues, options) => createOrUpdateCustomer({ formValues, options }),
  },
  [EDIT_USER_ROUTE]: {
    id: EDIT_USER_ROUTE,
    Header: (): ReactElement => <>Edit user</>,
    Form: (fields: CustomerFormValues & CustomerFormProps) => {
      const userId = window.location.hash.replace('#user=', '');
      const user = _find(fields?.users, { id: userId });
      return <EditUserForm {...fields} users={user ? [user] : []} />;
    },
    isValid: ({ users }: CustomerFormValues): boolean => {
      const user = _get(users, '[0]');
      const hasValidPermissions = notEmpty(user.permissions);
      const isValidEmail = RegExp(emailRegex).test(user.email);

      return hasValidPermissions && isValidEmail;
    },
    canGoNext: () => true,
    previousStep: COMPANY_PREVIEW_ROUTE,
    onSubmit: (formValues, options) => createOrUpdateCustomer({ formValues, options }),
  },
  [ADD_USERS_ROUTE]: {
    id: ADD_USERS_ROUTE,
    Header: (): ReactElement => <>Add users</>,
    Form: AddUsersForm,
    isValid: validateUserList,
    canGoNext: () => true,
    previousStep: COMPANY_PREVIEW_ROUTE,
    onSubmit: (formValues, options) => createOrUpdateCustomer({ formValues, options }),
  },
  [COMPANY_PREVIEW_ROUTE]: {
    id: COMPANY_PREVIEW_ROUTE,
    Header: (fields?: CustomerFormValues): ReactElement => {
      const editMode = _get(fields, 'editMode');
      const fullCompanyName = _get(fields, 'fullCompanyName');
      return editMode ? <>{fullCompanyName}</> : <>Review company details (3 of 3)</>;
    },
    Form: CustomerPreview,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    isValid: (_: CustomerFormValues): boolean => true,
    onSubmit: (formValues: CustomerFormValues, options) => createOrUpdateCustomer({ formValues, options }),
    /* eslint-disable @typescript-eslint/no-unused-vars */
    canGoNext: (_): boolean => true,
    previousStep: USERS_INVITE_ROUTE,
    nextStep: undefined,
  },
};

export default createCustomerWorkflow;
