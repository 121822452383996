import { ColumnsType } from 'antd/lib/table';
import { ColumnControlledValues } from '@beacon-types/Table';

export function assignControlledValues<T = unknown>(
  columns: ColumnsType<T>,
  columnControlledValues: Record<string, ColumnControlledValues> | undefined,
): ColumnsType<T> {
  return columns.map((column) => {
    const defaultAssignedColumn = {
      ...column,
      filtered: false,
      filteredValue: [],
      sortOrder: undefined,
    };
    if (column.key && columnControlledValues && columnControlledValues[column.key]) {
      return {
        ...defaultAssignedColumn,
        ...columnControlledValues[column.key],
      };
    }
    return defaultAssignedColumn;
  });
}
