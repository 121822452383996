import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import { Button, Typography } from 'antd';

const { Title } = Typography;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TitleContainer = styled.span`
  flex: 1;
`;

export const TitleButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DeletedText = styled(Text)`
  display: flex;
  height: 32px;
  align-items: center;
  font-size: 14px;
  color: #ff4d4f;
`;

export const TitleWithoutMargin = styled(Title)`
  margin-bottom: 0 !important;
`;

export const DeleteEntityButton = styled(Button)`
  border-color: black;
  background-color: white;
  color: red;

  &:hover {
    background-color: red;
    color: white;
  }
`;
