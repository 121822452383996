import {
  CustomerCapability,
  CustomerCapabilityDefinition,
  CustomerCapabilityType,
} from '@beacon-devops/customer-service-client';

const availableCapabilities = (capabilityDefinitions: CustomerCapabilityDefinition[], type: CustomerCapabilityType) => {
  return capabilityDefinitions?.filter((capability) => capability.type === type);
};

const availableCustomerAddOns = (capabilityDefinitions: CustomerCapabilityDefinition[]) => {
  return capabilityDefinitions?.filter((capability) =>
    [CustomerCapabilityType.AddOn, CustomerCapabilityType.AddOnWithQuantity].includes(capability.type),
  );
};

const getEnabledCustomerPlan = (
  capabilityDefinitions: CustomerCapabilityDefinition[],
  capabilities: CustomerCapability[],
): CustomerCapability | undefined => {
  const planIds = availableCapabilities(capabilityDefinitions, CustomerCapabilityType.Plan).map((p) => p.id);
  return capabilities?.find((cap) => cap.id && planIds.includes(cap.id) && cap.enabled);
};

export { availableCapabilities, getEnabledCustomerPlan, availableCustomerAddOns };
