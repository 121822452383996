import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import { Button, Typography } from 'antd';

const { Title } = Typography;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TitleContainer = styled.span`
  flex: 1;
`;

export const TitleButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const JSONButton = styled(Button)`
  height: 34px;
`;

export const DeletedText = styled(Text)`
  display: flex;
  height: 32px;
  align-items: center;
  font-size: 14px;
  color: #ff4d4f;
`;

export const UntrackableMessage = styled.span`
  display: flex;
  height: 32px;
  align-items: center;
  font-size: 14px;
`;

export const TitleWithoutMargin = styled(Title)`
  margin-bottom: 0 !important;
`;

export const DisableTrackingButton = styled(Button)`
  background-color: orange;
  color: white;

  &:hover {
    border-color: orange;
    background-color: orange;
    color: white;
  }
`;

export const EnableTrackingButton = styled(Button)`
  background-color: green;
  color: white;

  &:hover {
    border-color: green;
    background-color: green;
    color: white;
  }
`;

export const TrackingStatusButton = styled(Button)<{ isTrackingActive: boolean }>`
  background-color: ${({ isTrackingActive }) => (isTrackingActive ? 'green' : 'red')};
  border-color: ${({ isTrackingActive }) => (isTrackingActive ? 'green' : 'red')};
  color: white;
  pointer-events: none;

  &:hover {
    background-color: ${({ isTrackingActive }) => (isTrackingActive ? 'green' : 'red')};
    color: white;
  }
`;
