import React, { FC } from 'react';
import { CustomerStatus } from '@beacon-devops/customer-service-client';
import { Typography, Button, Menu, Tag } from 'antd';
import { UserWithRoles } from '@beacon-types/customer';

const { Text } = Typography;

export const UserMenu: FC<{
  onEditUser: () => void;
  sendInvite: (user: UserWithRoles) => void;
  user: UserWithRoles;
}> = ({ onEditUser, sendInvite, user }) => (
  <Menu>
    <Menu.Item>
      <Button
        type="link"
        onClick={() => {
          onEditUser();
        }}
      >
        Edit
      </Button>
    </Menu.Item>
    <Menu.Item>
      <Button
        type="link"
        onClick={() => {
          sendInvite(user);
        }}
      >
        Resend
      </Button>
    </Menu.Item>
  </Menu>
);

export const UserStatus = ({ status }: { status?: string }) => {
  const verifiedStatus = status === 'verified' ? 'ACTIVE' : 'PENDING';
  return (
    <Text strong className={`user-status user-status-${verifiedStatus}`}>
      {verifiedStatus}
    </Text>
  );
};

export const CustomerStatusTag = ({ status }: { status: CustomerStatus }) => (
  <Tag color={status === CustomerStatus.Archived ? 'red' : 'green'}>{`${status} ${
    status === CustomerStatus.Archived ? '- DO NOT USE!' : ''
  }`}</Tag>
);
