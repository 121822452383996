import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '@components/PageLayout';
import UserDetail from '@components/User/UserDetail';

export const User = (): ReactElement => {
  const { userId } = useParams<{ userId: string }>();
  const { customerId } = useParams<{ customerId: string }>();

  return (
    <PageLayout>
      <UserDetail userId={userId} customerId={customerId} />
    </PageLayout>
  );
};

export default User;
