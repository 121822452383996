import { CustomerState } from '../reduxTypes';

export const CUSTOMER_INITIAL_STATE: CustomerState = {
  customers: [],
  customer: undefined,
  isLoading: false,
  hasError: false,
  createCustomerSuccess: false,
};

export enum CustomerActions {
  CUSTOMER_LOADING = 'CUSTOMER_LOADING',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  CUSTOMER_ERROR = 'CUSTOMER_ERROR',
  CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS',
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_CUSTOMERS = 'SET_CUSTOMERS',
  CLEAR_CUSTOMER = 'CLEAR_CUSTOMER',
}
