import React, { useEffect, useState, ReactElement } from 'react';
import { notEmpty } from '@utils/generalUtilities';
import CreditProfileService from '@services/CreditProfileService';
import TableView from '@components/common/TableView';
import { GetInviteUserColumnsProps, UserWithRoles } from '@beacon-types/customer';
import { getColumns } from './UsersRolesTable.definition';

const UsersRolesTable = ({
  users,
  customerId,
  rolesList,
  ...rest
}: GetInviteUserColumnsProps & { customerId?: string; users: UserWithRoles[] }): ReactElement => {
  const [isSCFCustomer, setIsSCFCustomer] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const initTableData = async () => {
    setLoading(true);
    try {
      if (customerId) {
        const creditProfile = await CreditProfileService.getCreditProfile(customerId).catch(() => null);
        if (creditProfile) {
          setIsSCFCustomer(true);
        } else {
          setIsSCFCustomer(false);
        }
      } else {
        setIsSCFCustomer(false);
      }
    } catch (e) {
      setIsSCFCustomer(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    initTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);
  const tableProps = {
    data: users,
    getColumns: () => getColumns({ ...rest, isSCFCustomer, rolesList, users }),
    isLoading,
  };

  return notEmpty(rolesList) ? <TableView {...tableProps} /> : <></>;
};

export default UsersRolesTable;
