import { CargoStatus } from '@beacon-devops/graphql-typescript-client';

export const ContainerStatusMappings = [
  {
    label: 'Gated Out Empty At POL',
    value: CargoStatus.GatedOutEmpty,
  },
  {
    label: 'Gated In Full At POL',
    value: CargoStatus.GatedInFull,
  },
  {
    label: 'Unspecified',
    value: CargoStatus.Unspecified,
  },
  {
    label: 'Loaded',
    value: CargoStatus.LoadedAtOrigin,
  },
  {
    label: 'In Transit',
    value: CargoStatus.InTransit,
  },
  {
    label: 'Arrived At PoD',
    value: CargoStatus.ArrivedAtDest,
  },
  {
    label: 'Berthed At PoD',
    value: CargoStatus.BerthedAtDest,
  },
  {
    label: 'Discharged',
    value: CargoStatus.DischargedAtDest,
  },
  {
    label: 'Gated Out Full',
    value: CargoStatus.GatedOutFull,
  },
  {
    label: 'Gated In Empty At PoD',
    value: CargoStatus.GatedInEmpty,
  },
  {
    label: 'Cancelled',
    value: CargoStatus.Cancelled,
  },
  {
    label: 'Not Tracking',
    value: CargoStatus.TrackingError,
  },
  {
    label: 'Delivered',
    value: CargoStatus.Delivered,
  },
];
