import React, { useState } from 'react';
import { useParams } from 'react-router';
import PageLayout from '@components/PageLayout';
import { Card, Col, Row, Collapse } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  ScvEntityType,
  AddInspectionEntity,
  RemoveInspectionEntity,
} from '@platform/components/ScvCommanderEntity/ScvCommanderEntityUtils';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ScvCommanderEntity } from '../../components/ScvCommanderEntity';
import ScvLogo from '../../../static/img/scv.png';

interface ScvCommanderEntityProps {
  entityType: string;
  entityId: string;
}

interface ScvEntityModel {
  entityType: ScvEntityType;
  entityId: string;
  backgroundColour: string;
}

const primaryBackgroundColor = '#d6e4ff'; // geekblue-2
const backgroundColours: string[] = [
  '#fff1b8', // gold-2
  '#ffccc7', // red-2
  '#d9f7be', // green-2
  '#efdbff', // purple-2
  '#b5f5ec', // cyan-2
  '#ffe7ba', // orange-2
  '#ffd6e7', // magenta-2
];

export function ScvCommanderEntityPage() {
  // Initialise component from URL parameters and initial state:
  const { entityType, entityId } = useParams<ScvCommanderEntityProps>();

  // State: Just the list of inspection entities. Every time one is added or remove we will re-render
  const [entities, setEntities] = useState<ScvEntityModel[]>([]);

  // Keep track of the colours we use to distinguish separate inspection entities
  const [nextBackground, setNextBackground] = useState<number>(0);

  /**
   * Little utility to return the next available colour in the pre-set list above. If we reach the end we go back to the start.
   */
  function getNextBackground(): string {
    const toReturn = backgroundColours[nextBackground];
    const nextBackgroundPlusOne = nextBackground + 1;

    if (nextBackground >= backgroundColours.length) {
      setNextBackground(0);
    } else {
      setNextBackground(nextBackgroundPlusOne);
    }
    return toReturn;
  }

  /**
   * This will add a new entity to the inspection panels below the main entity. Every time it's called it will append another one to the bottom.
   * @param entityType The entity type for the link e.g. cargo, carrierShipment
   * @param entityId The Beacon ID for the entity e.g. bcn1.ti.cargo.foo
   */
  let addInspectionEntityFunc: AddInspectionEntity = (entityType: ScvEntityType, entityId: string) => {
    if (entities.some((e) => entityId === e.entityId)) {
      // NOT adding entity to inspection panels since it's already in there!
      return;
    }
    const backgroundColour = getNextBackground();
    setEntities([
      ...entities,
      {
        entityType,
        entityId,
        backgroundColour,
      },
    ]);
  };

  /**
   * This will add a new entity to the inspection panels below the main entity. Every time it's called it will append another one to the bottom.
   * @param entityId The Beacon ID for the entity e.g. bcn1.ti.cargo.foo
   */
  let removeInspectionEntityFunc: RemoveInspectionEntity = (entityId: string) => {
    setEntities(entities.filter((e) => entityId !== e.entityId));
    return false;
  };

  // Render the main page. Primary entity at the top, then a list of zero or more inspection entities below that.
  // The page will be re-rendered as inspection entities are added or removed
  return (
    <PageLayout>
      <div style={{ textAlign: 'left', padding: '32px' }}>
        <h2>
          <img src={ScvLogo} height={30} alt="" /> SCV Commander
        </h2>
        <Row>
          <Col span={24}>
            <Card title="Primary Entity Viewing Panel" color={primaryBackgroundColor}>
              <ScvCommanderEntity
                entityType={ScvEntityType[entityType]}
                entityId={entityId}
                addInspectionEntityFunc={addInspectionEntityFunc}
                backgroundColor={primaryBackgroundColor}
              />
            </Card>
          </Col>
        </Row>
        <Collapse style={{ marginTop: 10 }}>
          {entities.map((entity) => {
            const backgroundColor = entity.backgroundColour;
            const panelHeader = `${entity.entityType} : ${entity.entityId}`;
            const removePanelLink = (
              <a href="#" onClick={() => removeInspectionEntityFunc(entity.entityId)}>
                <CloseOutlined />
              </a>
            );
            return (
              <CollapsePanel
                header={panelHeader}
                key={entity.entityId}
                style={{ background: backgroundColor }}
                extra={removePanelLink}
              >
                <ScvCommanderEntity
                  entityType={entity.entityType}
                  entityId={entity.entityId}
                  addInspectionEntityFunc={addInspectionEntityFunc}
                  backgroundColor={backgroundColor}
                />
              </CollapsePanel>
            );
          })}
        </Collapse>
      </div>
    </PageLayout>
  );
}
