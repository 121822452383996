import { message } from 'antd';
import { Role } from '@beacon-devops/customer-service-client';
import { sessionStorageService } from '@utils/generalUtilities';
import { logoutAuth0, getAccessToken } from '@services/Auth';
import UserService from '@services/User';
import { User } from '@services/constants';
import { USERS_ROUTE, USER_DETAIL_ROUTE } from '@routes/constants';
import { History } from 'history';
import { UserActions } from './constants';
import { Action, Thunk, AsyncDispatch, ErrorResponse } from '../reduxTypes';

function removeRequestType(m: string) {
  return m.replace('bad request:', '');
}

export const getUserRequest = (): Action => ({
  type: UserActions.GET_USER_REQUEST,
  payload: null,
});

export const setUser = (data: User): Action => ({
  type: UserActions.GET_USER_SUCCESS,
  payload: data,
});

export const setUserAuth = (token?: string | null): Action => ({
  type: UserActions.SET_USER_IS_AUTHENTICATED,
  payload: token,
});

export const setSelectedUser = (data?: User): Action => ({
  type: UserActions.SET_SELECTED_USER,
  payload: data,
});

export const setSelectedUserRequest = (): Action => ({
  type: UserActions.GET_SELECTED_USER_REQUEST,
  payload: null,
});

export const deleteSelectedUserRequest = (): Action => ({
  type: UserActions.DELETE_SELECTED_USER_REQUEST,
});

export const updateSelectedUserRequest = (): Action => ({
  type: UserActions.UPDATE_SELECTED_USER_REQUEST,
  payload: null,
});

export const setSelectedUserError = (error: Error): Action => ({
  type: UserActions.GET_SELECTED_USER_ERROR,
  payload: error,
});

export const updateSelectedUserError = (error: Error): Action => ({
  type: UserActions.UPDATE_SELECTED_USER_ERROR,
  payload: error,
});

export const deleteSelectedUserError = (error: ErrorResponse): Action => ({
  type: UserActions.DELETE_SELECTED_USER_ERROR,
  payload: error,
});

export const setSelectedUserRoles = (data: Role[]): Action => ({
  type: UserActions.SET_SELECTED_USER_ROLES,
  payload: data,
});

export const setRolesList = (data: Role[]): Action => ({
  type: UserActions.SET_ROLES_LIST,
  payload: data,
});

export const login = (): Thunk<void> => {
  return async (dispatch) => {
    dispatch(getUserRequest());
    const accessToken = await getAccessToken();
    dispatch(setUserAuth(accessToken));

    return UserService.getUser().then((user) => {
      dispatch(setUser(user));
    });
  };
};

export const logout = (): Thunk<void> => {
  return () => {
    sessionStorageService.clear();
    logoutAuth0();
    return Promise.resolve();
  };
};

// TODO: remove in favour of getSelectedUserByIdAndCustomerId (backend preferance)
export const getSelectedUser = (userId: string): Thunk<void> => {
  return (dispatch: AsyncDispatch) => {
    dispatch(setSelectedUserRequest());

    return UserService.getUserById(userId)
      .then((user) => {
        dispatch(setSelectedUser(user));
      })
      .catch((e: Error) => {
        dispatch(setSelectedUserError(e));
        console.error(e);
      });
  };
};

export const getSelectedUserByIdAndCustomerId = (customerId: string, userId: string) => {
  return (dispatch: AsyncDispatch) => {
    dispatch(setSelectedUserRequest());

    return UserService.getUserByIdAndCustomerId(customerId, userId)
      .then((user) => {
        dispatch(setSelectedUser(user));
      })
      .catch((e: Error) => {
        dispatch(setSelectedUserError(e));
        console.error(e);
        message.error('Error retrieving user');
      });
  };
};

export const updateSelectedUser = (userId: string, version: string, userData: User, roles: string[]): Thunk<void> => (
  dispatch: AsyncDispatch,
) => {
  dispatch(updateSelectedUserRequest());

  return UserService.updateUser(userId, version, userData, roles)
    .then((user) => {
      dispatch(setSelectedUser(user));
      message.success('User has been updated');
    })
    .catch((e) => {
      dispatch(updateSelectedUserError(e));
      message.error(`Error updating user: ${removeRequestType(e.response.data)}`);
    });
};

export const addSelectedUser = (userData: User, roles: string[], history: History): Thunk<void> => (
  dispatch: AsyncDispatch,
) => {
  dispatch(setSelectedUserRequest());

  return UserService.addUser(userData, roles)
    .then((user) => {
      dispatch(setSelectedUser(user));
      history.push(USER_DETAIL_ROUTE.replace(':customerId', user.companyId).replace(':userId', user.id));
    })
    .catch((e) => {
      dispatch(setSelectedUserError(e));
      message.error(`Error inviting user: ${removeRequestType(e.response.data)}`);
    });
};

export const deleteSelectedUser = (userId: string, history: History): Thunk<void> => (dispatch: AsyncDispatch) => {
  dispatch(deleteSelectedUserRequest());

  return UserService.deleteUserById(userId)
    .then(() => {
      dispatch(setSelectedUser(undefined));
      message.success('User has been deleted');
      history.push(USERS_ROUTE);
    })
    .catch((error: ErrorResponse) => {
      dispatch(deleteSelectedUserError(error));
      message.error(error.response.data);
      console.error(error);
    });
};
export const sendInviteEmail = (customerId: string, userId: string): Thunk<void> => () => {
  return UserService.sendInviteEmail(customerId, userId)
    .then(() => {
      message.success('Email sent to user successfully');
    })
    .catch((error: ErrorResponse) => {
      message.error(error.response.data);
      console.error(error);
    });
};

export const fetchUserRoles = (customerId: string, userId: string): Thunk<void> => (dispatch: AsyncDispatch) => {
  return UserService.getUserRoles(customerId, userId)
    .then((response) => {
      dispatch(setSelectedUserRoles(response));
      message.success('User roles successfully fetched');
    })
    .catch((error: ErrorResponse) => {
      message.error(error.response?.data);
      console.error(error);
    });
};
