import styled from 'styled-components';

export const FromToLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  max-width: 200px;
`;

export const ContainerFromToWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubTitle = styled.b`
  flex-shrink: 0;
  margin-right: 4px;
`;

export const Port = styled.span`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
