import React, { FunctionComponent } from 'react';
import { Layout, Avatar, Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { connect, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { logout } from '@state/user';
import { AppState } from '@state/reduxTypes';
import './style.less';

type UserDropdownProps = {
  firstName: string;
  lastName: string;
};

const { Content } = Layout;

const UserDropdown: FunctionComponent<UserDropdownProps> = ({ firstName, lastName }: UserDropdownProps) => {
  const dispatch = useDispatch();
  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={() => dispatch(logout())}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Content className="bo__user-dropdown-menu">
      <Content>
        <Avatar className="avatar" shape="square">
          {`${firstName.charAt(0)}${lastName.charAt(0)}`}
        </Avatar>
      </Content>
      <Content className="dropdown-container">
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className="button-dropdown" type="text">
            <section className="ant-dropdown-link">
              {`${firstName} ${lastName}`}
              <DownOutlined className="down-arrow" />
            </section>
          </Button>
        </Dropdown>
      </Content>
    </Content>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    firstName: _get(state, 'user.data.firstName', ''),
    lastName: _get(state, 'user.data.lastName', ''),
  };
};

export default connect(mapStateToProps)(UserDropdown);
