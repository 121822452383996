// eslint-disable-next-line import/no-cycle
import { getAccessToken } from '@services/Auth';

interface Config {
  headers: {
    'Content-Type': string;
    Authorization: string;
  };
}

export const getConfig = async (): Promise<Config> => {
  const token = await getAccessToken();

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};
