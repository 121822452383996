import { Button, Card, Select, Timeline } from 'antd';
import styled, { css } from 'styled-components';

export const LoadingCentered = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormSection = styled.section`
  margin-top: 24px;
`;

export const FormLabel = styled.label`
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 500;
`;

export const ErrorWrapper = styled.div`
  margin-top: 20px;
`;

export const CDetailsTimelineItem = styled(Timeline.Item)`
  & .ant-timeline-item-head-custom {
    background: transparent;
  }
`;

export const CDetailsCard = styled(Card)<{ mb?: number }>`
  width: 100%;
  position: relative;

  ${({ mb }) =>
    mb
      ? css`
          margin-bottom: ${mb}px;
        `
      : ''}
`;

export const RemoveTranshipment = styled(Button)`
  position: absolute;
  top: 13px;
  right: 114px;
`;

export const CDetailsCardContentLayout = styled.div`
  display: grid;
  grid-template-columns: 36% 1fr 1fr 1fr;
  grid-column-gap: 40px;
  place-items: flex-end;
  flex-direction: row;
  width: 100%;
`;

export const CDetailsCardContent2RowsLayout = styled(CDetailsCardContentLayout)`
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 40px;
  grid-auto-flow: column;
  place-items: center;
`;

export const FirstVesselImoPlacement = styled.div`
  width: 200px;
  grid-area: 1 / 2 / span 2;
  place-self: center;
`;

export const BerthedPlacement = styled.div`
  grid-area: 1 / 5 / span 2;
  place-self: center;
`;

export const FromToPlacement = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  place-self: baseline;
`;

export const CDetailsTimelinePoint = styled.div`
  background: rgb(191, 204, 218);
  border: 2px solid rgb(242, 246, 250);
  border-radius: 50%;
  height: 17px;
  width: 17px;
`;

export const CDetailsSelect = styled(Select)`
  width: 100%;
`;
