import React, { ReactElement } from 'react';
import { Customer, CustomerStatus } from '@beacon-devops/customer-service-client';
import moment from 'moment';
import { compareDates, compareStrings } from '@utils/generalUtilities';

import { CustomerFE } from '@state/reduxTypes';
import { ColumnFilterProps } from '@beacon-types/Table';
import { StringTemplate, CustomerActionTemplate } from './partials';
import { CustomerStatusTag } from '../../pages/SingleCustomer/partials';
import { getColumnTextSearchProps } from '../common/TableView/partials';

export const getColumns = ({ forwardedRef, onSearch, onReset, searchedColumn, searchText }: ColumnFilterProps) => {
  return [
    {
      title: 'Id',
      dataIndex: 'customerId',
      key: 'customerId',
      width: 300,
      fixed: 'left',
      ...getColumnTextSearchProps<Customer>({
        dataIndex: 'customerId',
        onSearch,
        onReset,
        forwardedRef,
        searchedColumn,
        searchText,
        placeholder: 'Search Customer ID',
        renderCell: StringTemplate,
      }),
    },
    {
      title: 'Company name',
      dataIndex: 'fullCompanyName',
      key: 'fullCompanyName',
      fixed: 'left',
      sorter: (a: CustomerFE, b: CustomerFE) => compareStrings(a.fullCompanyName, b.fullCompanyName),
      ...getColumnTextSearchProps<CustomerFE>({
        dataIndex: 'fullCompanyName',
        onSearch,
        onReset,
        forwardedRef,
        searchedColumn,
        searchText,
        placeholder: 'Search Company Name',
        renderCell: StringTemplate,
      }),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'left',
      sorter: (a: CustomerFE, b: CustomerFE) => compareStrings(a.status, b.status),
      render: (status: CustomerStatus): ReactElement => <CustomerStatusTag status={status} />,
    },
    {
      title: 'Date Created',
      dataIndex: ['created', 'triggerAt'],
      render: (triggerAt: string) => (triggerAt ? moment(triggerAt).format('DD MMM YYYY') : ''),
      sorter: (a: CustomerFE, b: CustomerFE) => compareDates(a.createdAt, b.createdAt),
      key: 'created',
      fixed: 'left',
    },
    {
      title: '',
      dataIndex: 'customerId',
      key: 'customer_action',
      width: '75px',
      render: CustomerActionTemplate,
    },
  ];
};
